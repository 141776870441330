.payment-tab-menu {
  display: flex;
  align-items: center;
  width: 100%;

  padding-bottom: 5px;

  .payment-tab-header {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: solid 1px #e0e0e0;
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #d8d8d8;
    }
    &::-webkit-scrollbar {
      height: 6px;
      border-radius: 8px;
    }
    .btn {
      display: inline-flex;
      padding: 7px 4px;
      border-radius: 0;
      text-transform: capitalize;
      font-size: 12px;
      color: #484848;
      background: transparent;
      font-weight: bold;
      box-shadow: none;
      border-bottom: 4px solid transparent;

      &.active {
        color: #6e87ff;
        border-bottom: 4px solid #6e87ff;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .payment-tab-content {
    margin-top: 10px;
    width: 100%;
    position: relative;
    cursor: auto;
  }
}

.payment-container {
  .col-xl-4 {
    @include media-breakpoint-up(xl) {
      padding: 0;
      margin: 0 16px;
    }
  }
  .col-xl-3 {
    @include media-breakpoint-up(xl) {
      flex: 0 0 26%;
      max-width: 26%;
      padding: 0;
      margin-left: auto;
    }
  }
  .card {
    border-radius: 16px;
    border: solid 1px #e0e0e0;
    box-shadow: none;
    padding: 24px;
    @include media-breakpoint-down(xs) {
      padding: 16px;
    }
    .card-header {
      background: #fff;
      border: none;
      h5 {
        margin-bottom: 32px;
        @include media-breakpoint-down(xs) {
          margin-bottom: 24px;
        }
      }
    }
    .card-body {
      .total-details {
        padding: 0;
        border: solid 1px #e5e5e5;
        border-left: none;
        border-right: none;
        border-radius: 0;
      }
      .add-address {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
        }
      }
      .contract-box {
        border-bottom: solid 1px #e5e5e5;
        padding-bottom: 16px;
        margin-bottom: 16px !important;
      }
      .same-text {
        margin: 0;
        padding: 0 !important;
      }
      // .modal-backdrop {
      //   display: none;
      // }
      // .modal-backdrop.active {
      //   @include media-breakpoint-down(xs) {
      //     display: block;
      //   }
      // }
    }
    .address-container {
      border-radius: 12px;
      padding: 16px;

      .d-flex.flex-column {
        @include media-breakpoint-down(xs) {
          max-width: 215px;
        }
      }
    }
    .delivery-address {
      display: none;
      position: absolute;
      left: 16px;
      background: #fff;
      z-index: 1111;
      padding: 0;
      margin: 0 8px;
      width: 360px;
      border-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      @include media-breakpoint-down(xs) {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 0;
        margin: 0;
        width: 100vw;
      }

      .delivery-address-box {
        box-shadow: 0 0 6px 0 rgba(153, 153, 153, 0.5);
        padding: 0 8px;
        border-radius: 12px;
        max-height: 590px;
        overflow-y: scroll;
        z-index: 1111;

        @include media-breakpoint-down(xs) {
          padding: 16px;
          border-radius: 0;
        }
        .alert.active {
          background-color: #f8f8f8;
        }
        h4 {
          font-size: 20px;
          font-weight: bold;
          color: #202124;
          margin: 24px 0;
        }
        span.header-bar {
          position: absolute;
          top: 8px;
          left: 44%;
          width: 55px;
          height: 4px;
          border-radius: 2px;
          background-color: #d8d8d8;
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
        .close-btn {
          position: absolute;
          right: 0;
          bottom: 100%;
          padding: 0;
        }
        .btn-default {
          margin: auto;
          @include media-breakpoint-down(xs) {
            background: #f4f4f4 !important;
            width: 100%;
            justify-content: center !important;
            border-radius: 12px;
            font-size: 14px;
            font-weight: 600;
            color: #484848;
          }
          svg {
            color: #6e87ff;
          }
        }
        div {
          margin: 8px 0;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #d8d8d8;
        }
        &::-webkit-scrollbar {
          width: 6px;
          border-radius: 8px;
        }
      }
    }
    .delivery-address.show {
      display: block;
    }
  }
  .cargo-container {
    padding: 8px 16px;
    .radio-container {
      margin: 8px 0;
    }
    ul {
      list-style: none;
      width: 100%;
      li {
        @include media-breakpoint-down(xs) {
          border-bottom: solid 1px #dedede;
        }
        &:last-child {
          border: 0;
        }
        span.cargo-text {
          @include media-breakpoint-down(xs) {
            margin-left: 16px;
          }
          span {
            @include media-breakpoint-down(xs) {
              font-size: 13px !important;
            }
          }
        }
      }
    }
  }
}
.basketCodeWrap button {
  border-radius: 0;
}

.basketCodeWrap button:focus:not(:focus-visible) {
  outline: 0;
}

.basketCodeWrap button,
.basketCodeWrap input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.basketCodeWrap button {
  text-transform: none;
}

.basketCodeWrap button {
  -webkit-appearance: button;
}

.basketCodeWrap button:not(:disabled) {
  cursor: pointer;
}

.basketCodeWrap .btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .basketCodeWrap .btn {
    transition: none;
  }
}

.basketCodeWrap .btn:hover {
  color: #212529;
}

.basketCodeWrap .btn:focus {
  outline: 0;
}

.basketCodeWrap .btn:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.basketCodeWrap .mb-0 {
  margin-bottom: 0 !important;
}

.basketCodeWrap input {
  outline: 0;
}

.basketCodeWrap .btn {
  transition: all 0.2s ease-in-out;
}

.disocuntCode {
  width: 100%;
  float: left;
  position: relative;
  clear: left;
  max-width: 350px;
  margin-bottom: 30px;
}

.disocuntCode input {
  background: #e5e5e5;
  border: none;
  width: 100%;
  height: 48px;
  border-radius: 9px;
  text-indent: 20px;
  font-size: 20px;
  color: #868697;
  font-weight: 500;
}

.disocuntCode button {
  position: absolute;
  right: 0;
}

.basketCodeWrap .btn-apply {
  background: #ff7100;
  height: 100%;
  border-radius: 0 9px 9px 0;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.basketCodeWrap .btn-apply:hover {
  color: #fff;
  background: #d86100;
}

.basketCodeWrap {
  width: 100%;
  float: left;
  margin-top: 0;
}

.basketCodeWrap .disocuntCode input {
  background: #f2f7fd;
  font-size: 16px;
  color: #86869794;
  height: 39px;
  font-size: 14px;
}

.basketCodeWrap .disocuntCode input::placeholder {
  font-size: 14px;
}

.basketCodeWrap .disocuntCode button.btn-apply {
  font-size: 15px;
  font-weight: 500;
}

.basketCodeWrap input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.basketCodeWrap button,
input {
  color: inherit;
  font: inherit;
  margin: 0;
}

.basketCodeWrap button {
  overflow: visible;
}

.basketCodeWrap button {
  text-transform: none;
}

.basketCodeWrap button {
  -webkit-appearance: button;
  cursor: pointer;
}

.basketCodeWrap input {
  line-height: normal;
  border-radius: 9px;
}

.disocuntCode input {
  padding: 0;
  font-weight: 600;
  margin: 0;
  color: #585859 !important;
  background-color: #ebf4ff !important;
  text-indent: 12px;
  background: #f4f4f4 !important;
}

.disocuntCode input:focus {
  box-shadow: inset #ff9800 0px 0 0px 2px;
}

.disocuntCode button {
  font-weight: 600 !important;
  background-color: #ff9800;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.disocuntCode button:hover {
  background-color: #e98c00;
}
.radio--container {
  position: absolute;
  right: 0;
  bottom: 50%;
  .radio {
    position: absolute;
    right: 8px;
    top: 0px;
    height: $radio-size + 10px;
    width: $radio-size + 10px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #979797;
  }

  .radio.active::after {
    content: "";
    position: absolute;
    top: 0;
    left: 1px;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #6e87ff;
  }
}

.expire-date {
  .date-item {
    border-radius: 8px;

    div {
      div {
        div {
          color: #202124;
          font-weight: 600;
        }
      }
    }
  }
}

.bank-info-container {
  @include media-breakpoint-down(xs) {
    padding-top: 24px;
  }
  p {
    font-size: 12px;
    color: #202124;
    font-weight: 500;
    margin-bottom: 8px;
    strong {
      font-weight: bold;
    }
  }
}
.doorpayment-container {
  @include media-breakpoint-down(xs) {
    padding-top: 24px;
  }
}

.success-bank-account-info {
  padding: 0;

  strong {
    font-size: 14px;
  }
  p {
    margin-top: 8px;
    font-size: 14px;
    padding: 12px 16px;
    background: #f4f4f4;
    border-radius: 12px;
    cursor: pointer;
  }
}

.success-icon {
  position: absolute;
  left: -60px;
  top: 8px;
  @include media-breakpoint-down(xs) {
    position: relative;
    left: auto;
    top: auto;
    margin-right: 8px;
  }
}

.success-container {
  padding: 32px 40px 32px 108px;
  border-radius: 16px;
  border: solid 1px #e0e0e0;
  margin: 32px 0;
  @include media-breakpoint-up(xl) {
    min-height: 330px;
  }
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
  h2 {
    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }
  }
  .order-no {
    @include media-breakpoint-down(xs) {
      margin-left: 68px;
    }
  }
}

.cvv-icon {
  position: relative;
  &:hover {
    .cvv-tooltip {
      display: flex;
    }
  }
  .cvv-tooltip {
    display: none;
    align-items: center;
    justify-content: space-around;
    padding: 17px 16px;
    min-width: 380px;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    bottom: -32px;
    left: 30px;
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(65, 61, 61, 0.5);
    background-color: #ffffff;
    @include media-breakpoint-down(md) {
      bottom: 140%;
      right: -40px;
      left: auto;
    }
    @include media-breakpoint-down(xs) {
      font-size: 14px;
      font-weight: normal;
      color: #000000;
      min-width: 300px;
    }
    &::before {
      content: "";
      position: absolute;
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 12px 10px 0;
      border-color: transparent #ffffff transparent transparent;
      filter: drop-shadow(-2px 0px 2px rgba(65, 61, 61, 0.2));
      @include media-breakpoint-down(md) {
        border-width: 10px 10px 0 12px;
        border-color: #ffffff transparent transparent transparent;
        top: 100%;
        left: 84%;
        filter: drop-shadow(0px 3px 1px rgba(65, 61, 61, 0.2));
      }
      @include media-breakpoint-down(xs) {
        left: auto;
        right: 38px;
      }
    }
    span {
      @include media-breakpoint-down(xs) {
        margin-left: 8px;
      }
    }
  }
}
@media (max-width: 420px) {
  .cvv-tooltip {
    width: 70vw;
    min-width: auto !important;
  }
}

.payment-padding {
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}

@media (max-width: 375px) {
  .same-text {
    label {
      margin-left: 30px !important;
    }
  }
  .order-no {
    margin-left: 0 !important;
  }
  .payment-label {
    font-size: 11px !important;
  }
}

.bkm-express-container {
  display: flex;
  flex-direction: column;

  ul {
    margin: 16px 0 0;
    padding: 0;
    li {
      list-style: none;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: #202124;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
