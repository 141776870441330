@media (max-width: 768px) {
  .registerModal.modal-container {
    max-height: 100vh;
    overflow-y: auto;
  }

  .registerModal .modal-body {
    max-height: 80vh;
    overflow-y: auto;
  }
}
