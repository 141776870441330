html {
  overflow-x: hidden;
}

@font-face {
  font-family: dgDigitalBold;
  src: url(assets/font/DS-DIGIB.TTF);
}

.product-detail-content iframe {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-scroller .nav-blog {
  list-style: none;
}

.nav-scroller .nav-blog li {
  display: inline-block;
}

.nav-scroller .nav li .nav-link {
  color: #484848 !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-scroller .nav li .nav-link-blog {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  height: 100%;
}

.nav-scroller .nav li .nav-link-blog:hover {
  background: #000000;
  color: #ffffff !important;
}

.nav-scroller .nav li .nav-link:hover {
  background: #c0cbfc;
  color: #6e87ff !important;
}

/* Mobile Menu Css Begin */

.menuMobile.active {
  left: 0;
  opacity: 1;
  visibility: visible;
  /*overflow: auto;*/
}

.menuMobile {
  width: 479px;
  position: fixed;
  left: -100%;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #fff;
  z-index: 9;
  opacity: 0;
  padding: 24px 31px;
  visibility: hidden;
  transition: all 0.3s ease-in;
  -moz-transition: all ease-in 0.3s;
  -webkit-transition: all 0.3s ease-in;
  z-index: 99;
  box-shadow: 10px 0 100px rgba(0, 0, 0, 0.1);
}

.menuMobile::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.menuMobile::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #d8d8d8;
}

.menuMobileBlog {
  display: none;
  width: 266px;
  position: fixed;
  left: -100%;
  top: 0;
  height: 100%;
  background: #000000;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in;
  -moz-transition: all ease-in 0.3s;
  -webkit-transition: all 0.3s ease-in;
  z-index: 99;
  box-shadow: 10px 0 100px rgba(0, 0, 0, 0.1);
}

.menu-mobile-backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.menuMobile ul li {
  list-style-type: none;
}

.closeMenuMobile {
  width: 44px;
  height: 44px;
  position: absolute;
  right: 26px;
  top: 30px;
  background-size: 15px 14px;
  background-position: 50%;
  /* background-color: rgb(0, 0, 0); */
  cursor: pointer;
}

.menuMobileList {
  width: 246px;
  display: table;
  margin: 7px auto 0;
  height: auto;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  opacity: 1;
  visibility: visible;
  padding-left: 10px;
}

.menuMobileList a:hover,
.dropMenuMbl a {
  text-decoration: none !important;
}

.menuMobileList li {
  width: 100%;
  float: left;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
  margin-top: 15px;
}

.menuMobileList li span {
  font-size: 16px;
  width: 100%;
  color: #ff7100;
}

.menuMobileList li svg {
  float: right;
}

.dropMenuMbl {
  position: absolute;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  left: -100%;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
  -moz-transition: all ease-in 0.2s;
  -webkit-transition: all 0.2s ease-in;
}

.dropMenuMbl.active {
  left: 0;
  opacity: 1;
  visibility: visible;
}

.dropTitle {
  width: 100%;
  float: left;
  height: 50px;
  padding-top: 15px;
  padding-left: 5px;
  background-color: #ff7100;
  color: white;
}

.dropTitle span {
  font-size: 16px;
  color: white;
  margin-left: 25px;
  font-weight: bold;
}

.lessonMenuLeft {
  float: left;
  width: 476px;
  margin-left: 54px;
  margin-top: 27px;
}

.lessonMenuLeft li {
  width: 33.33%;
  float: left;
  margin-top: 0;
  margin-left: 0 !important;
  margin-bottom: 19px;
  margin-right: 0;
}

.lessonMenuLeft li a:first-child {
  font-size: 18px;
  position: relative;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.lessonMenuLeft li a {
  font-size: 14px;
  color: #666 !important;
  padding: 0;
  float: left;
  clear: left;
  margin-bottom: 9px;
}

.lessonMenuLeft li a:first-child:before {
  content: "";
  background-size: 8px 12px;
  width: 8px;
  height: 12px;
  position: absolute;
  left: -13px;
  top: 3px;
}

.menuMobileList li.dropdown span:after {
  content: "";
  width: 8px;
  height: 12px;
  background-size: 100% auto;
  float: right;
  margin-right: 10px;
}

@media only screen and (max-width: 1169px) {
  .menuMobile {
    display: inherit;
  }

  .lessonMenuLeft {
    float: left;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .lessonMenuLeft li {
    width: 100%;
    float: left;
    margin-top: 0;
    margin-left: 0 !important;
    margin-bottom: 0;
    margin-right: 0;
    line-height: 43px;
  }

  .lessonMenuLeft li a {
    color: #ff7100 !important;
    float: left;
    margin-bottom: 0;
    width: calc(100% - 10px);
    padding: 0 0 0 10px;
    background: #fff;
    border-bottom: 1px solid #ebebeb;
  }
}

/* Mobile Menu Css End */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.highlight-phone {
  color: #313437;
  background-color: #eef4f7;
  padding: 50px 0 55px;
  overflow: hidden;
}

.player-container {
  background-color: red;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio */
  position: relative;
  /* If you want text inside of it */
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.video-preview {
  width: 342px;
  height: 320px;
}

.chart-metric svg {
  transform: rotate(180deg);
}

.progress-thick {
  height: 2.4rem;
}

.btn-circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

button.text-transform-none {
  text-transform: none;
}

.table.order-table td {
  padding: 0.5rem;
}

.verified-badge {
  border-radius: 8px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
  background: #f4f4f4 !important;
}

.mapcontainer {
  display: block;
  width: 272px;
  height: 151px;
}

.mapcontainer .card-img-top {
  display: block;
  width: 272px;
  height: 151px;
  position: relative;
  overflow: hidden;
}

.mapcontainer .card-img-top .map-c {
  display: block;
  width: 600px;
  height: 400px;
}

/*
.mapcontainer > div > div{
  display: block;
  width: 272px;
  height: 151px;
}
*/

.synapsis-vote-container {
  position: relative !important;
  margin-bottom: 12px;
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .menuMobile {
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.new-design-toggle {
  position: fixed;
  right: 10px;
  bottom: 70px;
  z-index: 99999;
  transition: transform 0.3s;
  transform: scale(var(--scale, 1)) translateZ(0);
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.new-design-toggle input {
  display: none;
}

.new-design-toggle input + label {
  background: #fff;
  border-radius: 9px;
  padding: 16px 12px 16px 12px;
  display: block;
  cursor: pointer;
  position: relative;
  margin: 0;
}

.new-design-toggle input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(160deg, #f1f4ff, #f9faff);
  opacity: var(--gradient, 0);
  transition: opacity 0.4s;
}

.new-design-toggle input + label .switch {
  position: relative;
  display: inline-block;
  z-index: 1;
  vertical-align: top;
  height: 22px;
  width: 40px;
  border-radius: 11px;
  background: #eceffc;
}

.new-design-toggle input + label .switch:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(90deg, #4f97ff, #275efe);
  opacity: var(--gradient, 0);
  transition: opacity 0.4s;
}

.new-design-toggle input + label .switch .dot {
  background: #d1d6ee;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  left: -1px;
  top: -1px;
  transform: translateX(var(--offset, 0));
  transition:
    transform 0.4s,
    box-shadow 0.4s;
  box-shadow:
    -4px -4px 8px var(--light-shadow-2, transparent),
    4px 4px 8px var(--shadow, transparent);
}

.new-design-toggle input + label .switch .dot:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: linear-gradient(160deg, #f1f4ff, #f9faff);
  opacity: var(--gradient, 0);
  transition: opacity 0.4s;
}

.new-design-toggle input + label span {
  line-height: 22px;
  font-size: 13px;
  color: var(--text, #5806ab);
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  z-index: 1;
  position: relative;
  margin-left: 12px;
  transition: color 0.4s;
}

.new-design-toggle input + label + span {
  text-align: center;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  opacity: 0;
  font-size: 12px;
  font-weight: 500;
  color: #a6accd;
  transform: translateY(4px);
  transition:
    opacity 0.4s,
    transform 0.4s;
}

.new-design-toggle input:not(:checked):not(:disabled) + label + span {
  opacity: 1;
  transform: translateY(12px);
}

.new-design-toggle input:checked + label {
  --offset: 18px;
  --text: #580a6b;
  --gradient: 1;
  --shadow: rgba(0, 6, 39, 0.1);
  --light-shadow: rgba(255, 255, 255, 0.8);
  --light-shadow-2: rgba(255, 255, 255, 0.1);
}

.upper-element {
  z-index: 9999 !important;
}

.menuMobile {
  overflow-x: auto;
}

.menuMobile .menuMobileList li .menu-dropdown {
  position: relative;
  left: 0;
  width: 100%;
}

.menuMobileList .menu-mobile-container .menu-mobile-arrow.active {
  transform: rotate(90deg);
}
@media screen and (max-width: 450px) {
  .pdf__viewer {
    flex-direction: column;
  }

  .pdf__viewer .pdf__viewer-buttons {
    margin-top: 1rem;
  }
}

.ekDokumanCard,
.koclukCard {
  border-radius: 0.25rem !important;
}

.kitapPdfCard {
  box-shadow: none !important;
  border-radius: 0.25rem !important;
  border: 1px solid #6f7af5 !important;
  box-shadow: none;
}

.koclukBtn {
  background-color: rgba(197, 255, 247, 0.3) !important;
  color: #20baa6 !important;
  border-color: rgba(197, 255, 247, 0.3) !important;
  font-weight: 600 !important;
}

.ekDokumanBtn {
  background-color: rgba(201, 198, 255, 0.3) !important;
  color: #6c63ff !important;
  border-color: rgba(201, 198, 255, 0.3) !important;
  font-weight: 600 !important;
}

.kitapPdfBtn {
  color: #6c63ff !important;
  border-color: rgba(201, 198, 255, 0.3) !important;
  font-weight: 600 !important;
  background-color: #e7e6f6 !important;
  height: 50px;
  border-bottom: 1px solid #6f7af5 !important;
}

.tongucworks {
  text-align: center;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 14px;
  margin-bottom: 30px;
}

.tgcWorksContain {
  border-top: 1px solid rgba(151, 151, 151, 0.15);
}

.tongucworks .tgcMagaza img {
  height: 22px;
}

.tongucworks .tgcWorks img {
  height: 22px;
}

.tongucworks .pe-2 {
  padding-right: 10px;
}

.copyRight {
  text-align: center;
  font-size: 14px;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .footer-accordion-container {
    padding-bottom: 0px !important;
  }
}

.brand-list .card-title {
  font-weight: 600;
}

@media (max-width: 575px) {
  .brand-list .card-brand-small .btn-link {
    position: absolute;
    top: 0.5rem !important;
    right: 0.5rem !important;
    padding: 0 !important;
  }

  .brand-list .feature-brand .card-body {
    padding-bottom: 20px !important;
  }

  .brand-list .feature-brand .card-text {
    padding-bottom: 0 !important;
  }

  .brand-list .col-12 {
    padding: 0 !important;
  }
}

.order-detail
  .list-group-item
  .d-flex.justify-content-between
  .icon-box.icon-contain {
  font-family: unset !important;
  line-height: unset;
}

.order-detail
  .list-group-item
  .d-flex.justify-content-between
  .icon-box.icon-contain
  .icon {
  margin-right: 9px;
  margin-top: 3px;
}

.ps-25 {
  padding-left: 25px;
}

.kargoTakipNoText br {
  display: none;
}

@media (max-width: 1200px) {
  .ps-25 {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .kargoTakipNoText br {
    display: block;
  }

  .main-book-slider .slick-slider .slick-track,
  .main-book-slider .slick-slider .slick-list {
    display: flex !important;
  }
}

.menuMobileList .menu-mobile-container .menu-mobile-img img {
  object-fit: contain;
}

.markalar-menu-item {
  background-color: #d8ddf159 !important;
}

.markalar-menu-item .menu-mobile-box,
.markalar-menu-item .menu-mobile-box h6 {
  color: rgb(8 19 77) !important;
}

.brandsProductList.product-group .advertorialView {
  grid-column: span 3;
}

@media (max-width: 767.98px) {
  .brandsProductList.product-group .advertorialView {
    grid-column: span 2;
  }
}

.favoriteBrand .card-close-btn {
  position: absolute;
  display: none;
  z-index: 99;
  right: 0.5rem;
  top: 0.5rem;
  background: #f4f4f4;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 40px;
  padding: 0 !important;
  cursor: pointer;
}

.favoriteBrand:hover .card-close-btn {
  display: block;
}

.favoriteBrand:hover {
  box-shadow: 0 0 11px 0 rgba(136, 136, 136, 0.5) !important;
  transition: 0.1s;
  animation: shake 0.2s;
}

.favoriteBrand .card-close-btn img {
  width: 21px;
  height: 21px;
}

@media (max-width: 475px) {
  .favoriteBrand .card-title {
    height: 42px;
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {
  .p-0-mobile {
    padding: 0 !important;
  }

  .brandShow .brand-show-top-box {
    margin-top: 3px;
  }

  .brandShow .brand-show-top-box h3 {
    font-size: 1.2rem;
  }

  .brandShow .brand-show-top-box p {
    font-size: 13px !important;
  }
}

@media (max-width: 350px) {
  .brandShow .brand-show-mark-logo {
    width: 70px;
  }

  .brandShow .brand-show-top-box h3 {
    font-size: 1.1rem;
  }
}

.book-detail-container {
  display: flex !important;
}

.cardInfoText {
  color: #ae2e24;
}

.price-old {
  font-size: 23px;
  color: #9b9b9b;
  line-height: 30px;
  text-decoration: line-through;
  margin-right: 8px;
  font-weight: 400;
}

.price-old-card {
  line-height: 14px;
  text-align: right;
  color: #9b9b9bd1;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  display: inline-block;
  float: right;
}

.text-decoration-none .price-old-card::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 50%;
  top: 45%;
  height: 1.1px;
  background-color: #9b9b9b;
  transform: translate(-50%, -50%);
}

.price-with-tax {
  color: rgba(145, 145, 145, 0.8);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-decoration: line-through;
}

.slide-price-old {
  color: rgb(145, 145, 145) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  text-decoration: line-through;
}

.slide-price-old.price-card-slider {
  font-size: 11px !important;
}

.sticky-old-price {
  color: rgb(145, 145, 145) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-decoration: line-through;
  margin-right: 8px;
  line-height: 1.75;
}

.detail-box-mobile .sticky-old-price {
  font-size: 12px !important;
}
.speacial-bundle {
  align-items: center;
  padding-left: 15px;
}
.speacial-bundle .slide-price-old {
  min-width: 60px;
  margin-right: 4px;
  text-align: right;
}

.product-book-recommendation-container
  .price-container
  .recommendation-box-details
  .recommendation-item
  span {
  width: auto !important;
}

.calculate-old-price {
  color: rgb(145, 145, 145) !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-decoration: line-through;
  margin-right: 8px;
}

@media (max-width: 991px) {
  .bundle-product-container {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .price-old-card {
    text-align: left;
    float: unset;
  }

  .speacial-bundle {
    flex-wrap: wrap;
  }

  .speacial-bundle span {
    display: block;
    width: 100% !important;
    text-align: right;
  }

  .speacial-bundle .slide-price-old {
    margin-right: 0;
  }

  .product-book-recommendation-container
    .price-container
    .recommendation-box-details
    .recommendation-item {
    padding-right: 0;
  }

  .calculate-old-price {
    font-size: 16px !important;
  }

  .calculate-real-price {
    font-size: 18px !important;
  }
}

@media (max-width: 575.98px) {
  .price-old-card {
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .price-old {
    font-size: 18px;
    line-height: 18px;
  }

  .calculate-old-price {
    font-size: 14px !important;
  }

  .calculate-real-price {
    font-size: 16px !important;
  }
}

@media (max-width: 360px) {
  .bundle-product-container .price-box {
    flex-wrap: wrap;
    margin: 0;
    justify-content: center !important;
  }

  .bundle-product-container .price-box .total-price {
    width: 100%;
    text-align: center;
  }
}
