.stores-comment-btn {
  background: #ff6a00;
  padding: 3px 5px;
  font-size: 12px;
  text-transform: capitalize;
}

.stores-item {
  border-bottom: 1px solid #dedede;
  padding: 10px 5px;
  margin-right: 10px;
}

.stores-max-height {
  max-height: 300px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    overflow: hidden;
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
  }
}

.stores-comment-modal {
  .modal-dialog {
    width: 400px;
  }

  .modal-content {
    padding: 10px;
    border-radius: 8px;
  }
}

@media (max-width: 600px) {
  .stores-comment-modal {
    .modal-dialog {
      width: auto;
    }
  }
}
