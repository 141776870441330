.orders-details.card {
  box-shadow: none;
  border: solid 1px #e5e5e5;
  .card-body {
    padding: 24px;
  }
}
.btn-order-status {
  border-radius: 12px;
  box-shadow: 0 0 2px 0 #afafaf;
  width: 216px;
  height: 48px;
  font-weight: bold;
  font-size: 16px;
}

.order-detail-container.card {
  border-radius: 16px;
  border: solid 1px #e5e5e5;
  box-shadow: none;
  .card-body {
    padding: 24px;
    @include media-breakpoint-down(xs) {
      padding: 16px;
    }
    .d-flex.order-status {
      padding: 8px;
      border-radius: 16px;
      color: #015314;
      width: 188px;
      margin-right: 12px;
      .icon-box {
        padding-right: 10px;

        img {
          display: block;
          width: 25px;
          height: 25px;
        }
      }
    }
    .order-table {
      td {
        @include media-breakpoint-down(xs) {
          padding: 5px 0;
        }
      }
    }
    .card.product-card-md {
      cursor: pointer;
    }
  }
}

.order-mobile-item.show {
  .card-header {
    .accordion-icon {
      transform: rotate(-90deg);
    }
  }
  .card-body {
    display: block;
  }
}
.order-mobile-item {
  border-radius: 8px;
  border: solid 1px #e5e5e5;
  padding: 16px 16px 8px;
  margin-bottom: 16px;
  @include media-breakpoint-up(md) {
    display: none;
  }
  hr {
    margin: 8px 0;
  }
  .card-header {
    background: transparent;
    border: 0;
    padding: 0;
    .accordion-icon {
      transform: rotate(90deg);
      transition: 0.3s;
    }
  }
  .card-body {
    display: none;
    padding: 0;
    ul {
      padding: 0;
      li {
        padding: 0;
        margin-bottom: 14px;
        h6 {
          margin: 0;
        }
      }
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    background: transparent;
    border: 0;
    padding: 0 !important;
    .btn-order-status {
      padding: 0;
    }
  }
}

.order-detail {
  border-radius: 8px;

  .list-group-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    background: transparent;
    padding: 8px;
    .d-flex.justify-content-between {
      padding: 8px;
      @include media-breakpoint-down(xs) {
        background-color: transparent !important;
      }
      .icon-box {
        padding-right: 10px;

        img {
          display: block;
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}

.order-contracts {
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  @include media-breakpoint-down(xs) {
    width: auto;
  }
  a {
    width: 200px;
  }
}
