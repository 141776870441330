$md-checkbox-checked-color: var(--blue);
$md-checkbox-border-color: rgba(0, 0, 0, 0.34);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 1em 0.5em;
$md-checkbox-size: 1.8em;
$md-checkbox-padding: 0.25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.25em;
$md-checkmark-width: 0.25em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 0.75em;

#form-check-special {
  margin-top: -3px !important;
}

.form-group.form-check {
  position: relative;
  margin: 12px 0;
  text-align: left;

  &.form-check-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline-block;
    margin-left: 10px;
    line-height: $md-checkbox-size;
    vertical-align: top;
    clear: both;
    padding-left: 1px !important;
    font-size: 13px;
    font-weight: 600;
    color: #484848;
    user-select: none;

    &:not(:empty) {
      padding-left: $md-checkbox-label-padding;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid $md-checkbox-border-color;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background 0.3s;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;
    margin: -5px;

    &:checked {
      + label:before {
        background: $md-checkbox-checked-color;
        border: none;
      }

      + label:after {
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate(
            $md-checkbox-padding,
            ($md-checkbox-size / 2) - ($md-checkmark-size / 2.6)
          )
          rotate(-45deg);
        width: $md-checkmark-size;
        height: $md-checkmark-size / 2;

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }

    &:disabled {
      + label:before {
        border-color: $md-checkbox-border-color-disabled;
      }

      &:checked {
        + label:before {
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
}
