.h1,
h1 {
  font-size: 1.5rem;
}

.h2,
h2 {
  font-size: 1.4rem;
}

.h3,
h3 {
  font-size: 1.3rem;
}

.h4,
h4 {
  font-size: 1.2rem;
}

.h5,
h5 {
  font-size: 1.1rem;
}

.h6,
h6 {
  font-size: 1rem;
}

figure {
  margin: 0;
}

.btn-primary {
  line-height: 1.5rem;
}
.btn-orange {
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #afafaf;
  background-color: #ff6a00;
  width: 329px;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  &:hover {
    color: #ffffff !important;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-bottom: 24px;
  }
}

.btn-action {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0 !important;

  &:focus {
    outline: 0 !important;
  }
}

small {
  word-break: break-word;
}

.card-product {
  min-width: 255px;
}

.card-product a {
  margin-left: auto;
  margin-right: auto;
}

.card-score {
  min-width: 240px;
}

.card-coin {
  .card-img-top {
    img {
      height: 143px;
      @include media-breakpoint-down(xs) {
        height: auto;
      }
    }
  }

  .text-price {
    font-size: 24px;
    font-weight: 600;
    vertical-align: top;
    margin: 0 0.25rem !important;
  }

  .badge {
    border-radius: 0.25rem !important;
    text-transform: uppercase;
    height: 32px;
  }
}

// .card-coin img {
//   max-width: 220px;
//   margin-left: auto;
//   margin-right: auto;
// }

.card-address {
  min-width: 280px;
}

.card-metric {
  min-width: 400px;
}

.card-badge {
  min-width: 140px;
}

.card-video {
  min-width: 320px;
}

.card-shopping-cart {
  min-width: 295px;
  max-width: 300px;

  button {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .basket-product-img {
    width: 50px;
    height: 64px;
    object-fit: cover;
  }
}

.card-register {
  min-width: 200px;
}

.card-advertorial {
  max-width: 720px;
}

.card-avatar {
  min-width: 220px;
  min-height: 265px;
}

.card-application {
  min-width: 220px;
  min-height: 265px;
}

@media (max-width: 992px) {
  .card-search {
    width: 100%;
  }

  .header-links {
    position: absolute;
    right: 0;
    top: 10px;
  }

  .header-links .btn-group {
    padding-top: ($spacer * 0.25) !important;
    padding-bottom: ($spacer * 0.25) !important;
  }

  .card-img-overlay {
    padding: 0.75rem;
  }

  .row {
    margin: 0;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    // padding-right: 0px;
    // padding-left: 0px;
  }

  .card-product {
    min-width: 255px;
    max-width: 300px;
  }

  .card-address {
    max-width: calc(100vw - 20px);
  }

  .modal {
    // width: 50%;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0 !important;
  }

  .modal .modal-dialog {
    margin: 0;
  }
}

.card-advertorial figure img {
  @include media-breakpoint-only(xs) {
    max-width: 128px;
  }

  @include media-breakpoint-only(sm) {
    max-width: 180px;
  }

  @include media-breakpoint-only(md) {
    max-width: 180px;
  }
}

.card-advertorial figure img.logo {
  @include media-breakpoint-only(xs) {
    max-width: 56px;
  }

  @include media-breakpoint-only(sm) {
    max-width: 64px;
  }

  @include media-breakpoint-only(md) {
    max-width: 64px;
  }
}

.columns-chapters {
  @include media-breakpoint-only(xs) {
    column-count: 1;
  }

  @include media-breakpoint-only(sm) {
    column-count: 2;
  }

  @include media-breakpoint-only(md) {
    column-count: 3;
  }

  @include media-breakpoint-only(lg) {
    column-count: 3;
  }

  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

.columns-badge {
  @include media-breakpoint-only(xs) {
    column-count: 2;
  }

  @include media-breakpoint-only(sm) {
    column-count: 2;
  }

  @include media-breakpoint-only(md) {
    column-count: 3;
  }

  @include media-breakpoint-only(lg) {
    column-count: 3;
  }

  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}

@media (max-width: 991px) {
  .hidden-lg {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: 567px) {
  .breadcrumb {
    padding: 0;
  }
}

@media (min-width: 568px) {
  .breadcrumb {
    padding: 0;
  }
}

@media (min-width: 992px) {
  .d-none-lg {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .d-none-md {
    display: none !important;
  }
}

@media (min-width: 568px) and (max-width: 992px) {
  .d-none-sm {
    display: none !important;
  }
}

@media (max-width: 568px) {
  .d-none-xs {
    display: none !important;
  }
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-24 {
  font-size: 24px !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.font-weight-bold {
  font-weight: bold;
}
.border-radius-0 {
  border-radius: 0 !important;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-16 {
  border-radius: 16px;
}
.border-radius-12 {
  border-radius: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.word-break-all {
  word-break: break-all;
}

.box-shadow-none {
  box-shadow: none !important;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.outline-none {
  outline: none;
}

@media (min-width: 1200px) {
  @for $val from 0 to 100 {
    .w-xl-#{$val} {
      width: #{$val}% !important;
    }

    $val: $val + 25;
  }
}

@media (min-width: 992px) {
  @for $val from 0 to 100 {
    .w-lg-#{$val} {
      width: #{$val}% !important;
    }

    $val: $val + 25;
  }
}

@media (min-width: 768px) {
  @for $val from 0 to 100 {
    .w-md-#{$val} {
      width: #{$val}% !important;
    }

    $val: $val + 25;
  }
}

@media (min-width: 568px) {
  @for $val from 0 to 100 {
    .w-sm-#{$val} {
      width: #{$val}% !important;
    }

    $val: $val + 25;
  }
}

.border-none {
  border: none !important;
}
.border-e5e5e5 {
  border: solid 1px #e5e5e5 !important;
}
.border-top-dedede {
  border: 0;
  border-top: solid 1px #dedede !important;
  padding-top: 16px !important;
}
.border-bottom-c6c6c6 {
  border-bottom: 2px solid #c6c6c6;
}
.box-shadow-none {
  box-shadow: none !important;
}
.z-index-1 {
  position: relative;
  z-index: 1;
}

.z-index-2 {
  position: relative;
  z-index: 2;
}

.z-index-3 {
  position: relative;
  z-index: 3;
}

.z-index-4 {
  position: relative;
  z-index: 4;
}

.payment-img {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.text-decoration-none,
.text-decoration-none * {
  text-decoration: none !important;
}

.badge-light-blue {
  background: #00a3da;
}

.bl-0 {
  border-left: 0;
}

.br-0 {
  border-right: 0;
}

.bt-0 {
  border-top: 0;
}

.bb-0 {
  border-bottom: 0;
}

.p-xs-0 {
  @include media-breakpoint-down(xs) {
    padding: 0 !important;
  }
}
.p-xs-5 {
  @include media-breakpoint-down(xs) {
    padding: 0 5px !important;
  }
}
.p-sm-0 {
  @include media-breakpoint-down(sm) {
    padding: 0 !important;
  }
}
.w-xs-100 {
  @include media-breakpoint-down(xs) {
    width: 100% !important;
    max-width: none !important;
  }
}
.p-custom-md-0 {
  @include media-breakpoint-down(md) {
    padding: 0;
  }
}

@for $var from 0 to 360 {
  .rotate-#{$var} {
    transform: rotate(#{$var}deg);
  }

  $var: $var + 45;
}

.rotate-180 {
  transform: "rotate(180deg)";
}
