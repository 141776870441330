@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  25% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  75% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.product-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  row-gap: 32px;
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include media-breakpoint-down(sm) {
    column-gap: 8px;
    row-gap: 24px;
  }
  .product-card {
    box-shadow: none !important;
    padding: 0rem !important;
    background: white;
    transition: 0.1s;
    user-select: none;
    cursor: pointer;
    .product-card-inner {
      border-radius: 12px;
      &:hover {
        box-shadow: 0 0 11px 0 rgba(136, 136, 136, 0.5) !important;
        transition: 0.1s;
        animation: shake 0.2s;
        .add-to-cart {
          display: inline-block !important;
        }
        .add-to-notify-me {
          display: inline-block !important;
        }
        .favorite-icon-box {
          display: flex !important;
        }
        .favories-close-button {
          display: block;
        }
      }
    }

    figure {
      text-align: center !important;
      overflow: hidden;

      & img {
        width: 100%;
        height: 100%;
      }
    }
    .product-badge {
      height: 30px;
      padding: 4px 8px;
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 0.22px;
      border-radius: 17px;
      border: solid 2px #ffffff;
    }
    .badge-super-price {
      color: #d0000f;
      background-color: #fde5e7;
    }
    .badge-book-week {
      color: #00167e;
      background-color: #edeeff;
    }
    .badge-speed-camp {
      color: #895700;
      background-color: #ffe9ca;
    }
    .badge-chance-set {
      color: #154a00;
      background-color: #e6ffdc;
    }
    .badge-indispensable {
      color: #7a004a;
      background-color: #ffcbf2;
    }
    .badge-lgs-indispensable {
      color: #005258;
      background-color: #cbfcff;
    }
    .badge-product-indispensable {
      color: #005258;
      background-color: #cbfcff;
    }
    .badge-new-released {
      color: #003929;
      background-color: #eefffa;
    }
    .card-footer {
      background-color: transparent;
      padding: 0 16px 14px;
      border-top: 0;
    }
    .product-title {
      width: 100%;
      font-size: 12px;
      font-weight: 500;
      color: #2a2a2a;
      margin-bottom: 0;
      height: 32px;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        margin-bottom: 8px;
        height: 32px;
      }
    }
    .favories-close-button {
      position: absolute;
      display: none;
      z-index: 99;
      right: 0.5rem;
      top: 0.5rem;
      background: #f4f4f4;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 40px;
      padding: 0 !important;
      cursor: pointer;
    }
    .product-features {
      display: flex;
      position: absolute;
      top: 8px;
      width: 100%;
      flex-wrap: wrap;
      gap: 4px;
      .favorite-icon-box {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 8px;
        border-radius: 50%;
        display: none;
        justify-content: center;
        align-items: center;
        background: #f4f4f4;
        z-index: 1;
        .favorite-icon {
          width: 24px;
          height: 24px;
        }
      }

      .new-product-badge {
        width: 42px;
        height: 24px;
        margin-left: 10px;

        border-radius: 4px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

        background: #74de00;
        color: white;
      }
    }
    .support-icons {
      display: inline-flex;
      height: 32px;
      padding: 6px 8px;
      margin-left: 10px;

      border: 1px solid #f2f2f2;
      border-radius: 16px;

      img {
        height: 100%;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .product-operations {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 36px;

      .price {
        color: #f34854;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
      }

      .price-pale {
        font-size: 18px;
        font-weight: 400;
        color: #707070;
      }

      .add-to-cart {
        display: none;
        background: #f34854;
        color: #ffffff;
        padding: 4px 8px;
        transition: 0.1s all linear;
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
        &:hover {
          background: #f85560;
        }
      }

      .add-to-notify-me {
        display: none;
        background: #fde7d8;
        border-radius: 8px;
        color: #ffa96b;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        text-transform: uppercase;
        padding: 4px 8px;
        &:hover {
          background: #fdd3b8;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      // padding: 0.25rem !important;
      // margin-left: 1rem;
      // margin-right: 1rem;
      .product-operations {
        border: 1px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .add-to-cart {
          display: flex;
          background: transparent;
          color: #545454;
          font-size: 12px;
          align-items: center;
          padding: 0 8px;
          justify-content: center;
          border-left: 1px;
          cursor: pointer;
          border-radius: 0;
          // &::before {
          //   content: "";
          //   width: 1px;
          //   height: 18px;
          //   background: #c0c0c0;
          //   position: relative;
          //   left: -8px;
          // }
        }
        .add-to-notify-me {
          display: flex;
          background: transparent;
          color: #545454;
          font-size: 12px;
          align-items: center;
          padding: 0 8px;
          justify-content: center;
          border-left: 1px;
          cursor: pointer;
          border-radius: 0;
        }
        .price {
          font-size: 12px;
          display: flex;
          justify-content: center;
          color: #545454;
        }
        .price-pale {
          font-size: 12px;
          display: flex;
          justify-content: center;
          color: #545454;
        }
      }
    }
    @include media-breakpoint-down(xs) {
      -webkit-justify-content: center;
      justify-content: center;
      .product-card {
        margin: 0 0.5rem 1.5rem 0.5rem;
        .card-footer {
          .product-features {
            margin-bottom: 0 !important;
          }

          .product-operations {
            button {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  &.active {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    .product-card {
      .product-operations {
        .add-to-cart {
          width: 107px;
          height: 28px;
          font-size: 12px;
        }
        .add-to-notify-me {
          width: 107px;
          height: 28px;
          font-size: 12px;
        }
      }
    }
  }
}
.card.product-card {
  &::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: -10px;
    width: 109%;
    height: 1px;
    background-color: #f4f4f4;
    @include media-breakpoint-down(lg) {
      width: 111%;
    }
    @include media-breakpoint-down(sm) {
      content: none;
    }
  }
}
.product-card-md {
  box-shadow: none !important;
  width: 112px;
  height: 203px;
  margin-right: 1.5rem;
  margin-bottom: 1rem;

  figure {
    width: 112px;
    height: 143.1px;
    text-align: center !important;
    margin: 0 !important;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .card-body {
    padding: 0 !important;
    @include media-breakpoint-up(sm) {
      padding: 0.5rem 0 0 0 !important;
    }
    .card-title {
      @include media-breakpoint-up(sm) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
      }
    }
  }

  .card-footer {
    background-color: transparent;
    padding: 0 0.25rem;
    border-top: 0;
  }
}

@media (max-width: 500px) {
  .product-group,
  .product-group.active {
    justify-content: space-around !important;
  }
  .product-group .product-card,
  .product-group.active .product-card {
    .product-badge {
      font-size: 11px;
      border-radius: 7px;
    }
    .card-footer {
      padding: 0;
    }
  }
}
@media (max-width: 390px) {
  .product-group,
  .product-group.active {
    .product-card {
      padding: 0 !important;
      .product-operations {
        .price {
          font-size: 10px;
        }
        .add-to-cart {
          font-size: 10px;
        }
        .add-to-notify-me {
          font-size: 10px;
        }
      }
    }
  }
}

.mobile-product {
  height: auto !important;
  width: 100% !important;
}
@media (max-width: 767.98px) {
  .product-group .product-card .product-operations {
    border: 0px solid silver;
    height: auto;
  }

  .product-group .product-card .product-operations .add-to-cart,
  .product-group .product-card .product-operations .add-to-notify-me {
    background: #f34854 !important;
    color: #fff;
    padding: 4px 8px;
    transition: all 0.1s linear;
    border-radius: 8px;
    width: 100%;
  }

  .product-group .product-card .product-operations {
    display: block;
  }

  .product-group .product-card .product-operations .price {
    text-align: left;
    justify-content: flex-start !important;
    font-size: 16px !important;
    margin-bottom: 10px;
  }

  .product-group.active .product-card .card-footer,
  .product-group .product-card .card-footer {
    padding: 10px;
  }
}
