@for $val from 1 to 6 {
  .flex-#{$val} {
    flex: #{$val} 0 0 !important;
  }
}

@media (max-width: 992px) {
  @for $val from 1 to 6 {
    .flex-lg-#{$val} {
      flex: #{$val} 0 0 !important;
    }
  }
}

@media (max-width: 768px) {
  @for $val from 1 to 6 {
    .flex-md-#{$val} {
      flex: #{$val} 0 0 !important;
    }
  }
}

@media (max-width: 576px) {
  @for $val from 1 to 6 {
    .flex-sm-#{$val} {
      flex: #{$val} 0 0 !important;
    }
  }
}
