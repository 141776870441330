.badge-score,
.badge-coin {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202124;
  background: #fff1da;
  width: 132px;
}

.badge-rosette {
  background-color: #ffcc3c;
  color: #a67d0a;
  width: 132px;
  @include media-breakpoint-down(xs) {
    width: auto;
  }
}
.badge-score-custom {
  background: #e4eaf7;
  color: #e7792b;
  width: 132px;
  font-weight: 600;
  font-size: 14px !important;
  @include media-breakpoint-down(xs) {
    width: auto;
  }
}

.badge-pill {
  display: inline-flex;
  align-items: center;
  // padding: 0;

  .branch-title {
    padding: 0 0 3px 7px;
    font-size: 8px;
    font-weight: 700;
    margin: 0;
  }

  .badge-title {
    font-size: 14px;
    font-weight: bold;
    padding-left: 7px;
    padding-right: 15px;
    margin: 0;
    @include media-breakpoint-only(xs) {
      font-size: 12px;
    }
  }

  .badge-sub-title {
    font-size: 12px;
    font-weight: bold;
    padding-left: 7px;
    padding-right: 15px;
    margin: 0;
  }

  img {
    height: 32px;
    width: 32px;
    user-select: none;

    @include media-breakpoint-only(xs) {
      width: 24px;
      height: 24px;
    }
  }
}

.img-badge {
  display: inline-flex;
  align-items: center;

  min-height: 32px;
  background: #e4eaf7;
  border-radius: 16px;

  &.small {
    min-height: 24px;

    img {
      height: 24px;
      width: 24px;
    }
  }

  img {
    height: 32px;
    width: 32px;
    user-select: none;
  }

  span {
    padding-left: 7px;
    padding-right: 15px;
    color: var(--primary);
    font-size: 14px;
    font-weight: bold;
    min-width: 110px;
    text-align: center;
  }
}
