.desk-profile-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  overflow: hidden;
}

.position-absolute {
  z-index: 1;
}

.bg-fffbef {
  background: #fffbef;
}

.badge.badge-pill.small {
  width: auto;
  height: 22px;
  font-size: 12px;
  padding: 16px 12px;
  img {
    height: 100%;
  }
}

@media (max-width: 568px) {
  .profile-section {
    padding-bottom: 1rem;
    border-bottom: 1px solid #dedede;
    border-right: 0 !important;
    .desk-profile-img {
      width: 70px;
      height: 70px;
    }
  }

  .badge.badge-pill.sm-small {
    width: auto;
    height: 26px;
    font-size: 12px;

    img {
      height: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .card-columns .card {
    max-height: 249.88px;
  }

  .card-columns .card figure {
    max-height: 249.88px;
  }

  .card-columns .card figure img {
    max-height: 249.88px;
  }
}

.profile-section {
  h2 {
    @include media-breakpoint-down(xs) {
      font-size: 14px !important;
    }
  }
  .text-left {
    div {
      @include media-breakpoint-down(xs) {
        font-size: 13px !important;
        font-weight: 500 !important;
      }
    }
  }
}
.tats-profile {
  .tats-profile-info {
    .desk-profile-img {
      @include media-breakpoint-down(xs) {
        width: 88px;
        height: 88px;
      }
    }
    .fs-20.color-484848 {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
      }
    }
    .fs-16.color-919191 {
      @include media-breakpoint-down(xs) {
        font-size: 12px !important;
      }
    }
  }
}

.tats-banner-container {
  display: flex;
  align-items: center;
  padding: 23px 32px;
  border-radius: 16px;
  background: url("../../../images/banner/tats-banner-bg.svg"),
    linear-gradient(90deg, rgba(93, 76, 255, 1) 0%, rgba(166, 104, 255, 1) 100%);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: center;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    background-position-x: 50%;
    background-position-y: 25%;
    padding: 23px 20px;
  }

  .banner-content {
    width: 50%;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      width: auto;
      margin-bottom: 9px;
    }
    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 16px;
      @include media-breakpoint-down(xs) {
        font-size: 16px;
        font-weight: bold;
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
  }
  .banner-button-container {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-down(sm) {
      width: auto;
    }
    button {
      width: 302px;
      height: 50px;
      border-radius: 8px;
      box-shadow: 0 7px 15px -6px rgba(0, 0, 0, 0.5);
      background-color: #ff6a00;
      border: 0;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      @include media-breakpoint-down(sm) {
        width: 270px;
      }
    }
  }
}
