.form-control {
  background: #f4f4f4;
  border: 0;
  border-radius: 8px; // height: calc(1.5em + 1.375rem + 2px);
  height: 48px;
  &.clear {
    background: transparent;
    box-shadow: none !important;
  }

  & + .error {
    display: none;
  }

  &:invalid {
    border: 1px solid red;
    background-image: none;

    & + .error {
      display: inline-block;
      color: red;
      padding: 0;
      margin-top: 3px;
      width: 100%;
      font-size: 12px;
    }
  }
  &:focus {
    color: #495057;
    background-color: #fff;
    border: 0.2rem solid rgba(255, 113, 0, 0.25);
    outline: 0;
    // box-shadow: 0 0 0 0.2rem rgba(255, 113, 0, 0.25);
    // -webkit-box-shadow: 0 0 0 .2rem rgba(255,113,0,.25);
  }

  &:focus:invalid {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
  }
}

.form-group {
  margin-bottom: 0.5rem !important;

  label {
    font-size: 13px;
    font-weight: bold;
    color: var(--dark-color);
    margin-left: 0.25rem;

    .text-underline {
      color: #484848;
      text-decoration: underline;
    }

    &.longText {
      display: inline-block !important;
      margin-left: 10px !important;
      margin-top: -9px;
      line-height: 17px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      vertical-align: middle !important;

      &.m-0 {
        margin: 0px !important;
      }
    }
  }

  .invalid-feedback {
    display: inline-block;
    color: red;
    padding: 0;
    margin-top: 3px;
    width: 100%;
    font-size: 0.75rem;
  }

  .is-invalid {
    border: 1px solid red;
    background-image: none;
  }

  .custom-file-label {
    top: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.img-gallery-list {
      top: 0px;
      margin: 3px 0;
    }
  }
}

.input-container {
  display: flex;
  border: 1px solid #e1e1e1;
  height: 40px;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background: white;

  &.bg-f4f4f4 {
    background: #f4f4f4;

    .form-control {
      background: #f4f4f4;
    }
  }

  .form-control {
    background: white;
    border: 0 !important;
    outline: 0 !important;
    padding: 0 10px;
    box-shadow: none !important;
  }

  .btn {
    width: 120px;
    font-size: 13px;
    font-weight: 500;
    text-transform: capitalize;
    margin-right: 3px;
    @include media-breakpoint-down(xs) {
      width: 100px !important;
      height: 32px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.quantity-box {
  width: auto;
  padding: 7px 8px;
  background-color: #f8f9fa;
  border-radius: 8px;

  .btn {
    background-color: transparent;
  }

  .quantity-input {
    width: 50px;
    background-color: white;
    text-align: center;
    border-radius: 8px !important;
  }
}

.html-editor {
  height: 120px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}
