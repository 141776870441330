.color-484848,
.color-484848:hover {
  color: #484848 !important;
}

.color-4a4a4a,
.color-4a4a4a:hover {
  color: #4a4a4a;
}

.color-545454 {
  color: #545454;
}

.color-202124 {
  color: #202124 !important;
}

.bg-f4f4f4 {
  background: #f4f4f4 !important;
}

.bg-6e87ff {
  background: #6e87ff;
}

.color-919191 {
  color: #919191 !important;
}
.color-ff7c1e {
  color: #ff7c1e;
}
.color-f34854 {
  color: #f34854;
}
.color-f28b00 {
  color: #f28b00;
}
.text-blue {
  color: var(--blue);
}

.color-9b9b9b {
  color: #9b9b9b;
}
.color-9d9d9d {
  color: #9d9d9d;
}
.color-ffffff {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
}

.bg-light-green {
  background: #9fffa6;
}

.bg-light-red {
  background: #ffb4b4;
}

.bg-light-gray {
  background: #dedede;
}
.bg-edffe8 {
  background: #edffe8;
}
.bg-transparent {
  background: transparent;
}
.bg-fff1da {
  background-color: #fff1da;
}
.bg-b91414 {
  background: #b91414;
  color: #ffffff;
}
.color-c45454 {
  color: #c45454;
}

.color-6e87ff {
  color: #6e87ff;
}

.color-015314 {
  color: #015314;
}

.color-e74c3c {
  color: #e74c3c !important;
}

.color-c0cbfc {
  color: #c0cbfc !important;
}

.color-a7a7a7 {
  color: #a7a7a7 !important;
}
.color-6c83ff {
  color: #6c83ff !important;
}
.color-727272 {
  color: #727272 !important;
  text-decoration: underline;
}
