.markalar-filter-button {
  color: #919191 !important;
  font-weight: bold;
}

.markalar-filter-overflow {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.markalar-filter {
  ::-webkit-scrollbar {
    width: 6px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #d8d8d8;
  }
}
.filter-result-title {
  text-transform: capitalize;
}
.filter-result-detail {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #202124;
  span {
    font-weight: 600;
  }
}

.class-list-container {
  margin-bottom: 24px;
  width: 100%;
  .class-list {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    // justify-content: space-between;
    overflow-x: scroll;

    .class-list-item {
      height: 44px;
      background: #fff5f6;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      font-size: 20px;
      margin: 6px 4px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: center;
      color: #bc000d;
      &.true {
        color: #fff5f6;
        background: #bc000d;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      input {
        display: none;
      }
      label {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 0 11px;
        span {
          font-size: 10px;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .class-list {
    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #d8d8d8;
    }
  }
}

@media (max-width: 500px) {
  .filter-result-detail {
    font-size: 14px !important;
  }
}
