.card-book {
  padding: 16px;
  height: 414px;
  border-radius: 8px !important;
  transition: 0.3s linear;

  @include media-breakpoint-down(lg) {
    height: auto;
    min-height: 460px;
  }
  @include media-breakpoint-down(md) {
    min-height: 391px;
  }
  @include media-breakpoint-down(sm) {
    height: auto;
    min-height: 350px;
  }
  @include media-breakpoint-down(xs) {
    height: auto;
    min-height: auto;
    padding: 16px 0;
  }
  &.not-activated {
    .product-image {
      > img {
        opacity: 0.3;
      }
    }
  }
  .ekDokuman {
    position: absolute;
    left: 0%;
    top: 0px;
    height: 125px;
    width: 125px;
    @include media-breakpoint-only(xs) {
      width: 75px;
      height: 75px;
    }
  }
  .card-book-operations {
    padding: 0;
    @include media-breakpoint-down(xs) {
      padding: 0 8px;
      height: 96px;
    }
    .alert {
      padding: 10px 8px;
      @include media-breakpoint-down(sm) {
        padding: 0 8px;
      }
    }

    .badge-score-custom,
    .badge-coin {
      @include media-breakpoint-down(xs) {
        max-width: 132px;
        width: 100% !important;
      }
    }

    // &:hover {
    //   box-shadow: 0 5px 12px 0 rgba(102, 102, 102, 0.5);
    // }
    input.form-control {
      font-size: 14px;
      @include media-breakpoint-down(xs) {
        font-size: 11px;
      }
    }
  }
  .title {
    font-size: 14px;
    text-align: center;
    color: #919191 !important;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include media-breakpoint-down(xs) {
      font-size: 12px;
      font-weight: 500;
    }
  }
  // &:not(.text-overflow-none) .title {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }

  .product-statistics {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .img-wrapper {
    min-height: 302px;
    padding: 8px;
    @include media-breakpoint-down(sm) {
      height: auto;
    }
    @include media-breakpoint-down(xs) {
      min-height: 164px;
    }
    .img-fluid {
      width: 100%;
      height: 100%;
    }
  }
}

#bookModalSlider {
  .slick-slider {
    margin-bottom: 1rem;

    .slick-dots {
      li {
        opacity: 0.35;

        &.slick-active {
          opacity: 0.5;
        }

        button::before {
          font-size: 12px;
        }
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }
}

.books-list.row {
  .col-6 {
    @include media-breakpoint-down(xs) {
      padding: 5px;
    }
  }
}
