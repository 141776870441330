.scrollTopBtn {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 45px;
  font-size: 22px;
  border-radius: 8px;
  background: var(--primary);
  color: white;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  opacity: 0;
  transition: 0.2s;
  z-index: 99999;
  cursor: pointer;
  // @include media-breakpoint-down(xs) {
  //   display: none;
  // }
  svg {
    height: 25px;
    fill: white;
  }
}

.footer-items {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  h6 {
    font-size: 16px;
    font-weight: 600;
    color: #2a2a2a;
  }
  a {
    font-size: 14px;
    font-weight: 500;
    color: #646266;
  }
  .footer-app-download {
    .footer-app-item {
      width: 150px;
      height: 40px;
      margin: 3px 0 13px;
    }
  }

  .btn-link {
    display: flex;
    align-items: center;
    padding: 11px 0;
    .footer-link-item {
      margin: 1px 0 0 5px;
    }
  }
}

//accordion
.footer-accordion {
  padding: 0;
  .accordion-item {
    padding: 10px 0;
    cursor: default;
    list-style: none;
    .accordion-head {
      display: flex;
      align-items: center;
      position: relative;
      h6 {
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        color: #2a2a2a;
        margin: 0;
      }
      .accordion-icon {
        color: #202124;
        position: absolute;
        right: 0;
        transition: 300ms ease-in-out all;
      }
    }
    .accordion-body {
      font-size: 14px;
      font-weight: 500;
      line-height: normal;
      color: #646266;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: 400ms ease-in-out all;
      ul {
        padding: 0;
        .accordion-body-item {
          display: flex;
          align-items: center;
          margin: 12px 0;
        }
        a {
          display: flex;
          align-items: center;
          margin: 12px 0;
          font-size: 14px;
          font-weight: 500;
          color: #646266;
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .show .accordion-body {
    height: auto !important;
    opacity: 1;
  }

  .show .accordion-icon {
    transform: rotate(90deg);
  }
}

h6 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #2a2a2a;
}

.footer-accordion-container {
  border-top: 1px solid #f4f4f4;
  padding: 24px 0 50px 0;
  display: none;
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
  .footer-app-download {
    display: flex;
    .footer-app-item {
      width: 150px;
    }
  }
}
@media (max-width: 767px) {
  .footer-items {
    display: none;
  }
  .footer-app-item {
    margin: 0 8px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  .footer-accordion-container {
    display: block;
  }
}

@media (max-width: 480px) {
  .accordion-icon {
    right: 0 !important;
  }
}
@media screen and (max-width: 480px) {
  .scrollTopBtn {
    bottom: 60px !important;
  }
  .new-design-toggle {
    bottom: 112px !important;
  }
}
