.digitalTestText {
  position: absolute;
  color: rgb(255, 4, 0);
  font-weight: 700;
  font-size: 29pt;
  opacity: 0.2;
  transform: rotate(-45deg);
  top: 54px;
  text-decoration: underline;
}

@media (max-width: 728px) {
  .digitalTestText {
    top: 28px;
    font-size: 13pt;
  }
}
