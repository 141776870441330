.scheduler-container {
  h5 {
    @include media-breakpoint-down(xs) {
      font-size: 20px !important;
    }
  }
  .tab-bar {
    button {
      font-size: 14px !important;
      font-weight: 500 !important;
      @include media-breakpoint-down(xs) {
        font-size: 12px !important;
        font-weight: 600 !important;
      }
    }
  }
  .remaining-time {
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
    .stopwatch {
      font-size: 200px;
      font-weight: 300;
      line-height: 1;
      color: #6e87ff;
      @include media-breakpoint-down(sm) {
        font-size: 104px;
        font-weight: 300;
        line-height: 1.15;
      }
    }
  }
  .button-container {
    display: flex;
    button {
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
  .form-group {
    label {
      font-size: 16px !important;
    }
  }
  .list-group.activity-max2-height {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    .list-group-item {
      @include media-breakpoint-down(xs) {
        padding: 0;
        margin-bottom: 32px;
      }
      img {
        width: 26px;
        height: 26px;
        @include media-breakpoint-down(xs) {
          width: 22px;
          height: 22px;
        }
      }
      span {
        font-size: 12px !important;
      }
      p {
        padding: 0;
        font-size: 14px !important;
      }
      .img-badge.small {
        width: 94px !important;
      }
      .img-badge span {
        min-width: auto;
      }
    }
  }
}

@media (max-width: 375px) {
  .completed-container {
    span {
      font-size: 12px !important;
    }
  }
}

.btn-scheduler {
  width: 100%;
  max-width: 180px;
}
