.shopping-body {
  padding-top: 32px;
  .col-xl-9 {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
  .shopping-cart {
    padding: 0 32px;
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    @include media-breakpoint-down(xs) {
      padding: 0 16px;
    }
    .shopping-info {
      display: flex;
      padding: 24px 0;
      border-bottom: solid 1px #e5e5e5;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
      @include media-breakpoint-down(xs) {
        border: 0;
      }
      h5 {
        font-size: 24px;
        font-weight: 600;
        color: #202124;
        margin-bottom: 0;
        @include media-breakpoint-down(lg) {
          margin-bottom: 16px;
        }
        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
        span {
          font-size: 20px;
          color: #202124 !important;
          @include media-breakpoint-down(xs) {
            font-size: 16px;
          }
        }
      }
      .cargo-text {
        @include media-breakpoint-down(xs) {
          font-size: 12px;
        }
      }
    }

    .shopping-list {
      li {
        padding: 24px 0;
        .shopping-item-container {
          display: flex;
          column-gap: 24px;
          @include media-breakpoint-down(sm) {
            align-items: center;
          }
          figure.image-sm {
            float: left;
            width: 96px;
            height: auto;
            & img {
              width: 100%;
            }
          }
          .btn-light {
            color: #484848;
          }
          .shopping-item {
            @include media-breakpoint-down(sm) {
              flex-direction: column;
            }
            strong {
              font-size: 20px;
              @include media-breakpoint-down(xs) {
                font-size: 16px;
              }
            }
            .shopping-item-name {
              font-size: 16px;
              font-weight: 500;
              color: #202124;
              @include media-breakpoint-down(xs) {
                font-size: 13px;
              }
            }
          }
          .shopping-sub-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 32px;
            width: 100%;
            max-width: 405px;
            @include media-breakpoint-down(md) {
              width: auto;
            }
            @include media-breakpoint-down(sm) {
              flex-direction: column;
              align-items: start;
            }
            .shopping-operations-container {
              display: flex;
              height: 100%;
              width: 100%;
              align-items: center;
              justify-content: flex-end;
              column-gap: 32px;
              @include media-breakpoint-down(sm) {
                margin-top: 16px;
              }
            }
            .shopping-operations {
              font-size: 14px;
              font-weight: 500;
              color: #545454;
              text-decoration: underline;
              cursor: pointer;
            }
            .svg-inline--fa.fa-w-14 {
              height: 14px;
              width: 14px;
            }
          }
        }
      }
    }
  }
  .shopping-total-container {
    position: sticky;
    top: 10px;
    border-radius: 16px;
    border: solid 1px #e0e0e0;
    @include media-breakpoint-down(xs) {
      display: none;
    }
    .card-body {
      h5 {
        font-size: 20px;
        font-weight: bold;
        color: #202124;
        margin-bottom: 24px;
      }
      .total-price {
        font-size: 20px;
        font-weight: 600;
        color: #ff6a00;
        margin-bottom: 24px;
      }
      .total-price-detail {
        border: solid 1px #e5e5e5;
        border-width: 1px 0;
        margin-bottom: 16px;
      }
      .total-price-text {
        font-size: 14px;
        font-weight: 500;
        color: #545454;
        margin-bottom: 16px;
      }
      .btn-total-price {
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        border-radius: 12px;
        box-shadow: 0 0 4px 0 #afafaf;
        background-color: #ff6a00;
      }
    }
  }
  .shopping-login-box {
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    padding: 24px 32px 48px;
    margin: 32px 0;
    h5 {
      font-size: 24px;
      font-weight: bold;
      color: #2a2a2a;
    }
    .shopping-login-text {
      font-size: 16px;
      font-weight: 500;
      color: #202124;
      padding: 32px 0 16px;
    }
    .shopping-login-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ff6a00;
      border-radius: 12px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      width: 212px;
      height: 48px;
      cursor: pointer;
    }
  }
  .shopping-slider {
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    padding: 24px 32px;
    margin: 32px 0;
    @include media-breakpoint-down(md) {
      padding: 24px;
    }
    @include media-breakpoint-down(md) {
      padding: 16px;
    }
    h2 {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: bold;
      color: #2a2a2a;
      @include media-breakpoint-down(xs) {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
    .slick-slider {
      padding: 0 2px;
      .book-detail-container {
        .book-detail-box {
          margin: 0 6px;
          .book-detail-body {
            .book-detail-title {
              font-size: 12px;
              line-height: 1.37;
              height: 32px;
            }
            .book-sub-detail {
              margin-top: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px 0;
              height: 32px;

              @include media-breakpoint-down(xs) {
                border-radius: 8px;
                border: solid 1px #c0c0c0;
              }
              button {
                width: 95px;
                height: 28px;
                border-radius: 8px;
                background-color: #f34854;
                font-size: 12px;
                font-weight: bold;
                color: #fff;
                padding: 0;
                @include media-breakpoint-down(xs) {
                  width: auto;
                  height: auto;
                  color: #545454;
                  border-radius: 0;
                  background: transparent;
                  font-size: 11px;
                  border-left: 1px;
                  padding: 0 8px;
                  text-transform: uppercase;
                }
              }
              span {
                color: #545454;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.shopping-total-mobile-container {
  position: relative;
  .shopping-total-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #ffffff;
    display: flex;
    width: 100%;
    height: 64px;
    padding: 8px 16px;
    z-index: 38;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.5);

    .shopping-mobile-detail {
      span {
        font-size: 12px;
        font-weight: 500;
        color: #545454;
      }
      div {
        font-size: 16px;
        font-weight: bold;
        color: #202124;
      }
      .icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #ecefff;
        margin-left: 6px;
        .svg-inline--fa {
          width: 6px;
          height: 12px;
          transform: rotate(90deg);
          transition: all 0.3s;
        }
      }
    }
    button {
      font-size: 16px;
      font-weight: bold;
      width: 229px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      border-radius: 12px;
      box-shadow: 0 0 4px 0 #afafaf;
      background-color: #ff6a00;
    }
  }

  .shopping-total-mobile.show {
    .shopping-mobile-detail {
      .svg-inline--fa {
        transform: rotate(-90deg);
      }
    }
  }
  .shopping-mobile-sub-detail {
    display: none;
    position: fixed;
    bottom: -100px;
    left: 0;
    background: #fff;
    width: 100vw;
    padding: 16px 16px 0;
    transition: ease 0.3s all;
    z-index: 33;
    &::after {
      content: "";
      position: absolute;
      top: 8px;
      left: 44%;
      width: 55px;
      height: 4px;
      border-radius: 2px;
      background-color: #d8d8d8;
    }
    .shopping-mobile-item {
      display: flex;
      justify-content: space-between;
      margin: 16px 0;
      span,
      div {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
  .shopping-mobile-sub-detail.show {
    bottom: 59px;
    transition: ease 0.3s all;
  }
}
@media (max-width: 576px) {
  .shopping-mobile-sub-detail {
    display: block !important;
  }
}
@media (max-width: 375px) {
  .shopping-total-mobile {
    button {
      width: 60% !important;
      font-size: 14px;
    }
  }
}

.remove-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .modal-box {
    width: 652px;
    height: 453px;
    z-index: 1041;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;
    border-radius: 16px;
    box-shadow: 0 2px 10px 0 rgba(63, 63, 63, 0.5);
    @include media-breakpoint-down(xs) {
      position: fixed;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 374px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 16px;
    }
    span {
      position: absolute;
      top: 8px;
      left: 44%;
      width: 55px;
      height: 4px;
      border-radius: 2px;
      background-color: #d8d8d8;
      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
    .close-btn {
      position: absolute;
      right: 16px;
      top: 16px;
      padding: 0;
      display: block;
      @include media-breakpoint-up(xs) {
        display: none;
      }
    }
    .icon-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f4f4f4;
      border-radius: 50%;
      height: 110px;
      width: 110px;
      margin-bottom: 24px;
      @include media-breakpoint-down(xs) {
        min-height: 76px;
        height: 76px;
        width: 76px;
        margin-bottom: 16px;
      }
      svg {
        width: 46px;
        height: 46px;
      }
      .sub-icon {
        position: absolute;
        right: -20px;
        top: -20px;
        border: 6px solid #f4f4f4;
        border-radius: 50%;
        background: #fff;
        width: 62px;
        height: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(xs) {
          height: 38px;
          width: 38px;
          right: -9px;
          top: -9px;
        }
        svg {
          @include media-breakpoint-down(xs) {
            height: 27px;
            width: 22px;
          }
        }
      }
    }
    h2 {
      color: #202124;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      @include media-breakpoint-down(xs) {
        font-size: 20px;
        margin-bottom: 16px;
      }
    }
    .modal-text {
      font-size: 16px;
      font-weight: 500;
      color: #202124;
      text-align: center;
      margin-bottom: 41px;
      @include media-breakpoint-down(xs) {
        font-size: 14px;
        margin-bottom: 24px;
      }
    }

    .btn-item {
      border-radius: 12px;
      box-shadow: 0 0 2px 0 #afafaf;
      padding: 0 32px;
      color: #202124;
      font-size: 16px;
      font-weight: bold;
      height: 48px;
      @include media-breakpoint-down(xs) {
        margin-right: 6px;
      }
    }
    .btn-remove {
      border-radius: 12px;
      box-shadow: 0 0 4px 0 #afafaf;
      background-color: #ff6a00;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      padding: 11px 20px;
      margin-left: auto;
      height: 48px;
      @include media-breakpoint-down(xs) {
        padding: 0px 12px;
      }
    }
  }
}
