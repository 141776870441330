.loginModal,
.registerModal,
.forgotPasswordModal {
  overflow-x: hidden !important;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  .modal-dialog {
    max-width: 415px;
    @include media-breakpoint-down(xs) {
      max-width: none;
    }
  }
  .modal-header {
    padding: 1rem 0;
    .modal-tab {
      background-color: #f4f4f4;
      height: 56px;
      border-radius: 16px;
      width: 100%;
      display: flex;
      align-items: center;
      .btn {
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        color: #727272;
      }
      .btn.active {
        border-radius: 16px;
        box-shadow: 0 1px 6px 0 #afafaf;
        background-color: #fffefe;
        color: #ff6a00;
        height: 50px;
      }
    }
    span {
      @include media-breakpoint-down(xs) {
        width: 55px;
        height: 4px;
        position: absolute;
        left: 44%;
        top: 8px;
        background: #d8d8d8;
        border-radius: 2px;
      }
    }
  }
}

.modal {
  &.overflow-scroll .modal-body {
    overflow-y: scroll;
    max-height: 400px;
  }

  &.library-store {
    .w-200 {
      width: 200px;
      //margin-right: 50px;
    }
  }

  .modal-body {
    .filter-close {
      position: fixed;
      top: 15px;
      right: 15px;
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }
  }

  .modal-dialog {
    margin: auto;
    border: 0;

    .modal-close-button {
      z-index: 99999 !important;
      background: transparent;
      border: none;
      position: absolute;
      right: 0;
      margin-right: 10px;
      left: auto;
      color: #fff;
      font-size: 40px;
      padding: 0;
      svg path {
        fill: #000000;
      }
    }

    .modal-content {
      height: auto;
      // padding: 48px 48px 38px ;
      border-radius: 12px;
    }
  }
}

.secure3d-modal {
  .modal-body {
    padding: 0 0 1.5rem 0;
  }

  iframe {
    height: 580px;
    border-width: 0;
  }
}

@media (max-width: 568px) {
  .modal {
    .modal-dialog {
      .modal-close-button {
        top: -60px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        right: 0;
      }
    }

    .modal-content {
      padding: 0 !important;
    }

    &.library-store {
      .w-200 {
        width: 200px;
        margin-right: 0;
      }
    }

    // &:not(.non-modal-height) {
    //   .modal-content {
    //     min-height: 100vh;
    //     border-radius: 0;
    //   }
    // }
  }
}

// @media (max-width: 568px) and (max-height:610px) {
// 	.modal {
// 		.modal-dialog {
// 			.modal-close-button {
// 				top: -10px !important;
// 				// left: 50%;
// 				transform: none;
// 				right: 0 !important;
// 				left: auto !important;

// 				svg path {
// 					fill: #000;
// 				}
// 			}
// 		}
// 	}
// }

@media only screen and (max-width: 768px) {
  .modal {
    .modal-dialog {
      .modal-close-button {
        top: 0px;
        left: 50%;
        color: #fff;
        left: auto;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        margin: 0;
        padding: 0;
      }
    }
  }
}

/*small screens landscape*/
@media only screen and (max-width: 568px) {
  .modal {
    &.library-store {
      .modal-dialog {
        .modal-close-button {
          top: -60px;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          right: 0;

          svg path {
            fill: #fff;
          }
        }
      }
    }

    &.contract-modal {
      .modal-dialog {
        .modal-close-button {
          top: 0;
          right: 0;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          -webkit-transform: none;
          transform: none;

          svg path {
            fill: #000;
          }
        }
      }
    }

    .modal-dialog {
      .modal-close-button {
        top: -60px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
      }
    }
  }
}

// @media (max-width: 460px) {
//   .modal {
//     width: 70%;
//   }
// }

//small screens
// @media (max-width: 320px) {
// 	.modal {
// 		.modal-dialog {
// 			.modal-close-button {
// 				top: -10px;
// 				right: 0;
// 				color: #000;
// 				left: auto;
// 				transform: none;
// 				z-index: 99999 !important;

// 				svg path {
// 					fill: #000;
// 				}
// 			}
// 		}
// 	}
// }

.address-modal {
  @include media-breakpoint-down(xs) {
    width: 100% !important;
  }
  .modal-dialog {
    .modal-content {
      height: auto;
      width: 408px;
      box-shadow: 0 2px 10px 0 rgba(63, 63, 63, 0.5);
      border-radius: 16px !important;
      margin: auto;
      overflow-y: auto;
      overflow-x: hidden;

      @include media-breakpoint-down(xs) {
        min-height: auto !important;
        width: 100% !important;
        height: 98vh;
        position: fixed;
        bottom: 0;
        left: 0;
        border-radius: 0 !important;
        border-top-left-radius: 16px !important;
        border-top-right-radius: 16px !important;
      }
      .modal-header {
        @include media-breakpoint-down(xs) {
          padding-bottom: 0;
        }
        span {
          position: absolute;
          top: 8px;
          left: 44%;
          width: 55px;
          height: 4px;
          border-radius: 2px;
          background-color: #d8d8d8;
          @include media-breakpoint-up(sm) {
            display: none;
          }
        }
        .modal-close-button {
          right: 6px;
          top: -6px;
          left: auto;
          @include media-breakpoint-up(sm) {
            display: none;
          }
          svg {
            height: 18px;
            width: 18px;

            path {
              fill: #202124;
            }
          }
        }
      }
    }
  }
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.tats-avatar-modal {
  @include media-breakpoint-down(md) {
    width: auto !important;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
  }

  .modal-dialog {
    height: 100vh;
    min-height: auto;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(xs) {
      width: 295px;
      height: 504px;
    }
    .modal-content {
      height: auto;
      border-radius: 10px;
      width: 672px;
      @include media-breakpoint-down(xs) {
        top: 25%;
      }
      .modal-header {
        border-bottom: 1px solid #dedede !important;
        padding: 16px 0;
        margin: 0 16px;
      }
      .modal-body {
        overflow-y: auto;
        @include media-breakpoint-down(xs) {
          padding-left: 0;
        }
        @include media-breakpoint-down(md) {
          justify-content: space-around;
        }
        .radio-container {
          img {
            width: 136px;
            height: 136px;
            @include media-breakpoint-down(xs) {
              width: 115px;
              height: 115px;
            }
          }
          .radio-checkmark {
            background: transparent;
          }
        }
      }
    }
    .modal-close-button {
      top: -25px;
      right: -60px;
      @include media-breakpoint-down(xs) {
        top: -60px;
        right: 36%;
      }
    }
  }
}

.success-modal {
  @include media-breakpoint-down(md) {
    width: auto;
  }
  .modal-dialog {
    height: 191px;

    @include media-breakpoint-down(xs) {
      margin: 21px;
    }
    .modal-close-button {
      top: -20px;
      right: -60px;
      @include media-breakpoint-down(xs) {
        top: -60px;
        right: 36%;
      }
    }
    .modal-content {
      height: auto;
      border-radius: 10px !important;
      .modal-body {
        height: 100%;
        padding: 24px 24px 36px;
        button {
          @include media-breakpoint-down(xs) {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.information-modal {
  @include media-breakpoint-down(md) {
    width: auto;
  }

  .modal-dialog {
    height: 526px;
    width: 672px;
    max-width: auto;
    @include media-breakpoint-down(sm) {
      width: auto;
      margin: 32px;
    }
    @include media-breakpoint-down(xs) {
      margin: 21px;
    }
    .modal-content {
      height: auto;
      border-radius: 10px !important;
      .modal-header {
        padding: 16px 0;
        margin: 0 24px;
        border-bottom: solid 1px #dedede !important;
        @include media-breakpoint-down(xs) {
          border: 0 !important;
        }
        .modal-title {
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }
      .modal-body {
        height: 100%;
        .slick-slider {
          padding: 0;
          height: 300px;
          .slick-list {
            height: 100%;

            .slick-track {
              height: 100%;
              .slick-slide {
                height: 100%;

                div {
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .modal-footer {
        padding: 16px 0;
        margin: 0 24px;
        button {
          width: 132px;
          @include media-breakpoint-down(xs) {
            width: 100% !important;
          }
        }
      }
    }
  }
}

.complete-modal {
  @include media-breakpoint-down(md) {
    width: auto;
  }
  .modal-dialog {
    height: 191px;
    @include media-breakpoint-down(xs) {
      margin: 0 16px;
    }
    .modal-close-button {
      top: -20px;
      right: -40px;
      @include media-breakpoint-down(xs) {
        top: -60px;
        right: 36%;
      }
    }
    .modal-content {
      height: auto;
      border-radius: 10px !important;
      .modal-body {
        p {
          @include media-breakpoint-down(md) {
            font-weight: 500 !important;
          }
        }
        button {
          @include media-breakpoint-down(xs) {
            width: 100%;
            padding: 8px 0 !important;
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .complete-modal .modal-dialog .modal-close-button {
    right: -50px;
  }
}

@media only screen and (max-width: 575px) {
  .modal .modal-dialog .modal-close-button {
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.registerModal {
  .modal-dialog {
    min-height: 100% !important;
  }
}
.registerModal,
.loginModal,
.forgotPasswordModal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      min-height: 100%;
    }
    .modal-close-button {
      @include media-breakpoint-down(xs) {
        top: 0;
        right: 0;
        left: auto;
      }
      svg {
        @include media-breakpoint-down(xs) {
          width: 24px;
          height: 24px;
        }
      }
    }
    .modal-content {
      height: auto !important;
      padding: 40px 48px 38px !important;
      border-radius: 12px !important;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 36px 24px 30px !important;
      }
      .modal-header {
        border: 0 !important;
        span {
          @include media-breakpoint-down(xs) {
            width: 55px;
            height: 4px;
            position: absolute;
            left: 44%;
            top: 8px;
            background: #d8d8d8;
            border-radius: 2px;
          }
        }
        button {
          figure {
            svg {
              g {
                path {
                  @include media-breakpoint-down(xs) {
                    fill: #202124;
                  }
                }
              }
            }
          }
        }
      }
      .modal-body {
        padding: 0;
      }
    }
  }
}
.mailVerificationModal,
.resetPasswordModal {
  .modal-dialog {
    max-width: 421px;
    @include media-breakpoint-down(xs) {
      min-height: 100%;
    }
    .modal-content {
      height: auto !important;
      border-radius: 12px;
      padding: 48px;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 48px 24px 38px !important;
      }
      .modal-header {
        padding: 0;
        justify-content: start;
        border-bottom: 0 !important;
        display: flex;
        align-items: center;
        span {
          @include media-breakpoint-down(xs) {
            width: 55px;
            height: 4px;
            position: absolute;
            left: 44%;
            top: 8px;
            background: #d8d8d8;
            border-radius: 2px;
          }
        }
        .modal-close-button {
          right: 6px;
          top: -6px;
          left: auto;
          svg {
            height: 24px;
            width: 24px;

            path {
              fill: #202124;
            }
            @include media-breakpoint-up(sm) {
              z-index: 99999 !important;
              background: transparent;
              border: none;
              position: absolute;
              top: -60px;
              right: 0;
              left: auto;
              color: #fff;
              font-size: 40px;
              padding: 0;
              svg path {
                fill: #fff;
              }
              path {
                fill: #fff;
              }
            }
          }
        }
      }
      .modal-body {
        padding: 0;
        margin-top: 32px;
        .btn-submit-form {
          width: 100%;
        }
      }
    }
  }
}

.contract-modal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      min-height: 100%;
    }
    .modal-content {
      height: auto !important;
      border-radius: 12px;
      padding: 24px;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding: 48px 24px 38px !important;
      }
      .modal-header {
        padding: 0;
        justify-content: start;
        border-bottom: 0 !important;
        display: flex;
        align-items: center;
        span {
          @include media-breakpoint-down(xs) {
            width: 55px;
            height: 4px;
            position: absolute;
            left: 44%;
            top: 8px;
            background: #d8d8d8;
            border-radius: 2px;
          }
        }
        .modal-close-button {
          right: 6px;
          top: -6px;
          left: auto;
          svg {
            height: 24px;
            width: 24px;

            path {
              fill: #202124;
            }
          }
        }
      }
      .modal-body {
        padding: 0;
        margin-top: 32px;
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          overflow: hidden;
          background: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 4px;
        }
      }
    }
  }
}
.verificationModal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      min-height: 100%;
    }
    .modal-content {
      height: auto !important;
      border-radius: 12px;
      padding: 24px;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        padding: 8px;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }
}

.galleryModal {
  .modal-dialog {
    .modal-content {
      height: 600px;
      .modal-body {
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          overflow: hidden;
          background: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 4px;
        }
      }
    }
  }
}

.editableSliderModal,
.editableStoryModal {
  .modal-dialog {
    max-width: 720px;
    .modal-header {
      h5 {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #484848;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      .title-container {
        // display: grid;
        // align-items: end;
        // grid-template-columns: 2fr 1fr;
        // gap: 8px;
        .input-wrapper {
          display: flex;
          flex-direction: column;
          label {
            font-size: 16px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.14;
            letter-spacing: normal;
            color: #484848;
          }
          #editableSliderTitle {
            border-radius: 8px;
            background-color: #f4f4f4;
            border: 0;
            height: 40px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #545454;
            padding: 0 16px;
          }
        }
        // button {
        //   border-radius: 8px;
        //   background-color: #6e87ff;
        //   height: 40px;
        //   font-size: 14px;
        //   font-weight: 600;
        //   font-stretch: normal;
        //   font-style: normal;
        //   line-height: normal;
        //   letter-spacing: normal;
        //   color: #fff;
        // }
      }
      .product-list {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        height: 300px;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          overflow: hidden;
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 4px;
        }
        .product-list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 96px;
          border-radius: 16px;
          padding: 0 16px;
          background-color: #f4f4f4;
          .product-content {
            display: flex;
            align-items: center;
            .product-index {
              width: 30px;
              font-size: 24px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 0.76;
              letter-spacing: normal;
              color: #484848;
            }
            .product-img {
              width: 60px;
              margin-right: 16px;
            }
            .product-name {
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.3;
              letter-spacing: normal;
              color: #484848;
              max-width: 340px;
            }
          }
          .product-operations {
          }
        }
      }
      .product-select-container {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 8px;
        align-items: center;
        button {
          height: 48px;
          border-radius: 8px;
          background-color: #6e87ff;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          figure {
            width: 16px;
            height: 16px;
            display: flex;
          }
        }
      }
    }
    .modal-operations {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 24px;
      .btn-cancel {
        border-radius: 12px;
        box-shadow: 0 0 2px 0 #afafaf;
        background-color: #fff;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #202124;
        width: 128px;
        height: 48px;
      }
      .btn-save {
        width: 128px;
        height: 48px;
        border-radius: 8px;
        background-color: #ff6a00;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.89;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
      }
    }
  }
}
