.navbar-brand {
  display: flex;
  width: 166px;
  @include media-breakpoint-down(xs) {
    height: 48px;
    max-width: 132px;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.navbar {
  padding: 24px 0;
  border-bottom: 1px solid #eeeeee;
}

.navbar.topnavbar {
  padding: 0 0;
  height: 48px;
}

.topnavbar .nav-link {
  font-size: 14pt;
  color: #515154;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700;
}

// max width (max-width: 992px)
@media (max-width: 992px) {
  .topnavbar .nav-link {
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
  .topnavbar .nav-link {
    font-size: 9pt;
  }
}

.topnavbar .nav-link.active {
  color: #da0d17;
  text-decoration: underline;
}

.topnavbar .nav-link:hover {
  color: #da0d17;
}

.card-search {
  float: left;
  min-width: 309px;
  width: 100%;
  border: 0;
  position: relative;
  box-shadow: none !important;

  .search-icon {
    position: absolute;
    right: 17px;
    top: 12px;

    path {
      fill: #202124;
    }
  }

  .form-inline {
    @include media-breakpoint-down(sm) {
      // display: none;
    }
    .form-control.search-input {
      border: 0;
      width: 100%;
      height: 48px;
      padding-left: 24px;
      flex: 1;
      background: #f4f4f4;
      border-radius: 16px;
      font-size: 14px;
      font-weight: 500;
      box-shadow: none;
      margin-right: 5px;
    }
  }
  .form-inline.active {
    .search-icon {
      path {
        fill: #f34854;
      }
    }
    .form-control.search-input {
      background-color: #ffffff;
      border-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: 0 2px 10px 0 rgba(63, 63, 63, 0.5);
    }
  }

  .search-result-card {
    display: none;
    position: absolute;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 10px 10px 0 rgba(63, 63, 63, 0.5);
    background-color: #ffffff;
    z-index: 99999999;
    top: 40px;
    padding: 0.25rem 1rem 0.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    max-height: 500px;
    @include media-breakpoint-down(sm) {
      top: 0;
      right: 0;
      border: none;
      border-radius: 0;
      position: fixed;
      height: 100%;
      width: 50%;
      padding: 16px;
      box-shadow: none;
      overflow: auto;
      max-height: none;
    }
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      overflow: hidden;
      background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background: #cccccc;
      border-radius: 4px;
    }
    .close-button {
      position: absolute;
      right: 20px;
      width: 16px;
    }

    .form-control.search-input {
      background: white;
      border-bottom: 1px solid #dedede;
      border-radius: 0;

      &:hover,
      &:active,
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }

    .result-item {
      color: #202124;
      padding: 8px 0;
      text-decoration: none;
      font-size: 14px;
      display: block;
      width: 100%;

      &:hover {
        background: #f4f4f4;
      }
    }
  }
}

.navbar-nav {
  flex-direction: row;
}

.header-links .btn-group {
  padding-top: ($spacer) !important;
  padding-bottom: ($spacer) !important;
}

.svg-inline--fa {
  width: 15px !important;
  height: 22px;
}

.header-links strong {
  font-size: 0.75em;
}

.navbar-light {
  background: #ffffff !important;
}

.work-toggle {
  display: flex;
  min-width: 256px;
  height: 48px;
  border-radius: 16px;
  background: #f4f4f4;
  position: relative;
  align-items: center;
  overflow: hidden;
  padding: 6px;
  z-index: 1;
  .btn {
    flex: 1;
    border-radius: 50px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
    overflow: hidden;
    line-height: 21px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding: 0;

    color: #545454;
  }

  .btn.active {
    background: #ffffff;
    color: #6e87ff;
    width: 134px;
    height: 36px;
    border-radius: 12px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  }
}

.work-toggle-hidden {
  display: flex;
  border-radius: 50px;
  padding: 2px;
  height: 40px;
  width: 220px;
  position: relative;
  align-items: stretch;
  // margin: 6px;
  overflow: hidden;

  .btn {
    flex: 1;
    border-radius: 50px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: normal;
    overflow: hidden;
    line-height: 21px;

    &:focus {
      box-shadow: none;
    }
  }

  .btn.active {
    background: #6e87ff;
    color: white;
  }
}

.header-links {
  svg path:not(.path-white) {
    fill: #484848;
  }

  svg:hover path:not(.path-white) {
    fill: var(--custom-color);
  }
}

.dropdown-menu#classes {
  min-width: 1rem !important;
  width: 260px !important;
}

.card-account-menu {
  overflow: hidden;
  background: white !important;
  display: none;
  top: 70px;
  box-shadow: 0 0 12px 0 rgba(191, 191, 191, 0.5);

  @include media-breakpoint-up(xl) {
    display: block;
  }
  &::before {
    content: "";
    position: absolute;
    left: 35px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    filter: drop-shadow(0px -1px 1px rgba(65, 61, 61, 0.1));
  }

  .list-group {
    .list-group-item {
      padding: 10px 0;
      span {
        font-size: 16px;
        font-weight: 500;
        color: #202124;
      }
    }
  }
}

.shopping-cart {
  .cart-item-heading {
    color: #989898;
    font-size: 12px;
    line-height: 15px;
  }

  .cart-item-price {
    font-size: 13px;
    font-weight: bold;
    color: var(--dark-color);
  }
}

.mobile-search-input-box {
  display: none;
  align-items: center;
  min-height: 40px;
  height: 40px;
  margin-bottom: 24px;
  @include media-breakpoint-down(sm) {
    display: flex;
  }
  .btn-mobile-back {
    background-color: #f4f4f4;
    min-width: 40px;
    height: 100%;
    border-radius: 12px;
    padding: 0;
  }
  .mobile-search-input {
    position: relative;
    width: 100%;
    height: 100%;
    margin-left: 8px;

    input {
      padding-left: 55px;
      height: 100%;
      background: #f4f4f4;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #151515;
      border-radius: 12px;
    }
    .mobile-search-icon {
      position: absolute;
      left: 0;
    }
  }
}

@media (max-width: 1200px) {
  .btn-login {
    min-width: 166px !important;
  }
  .btn-basket {
    margin-left: 0 !important;
  }
}

@media (max-width: 991px) {
  .work-toggle {
    width: 100%;
    margin: 0;
    // margin-top: 10px;
  }

  .navbar-w100 {
    width: 100% !important;
  }
}

.show-item-mobile {
  display: none;
}
.search-backdrop {
  @include media-breakpoint-down(sm) {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    z-index: 30;
    opacity: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
  }
}
.search-backdrop.active {
  @include media-breakpoint-down(sm) {
    opacity: 0.5;
    display: block;
  }
}
@media (max-width: 768px) {
  // .form-control.search-input {
  //   border: 1px solid #fd7e14 !important;
  //   border-radius: 8px !important;
  // }

  .show-item-mobile {
    display: block;
  }

  .close-button {
    right: 25px !important;
  }

  .result-items {
    border-top: 1px solid #dedede;
    border-bottom: 1px solid #dedede;
  }
  .form-inline.active {
    .search-icon {
      path {
        fill: #202124 !important;
      }
    }
  }
}

.navbar-sticky {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999999;
}

.navbar-light .navbar-toggler {
  border: 0;
  padding: 0;
}

.navbar-light .navbar-toggler-icon {
  //background-image: url("../images/icons/hamburger-menu.svg");
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cpath id='a' d='M20.75 14H3.25C2.56 14 2 13.328 2 12.5S2.56 11 3.25 11h17.5c.69 0 1.25.672 1.25 1.5s-.56 1.5-1.25 1.5zM22 8H2V6.5C2 5.672 2.56 5 3.25 5h17.5c.69 0 1.25.672 1.25 1.5V8zm0 9v1.5c0 .828-.56 1.5-1.25 1.5H3.25C2.56 20 2 19.328 2 18.5V17h20z'/%3E%3C/defs%3E%3Cuse fill='%2342252' fill-rule='evenodd' xlink:href='%23a'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 1.1em;
}

.menuMobileList {
  margin: 0;
  width: 100%;

  li {
    padding: 0 !important;
    margin: 4px 0 !important;
    border-bottom: 0;
    border-radius: 16px;
    .menu-dropdown {
      display: none;
      background-color: #ffffff;
      width: 398px;
      max-height: 370px;
      overflow-y: auto;
      position: absolute;
      left: 437px;
      top: 0;
      flex-direction: column;
      padding: 24px 32px;
      &::-webkit-scrollbar {
        width: 6px;
        -webkit-border-radius: 8px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3px;
        border-radius: 3px;
        background-color: #d8d8d8;
      }
      @include media-breakpoint-down(lg) {
        padding: 0;
      }
      h6 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.87px;
        color: #202124;
      }
      ul {
        padding: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 32px;
        row-gap: 24px;
        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column;
          row-gap: 0;
        }
        li {
          margin: 0 !important;
          @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 16px 24px !important;
            border-bottom: 1px solid rgba(183, 65, 0, 0.08);
            border-radius: 0;
          }
          a {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.7px;
            div {
              @include media-breakpoint-up(xl) {
                color: #202124 !important;
              }
            }
          }
          &:last-child {
            border: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
    .menu-dropdown.active {
      display: flex;
    }
    a {
      display: block;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    a,
    span {
      color: var(--dark-color);

      // &:hover {
      //   color: var(--dark-color);
      // }
    }
    &:last-child {
      .menu-dropdown {
        bottom: 0;
        top: auto;
      }
    }
  }
  li.tats-list-item {
    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      gap: 16px;
      span {
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
      img {
        float: none;
        height: 48px;
        width: 48px;
        @include media-breakpoint-down(xs) {
          height: 32px;
          width: 32px;
        }
      }
    }
    &:nth-child(2) {
      background-color: rgba(255, 231, 167, 0.3);
      span {
        color: #cf9902 !important;
      }
    }
    &:nth-child(3) {
      background-color: rgba(197, 255, 247, 0.3);
      span {
        color: #20baa6 !important;
      }
    }
    &:nth-child(4) {
      background-color: rgba(201, 198, 255, 0.3);
      span {
        color: #6c63ff !important;
      }
    }
    &:nth-child(5) {
      background-color: rgba(146, 196, 252, 0.3);
      span {
        color: #0071f0 !important;
      }
    }
    &:nth-child(6) {
      background-color: rgba(255, 217, 197, 0.3);
      span {
        color: #ff6e1f !important;
      }
    }
  }
}

.btn-primary {
  line-height: 1.5 !important;
}

$avatar-size: 28px;

.default-avatar {
  display: inline-flex;
  width: $avatar-size;
  min-width: $avatar-size;
  height: $avatar-size;
  background: #919191;
  border-radius: $avatar-size;
  font-size: 15px;
  text-transform: uppercase;
  color: white;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  // padding: 0 5px;

  &:hover {
    background: #a5a5a5;
    transition: 0.2s;
  }
}

.btn-custom {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 16px;
  background-color: #f4f4f4;
  border-radius: 16px;
  height: 48px;
  position: relative;
  @include media-breakpoint-only(xs) {
    border-radius: 12px;
  }
}

.btn-login {
  min-width: 166px;
}

.btn-category svg.icon {
  padding-right: 10px;
}

.basket-quantity {
  position: absolute;
  top: 0px;
  left: 29px;
  font-size: 16px;
  font-weight: bold;
  color: #f34854;
  @include media-breakpoint-only(xs) {
    font-size: 12px;
    top: 1px;
    left: 17px;
  }
}

.btn-basket {
  min-width: 40px;
  margin-left: 24px;
  @include media-breakpoint-only(xs) {
    width: 24px;
  }
  g {
    fill: #202124;
  }
}
.btn-basket.active {
  .basket-quantity {
    color: #202124;
  }

  g {
    fill: #f34854;
  }
}

.btn-search {
  width: 40px;
  height: 40px;
  margin-right: 16px !important;
}

.nav-border {
  border-bottom: 1px solid rgba(228, 228, 228, 0.5);
  // position: sticky;
  // top: 0;
  // z-index: 38;
}

.btn-profile {
  min-width: 48px;
  height: 48px;
  border-radius: 8px;
  padding: 0;
}

.search-box {
  min-width: 309px;
  width: 100%;
  margin: 0 24px;
}

.menuMobileList {
  .menu-mobile-container {
    display: flex;
    align-items: center;
    border-radius: 16px;
    padding: 16px 24px;
    @include media-breakpoint-down(xs) {
      padding: 8px;
    }
    .menu-mobile-img {
      width: 60px;
      height: 64px;
      display: flex;
      margin-right: 17px;
      @include media-breakpoint-down(xs) {
        width: 32px;
        height: auto;
        margin-right: 8px;
      }
    }
    .menu-mobile-box {
      h6 {
        display: inline;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000c47;
        margin: 0 8px 0 0;
      }
      .category-badge {
        display: inline;
        font-size: 9px;
        font-weight: bold;
        top: -3px;
        position: relative;
        text-transform: uppercase;
        border-radius: 7px;
        border: solid 2px #ffffff;
        background-color: #c6ffef;
        color: #324b44;

        padding: 2px 4px;
      }
      .menu-mobile-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }
    .menu-mobile-arrow {
      position: absolute;
      right: 16px;
      transition: 0.2s;
      @include media-breakpoint-down(xs) {
        width: 16px !important;
        height: 16px !important;
        right: 10px;
      }
      path {
        fill: inherit;
      }
    }
    .menu-mobile-arrow.active {
      @include media-breakpoint-down(lg) {
        transform: rotate(90deg);
      }
    }
  }
  img.logo {
    width: 165px;
  }
}

.menu-user-container {
  display: none;
  margin: 0;
  padding: 0 0 0 10px;
  border-bottom: solid 1px rgba(151, 151, 151, 0.3);
  // &:nth-child(2){
  //   display: block;
  // }
  .menu-user-item {
    display: flex;
    align-items: center;

    padding: 12px 0;
    .work-toggle {
      .btn {
        @include media-breakpoint-down(xs) {
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
    button,
    .menu-operations {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #202124;
      padding: 0;
    }

    .menu-user-img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    .menu-user-box {
      display: flex;
      align-items: center;
      width: 100%;
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
      .btn-profile {
        height: auto;
        margin: 0 16px 0 0;
      }
      div {
        font-size: 16px;
        font-weight: bold;
        color: #202124;
        cursor: pointer;
      }
    }
  }
}
.menu--category {
  display: none;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #202124;
  margin: 24px 0 16px 10px;
}

.history-list {
  .history-list-item {
    padding: 10px 0px;
    .history-list-item-header {
      display: flex;
      margin-bottom: 14px;
      .history-list-item-title {
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #151515;
      }
      .remove-items-button {
        margin-left: auto;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #6e87ff;
        cursor: pointer;
      }
    }
    .history-list-item-body {
      .last-search-book {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        row-gap: 5px;
        .last-search-book-item {
          list-style: none;

          img {
            min-width: 48px;
            height: 58px;
          }
        }
      }
      .last-search-result {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        .last-search-result-item {
          list-style: none;
          padding: 12px 0;
          &:first-child {
            padding: 0;
          }
          &:last-child {
            padding: 0;
          }
          a {
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #202124;
          }
        }
      }
    }
  }
}

/*media query*/
@media (max-width: 1199px) {
  .menu--category {
    display: block;
  }
  .menu-dropdown {
    position: static !important;
    width: 100% !important;
    height: auto !important;
    h6 {
      display: none;
    }
  }
  .menu-user-container {
    display: block !important;
  }
  .menuMobileList {
    li:first-child {
      // display: none;
    }
  }
}
@media (max-width: 768px) {
  .product-group {
    .product-card:hover {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0) !important;
    }
  }

  .product-card {
    .product-operations {
      .add-to-cart:hover {
        background: transparent !important;
      }
      .add-to-notify-me:hover {
        background: transparent !important;
      }
    }
  }
  .search-box,
  .card-search {
    margin: 0;
    margin-left: auto;

    width: auto;
    min-width: auto;
  }
  // .form-inline,
  // .form-inline.active {
  //   display: none;
  // }
  .last-search-result-item {
    a {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #202124 !important;
    }
  }
}

@media (max-width: 600px) {
  .menuMobile {
    width: 78% !important;
    padding: 0 12px 21px;
    .closeMenuMobile {
      right: 0px;
      top: 0px;
    }
    .menu-user-container {
      padding: 0;
      .btn-profile {
        width: 32px !important;
        height: auto !important;
        min-width: auto;
      }
      .work-toggle {
        min-width: auto;
      }
    }
    .menuMobileList {
      padding: 0;
      .menu-mobile-container {
        // padding: 0 24px;
        .menu-mobile-box {
          h6 {
            margin: 0;
          }
          .menu-mobile-text {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .login-box-mobile {
    width: 100% !important;
    font-size: 14px !important;
  }
  .btn-custom {
    width: 40px;
    height: 40px;
    padding: 0 !important;
  }

  header.navbar.navbar-expand-lg {
    padding: 16px 0 !important;
  }
}

.login-box {
  display: none;
  @include media-breakpoint-up(xl) {
    display: flex;
  }
  span {
    cursor: pointer;
  }
  .line {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      height: 22px;
      background-color: #d8d8d8;
      top: -11px;
      left: 0;
      border-radius: 1px;
    }
  }
}
.login-box-mobile {
  display: flex;
}

@media (max-width: 375px) {
  .menu-user-box {
    div {
      width: 130px;
    }
  }
}
@media (max-width: 575px) {
  .btn-basket.active .basket-quantity {
    color: #202124;
    background: #df0b17;
    border-radius: 50%;
    color: #fff;
    padding: 3px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Navbarda mobilde logo düzenlendi  */

  .navbar .navbar-brand {
    height: auto !important;
  }
}
