.head-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &.head-banner--mobile {
    margin-bottom: 24px;
    border-radius: 12px;
    img {
      border-radius: 12px;
    }
  }
  .editable-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #dedede;
    background-color: #fff;
    figure {
      pointer-events: none;
    }
  }
}
