// Override default variables before the import
$body-bg: #fff;

$theme-colors: (
  "primary": #ff7100,
  "danger": #ff4136,
  "custom-color": #580a6b,
  "dark": #484848,
  "blue": #6e87ff,
  "success": #5ad363,
);

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

//Import Custom Styles

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "../images/icons/icomoon/style.css";

@import "./navbar.scss";
@import "./footer.scss";
@import "./flex.scss";
@import "./color.scss";
@import "./utility.scss";
@import "./img-badge.scss";
@import "./breadcrumbs.scss";
@import "./product-card.scss";
@import "./toast.scss";
@import "./desk.scss";
@import "./tree-chart.scss";
@import "./progressbar.scss";
@import "./tree-table.scss";
@import "./table.scss";
@import "./plyr.scss";
@import "./head-banner.scss";
@import "./basket-nav.scss";

//Forms

@import "./forms/input.scss";
@import "./forms/checkbox.scss";
@import "./forms/radio.scss";

//Pages

/* Store */
@import "./pages/store/main.page.scss";
@import "./pages/store/account.page.scss";
@import "./pages/store/orders.page.scss";
@import "./pages/store/shopping.cart.page.scss";
@import "./pages/store/product-show.page.scss";
@import "./pages/store/brand.page.scss";
@import "./pages/store/support.page.scss";
@import "./pages/store/payment.page.scss";
@import "./pages/store/board.page.scss";
@import "./pages/store/stores.page.scss";
@import "./pages/store/settings.page.scss";
@import "./pages/store/products.page.scss";

/* Desk */

@import "./pages/desk/index.page.scss";
@import "./pages/desk/book.page.scss";
@import "./pages/desk/stats.page.scss";
@import "./pages/desk/scheduler.page.scss";

//Modals

@import "./modals/modals.scss";

a:hover {
  text-decoration: none;
}

.bg-light {
  background: #ffffff !important;
}

.bg-gray {
  background: #f6f6f6;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
    padding: 0;
  }
}

.contract-product-table td {
  padding: 15px;
}

.default-dropdown-menu {
  border: none !important;
  box-shadow: -2px 0 16px rgba(183, 183, 183, 0.5);
  border-radius: 8px;
  opacity: 1;
}

.dropdown-menu {
  min-width: 1rem;
  width: 240px;
  border: none !important;
  box-shadow: -2px 0 16px rgba(183, 183, 183, 0.5);
  border-radius: 8px;
  opacity: 1;

  .btn-link {
    text-transform: capitalize;
    font-weight: 500;
    float: left;
    width: 50%;
    margin: 0;
    color: var(--dark-color);
    text-align: left;

    &:hover {
      color: var(--dark-color) !important;
    }
  }
}

.card-account-menu {
  width: 223px;
  opacity: 1 !important;
  padding: 10px;
  overflow: inherit;

  .list-group-item:last-child {
    border-bottom: 0 !important;
  }
}

.list-group {
  &.full-w {
    width: 100%;
  }
}

.percent {
  user-select: none;
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  text-align: center;
  padding-left: 1px;
  line-height: 18px;
  border: 1.5px solid var(--blue);
  color: var(--blue);
  font-weight: 500;
}

.fill-custom-color {
  path {
    fill: red;
  }
}

.bt-none {
  border-top: none;
}

.bb-none {
  border-bottom: none;
}

.bl-none {
  border-left: none;
}

.br-none {
  border-right: none;
}

.dropdown-menu-list {
  .list-group-item {
    border: none;

    // svg path {
    //   fill: #484848;
    // }
  }
}

.tooltip.show {
  opacity: 1;
}

.card-register {
  border-radius: 8px;
  border: 0;
  box-shadow: -2px 0 16px rgba(183, 183, 183, 0.5);
}

button,
button:focus {
  outline: 0;
}

.card {
  border: 0;
  border-radius: 16px;
  box-shadow: 0 0 12px 0 rgba(191, 191, 191, 0.5);

  .card-header {
    border-radius: 16px;
  }

  .card-footer {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
}

.card-list-group {
  .list-group-item {
    border: 0;
    border-bottom: 1px solid #dedede;
    border-radius: 0;
    background: transparent;

    &:last-child {
      border-bottom: 0 !important;
    }

    a {
      color: var(--blue) !important;
    }
  }
}

.stepNumber {
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 32px;
  background: #f4f4f4;
  color: var(--dark-color);
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.btn-search-store {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #919191;
  text-transform: none;
  width: 100%;
}

.tooltip-store {
  .card {
    display: none;
  }
}

/* .tooltip-container {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  left: 18%;
} */

.stores-list {
  .list-item {
    position: relative;

    &:hover::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: 0;
      left: 40%;
      box-sizing: border-box;

      border: 0.5em solid rgba($color: #000000, $alpha: 0.05);
      border-color: transparent transparent white white;

      transform-origin: 0 0;
      transform: rotate(-135deg);

      box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
      z-index: 99;
    }
  }

  li {
    width: 50%;
    float: right;
    padding: 5px 0;
    border: none !important;

    .store-title {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      color: #919191;
      margin-bottom: 0;
    }

    .rate-text {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .stores-reservation-button {
      float: right;
      height: 24px;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: #ffffff;
      line-height: 0 !important;
      text-transform: capitalize;
    }
  }
}

@media (max-width: 992px) {
  .stores-list {
    .list-item {
      &:hover::after {
        content: none;
        display: none;
      }
    }
  }
}

.card-close-btn {
  width: 24px;
  height: 24px;
  padding: 0 !important;

  right: 0.5rem;
  top: 0.5rem;
  border-radius: 32px;
  background-color: white;

  img {
    width: 24px;
    height: 24px;
  }
}

.toggle-select {
  display: inline-flex;
  padding: 3px;
  background: #f1f3f4;
  border-radius: 8px;

  .toggle-select-item {
    font-size: 12px;
    font-weight: 600;
    padding: 11px 16px;
    color: #919191;
    border-radius: 8px;
    margin-left: 5px;
    transition: 0.1s;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: #202124;
      background: white;
      transition: 0.1s;
    }

    &.active {
      color: #202124;
      background: white;
    }
  }
}

textarea {
  resize: none;
}

.accordion {
  .card {
    box-shadow: none;
    border-bottom: 1px solid #dedede;
    background: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .card-header {
      padding-bottom: 5px;

      button {
        font-size: 15px;
        font-weight: 500;
        color: #484848;
        box-shadow: none;
        display: block;
        text-decoration: none !important;
        text-align: left;

        &::before {
          content: "";
          margin-right: 10px;
          display: inline-block;
          width: 12px;
          height: 12px;
          background: url("../images/icons/icon-minus-black.svg");
        }
      }
    }

    .collapse .card-body {
      font-size: 14px;
      padding: 10px;
      padding-left: 55px;
      color: #484848;
    }
  }

  .card .collapsed::before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url("../images/icons/icon-plus-black.svg") !important;
  }
}

.alert {
  padding: 14px 18px;
  font-size: 12px;

  &-success {
    background: #ecffe0;
    border: 1px solid #59a022;
    color: #59a022;

    a {
      color: #59a022;
      font-weight: 600;
    }
  }
}

@media (min-width: 568px) {
  .modal-container .comment-list {
    max-height: 410px;
    overflow: auto;
  }
}

.comment-item {
  margin: 12px 0;
  border-bottom: 1px solid #f4f4f4;
  &:last-child {
    margin-bottom: 0px;
  }
  .comment-avatar {
    width: 24px;
    height: 24px;
  }

  .comment-user-name {
    font-size: 16px;
    font-weight: 600;
    color: #202124;
  }

  .time {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: #545454;
  }

  .comment {
    font-size: 16px;
    font-weight: 500;
    color: #202124;
    margin-bottom: 8px;
  }
}

.btn-show-more {
  &:hover {
    color: #6e87ff;
  }

  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #6e87ff;
  margin: auto;
}

.rating-component {
  margin-bottom: 12px;
  display: inline-block;
  &:hover {
    text-decoration: none;
  }

  svg {
    width: 13px;
    height: 13px;
    margin-right: 4px;
    color: #ffb500;
  }

  .rate-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
    color: #545454;
    text-decoration: underline;
  }
}
@media (max-width: 480px) {
  .rating-component {
    svg {
      width: 13px !important;
      height: 13px;
    }
  }
}
@media (max-width: 575px) {
  .d-xs-none {
    display: none !important;
  }
}

// @media (max-width: 992px) {
//   .mt-lg-55 {
//     margin-top: 55px;
//   }
// }

.btn-outline-success {
  &:hover,
  &:active {
    color: white !important;
  }
}

.btn.btn-default {
  background: #f4f4f4;
  border-radius: 8px;
  padding: 8px 12px;
  border: 0;
}

.b-dedede {
  border: 1px solid #dedede;
}

.by-dedede {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.br-dedede {
  border-right: 1px solid #dedede;
}

.info-card {
  background: #edeeff;
  border-radius: 12px;
  box-shadow: none;
  margin-top: 24px;
  .card-body {
    padding: 16px;
  }
}

.b-919191 {
  border: 1px solid #919191;
}

.modal-tab-bar {
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(222, 222, 222, 0.6);

  .btn {
    flex: 1;
    padding: 15px 0;
    border-radius: 0;
    font-size: 16px;
    font-weight: bold;
    color: #202124;
    box-shadow: none;
    border: 4px solid transparent;
    &.active {
      border-bottom: 4px solid #6e87ff;
      color: #6e87ff;
    }
  }
}

.apexchart-value-bold {
  .apexcharts-datalabel-value {
    font-weight: 600;
  }
}

.max-height-250 {
  max-height: 250px;
}

.progress {
  border-radius: 50px;
  background: #eee;
  height: 24px;

  .progress-bar {
    background: var(--blue);
    font-weight: 500;
    font-size: 14px;
  }
}

.br-c6c6c6 {
  border-right: 1px solid #c6c6c6;
}

// @media (max-width: 768px) {
//   .br-md-none {
//     border-right: none;
//   }

//   .account-menu .list-group-item {
//     &:last-child {
//       border-bottom: 0 !important;
//     }

//     padding: 19px 21px 21px 21px;
//     border-bottom: 0.5px solid #919191 !important;
//   }
// }

.badge:not(.badge-pill) {
  font-size: 14px;
  border-radius: 16px;
  padding: 8px 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: #dedede;
  color: #484848;

  &.badge-success {
    background: #cdffd1;
    color: #3cd347;
  }

  &.badge-danger {
    background: #ffcbcb;
    color: #b20202;
  }
}

.gallery-list {
  .badge-danger {
    background: #ffcbcb;
    color: #ff4a4a;
    border-radius: 50px;
    padding: 10px;
    min-width: 12px;
  }
}

.border-y-919191 {
  border-top: 1px solid #919191;
  border-bottom: 1px solid #919191;
}

.tab-bar {
  display: inline-flex;
  align-items: center;
  padding: 5px;
  background: #f4f4f4;
  border-radius: 50px;
  width: auto;

  &.nowrap {
    .tab-header {
      display: flex;
      white-space: normal;

      .btn {
        border-radius: 50px;
      }
    }
  }

  .tab-header {
    width: 100%;
    overflow: auto;
    white-space: nowrap;

    .btn {
      display: inline-flex;
      border-radius: 16px;
      text-transform: capitalize;
      font-size: 14px;
      margin-right: 5px;
      color: #484848;
      background: transparent;
      font-weight: 500;
      box-shadow: none;

      &.active {
        background: var(--blue);
        color: white;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .tab-content {
    margin-top: 10px;
    width: 100%;
    position: relative;
    cursor: auto;
  }
}

@media (max-width: 568px) {
  .tab-bar {
    width: 100%;
  }

  .width-sm-100 {
    width: 100%;
  }
}

$btn-size: 30px;

.zoom-group {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
  padding: 3px 4px;
  background: #f4f4f4;
  border-radius: 50px;
  color: #9b9b9b;
  font-size: $btn-size - 16px;
  font-weight: 600;
  @include media-breakpoint-down(xs) {
    width: 113px;
  }
  .btn {
    width: $btn-size;
    height: $btn-size;
    border-radius: $btn-size;
    background: #d8d8d8;
    color: #484848;
    padding: 0;
    text-align: center;
    line-height: 1;
    @include media-breakpoint-down(xs) {
      width: 20px;
      height: 20px;
    }
    svg {
      width: 17px;
      height: 17px;
      @include media-breakpoint-down(xs) {
        width: 12px;
      }
    }
    &:hover {
      background: #c9c9c9;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05);
    }
  }
}

.circle-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #ddd;
  color: white;
  text-align: center;
  line-height: 23px;
  font-size: 14px;
}

.accordion-container {
  padding: 0px 26px;
  height: 100%;

  .btn::before {
    display: none !important;
  }

  ul {
    list-style: none;
  }

  .btn {
    display: inline-block;
    font-size: 15px;
  }

  li {
    font-size: 14px;
    padding-right: 10px;
  }

  .btn,
  li,
  span {
    font-family: "Montserrat", sans-serif;
    font-weight: normal;
    color: #919191;
    border: 0 !important;
    text-transform: capitalize !important;

    &:hover {
      text-decoration: none;
    }
  }

  .li-selected {
    font-weight: bold !important;
  }

  .card {
    padding: 16px 0 !important;
    border-bottom: 1px solid #797979 !important;

    &:last-child {
      border-bottom: 0 !important;
    }

    .card-header {
      padding: 0 !important;
    }
  }
}

.chapter-test-item {
  border-radius: 16px;
  margin-bottom: 24px;

  .card-body {
    padding: 21px;
    @include media-breakpoint-down(xs) {
      padding: 8px;
    }
    h5.card-title {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
        font-weight: bold !important;
      }
    }
  }
  .book_image {
    position: absolute;
    top: 18px;
    left: 20px;
    width: 78.6px;
    height: 105px;
    @include media-breakpoint-down(xs) {
      top: 70px;
      left: 0;
      width: 40px;
      height: 53px;
    }
  }

  p,
  h5 {
    font-family: "Montserrat", sans-serif;
    color: #484848 !important;
    font-weight: 600 !important;
    font-size: 16px;
  }

  font-size: 14px;

  .chapter-test-image {
    height: 105px;
    border-radius: 16px;
  }

  .card-body .card-title {
    font-size: 15px !important;
  }

  .icon {
    width: 44px;
    height: 44px;
    @include media-breakpoint-down(xs) {
      width: 29px;
      height: 29px;
    }
  }

  .page-range {
    color: #9b9b9b;
  }
}

.chapter-checkbox {
  font-family: "Montserrat", sans-serif;
  color: #919191;
}

.chapter-list-header {
  margin-bottom: 1rem;
  h1 {
    @include media-breakpoint-down(xs) {
      font-size: 18px !important;
      font-weight: bold !important;
    }
  }
  label {
    h5 {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
      }
    }
  }
}

.chapter-acordion {
  height: 96%;
}

@media (max-width: 768px) {
  .chapter-list-header {
    flex-direction: column;
    align-items: start !important;
    padding-left: 5px;
    margin-bottom: 0;
  }

  .chapter-checkbox {
    margin-bottom: 20px !important;
    margin-left: 0 !important;
  }

  .chapter-acordion {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .chapter-checkbox {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {
  .chapter-checkbox {
    display: inline-block;
    float: right;
  }

  .chapter-test-item {
    margin: 0 -2.5px 24px;
  }
}

.product-detail-slide-mobile {
  display: flex;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.product-detail-slide {
  margin-top: 40px;
  padding: 0;
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  // @include media-breakpoint-down(sm) {
  //   margin-bottom: 24px;
  // }

  .slick-active {
    .slide-paging {
      background: #6e87ff;
    }
  }

  .slide-paging {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: #d8d8d8;
    border-radius: 12px;
  }

  .slick-slide {
    padding: 0 12px;
    box-sizing: border-box;
    @include media-breakpoint-down(lg) {
      padding: 0;
    }

    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}

#custom-image-gallery-item {
  width: 207px !important;
  object-fit: contain !important;
}

.nowrap {
  white-space: nowrap;
}

.badge-warning {
  background: #ffe18e !important;
  color: #b6880a !important;
}
.badge-pink {
  background: #fef3f1 !important;
  color: #aa1500 !important;
  width: 89px;
}

.btn-outline-dark {
  border-color: #484848 !important;
  color: #484848;

  &:hover {
    background: #484848;
    color: #ffffff;
  }
}

.cookie-policy {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font: {
      size: 14px;
    }

    .modal-close-button {
      background: transparent;
      border: none;
      padding: 0;

      path {
        fill: #484848 !important;
      }
    }
  }
}

.modal-mobile-white-btn-close {
  margin-top: 50px;
}

.numeric-input {
  input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.reservation-list {
  .list-group-item {
    padding: 0.75rem 0 !important;
  }
}

.min-w-100 {
  min-width: 110px;
}

.note {
  width: 100%;
  display: flex;
  padding: 15px 20px;
  margin-top: 1rem;

  &.linked {
    background-color: #fff9e8;
    border: 1px solid #ffda77;

    .note-content-head {
      display: flex;
      justify-content: space-between;

      img {
        display: block;
        width: 100px;
        min-width: 100px;
        height: 22.5px;
      }
    }

    .note-content {
      font: {
        size: 12px;
      }

      h6 {
        color: #d6a011;
        font-weight: 400;
      }

      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  &.new {
    background-color: #f8f8f8;
    border: solid 1px #dadada;
  }

  &.security {
    display: block;
    background-color: #f8f8f8;
    border: solid 1px #dadada;
    margin-top: 0;
    margin-bottom: 20px;

    font: {
      size: 12px;
    }

    img {
      display: block;
      width: 120px;
      margin-left: 20px;
    }

    > div {
      display: flex;
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.modal-header-mp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    display: block;
    width: 120px;
    min-width: 120px;
  }
}

.otp-modal {
  &.invalid-otp {
    label[for="validationCode"]:after {
      display: inline-block;
      content: "Hatalı giriş yaptınız.";

      font: {
        size: 12px;
        weight: normal;
      }

      color: #ff0000;
      margin-left: 10px;
    }
  }

  h5 {
    margin-bottom: 0;
  }

  form {
    .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row + .form-row {
      display: none;
    }
  }

  .form-actions {
    button {
      max-width: 150px;
      margin-top: 0;
    }
  }
}

.registration-modal {
  form {
    .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row + .form-row + .form-row {
      display: none;
    }

    .form-row + .form-row + .form-row + .form-row + .form-row + .form-row {
      display: none;
    }
  }
}

.card-list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    label {
      margin-bottom: 0;
      max-width: 150px;
    }

    .mp-bin {
      margin-right: 0.5em;
      font-family: monospace;
      font-size: 15px;
    }
  }
}

// .mp-form-save {
//   margin-top: -.5em !important;
// }

.mp-label {
  display: flex !important;
  align-items: center;
  clear: none;

  img {
    display: block;
    width: 100px;
    min-width: 100px;
    height: 22.5px;
  }

  > span {
    display: block !important;
    font-size: 11px;
    line-height: 1.6em;

    span {
      text-decoration: underline;
    }
  }
}

.form-group.form-check label {
  &.mp-label {
    &:before,
    &:after {
      top: 50%;
      margin-top: -0.9em;
    }
  }
}

.mpn {
  font-size: 11px;
}

.mp-list-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //margin: 2rem 0 .5rem 0;
  margin: 0 0 0.5rem 0;

  img {
    display: block;
    width: 100px;
    min-width: 100px;
    height: 22.5px;
  }

  h2 {
    font-size: 18px;
    margin: 0;
  }
}

.showMPList,
.hideMPList {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
  margin: 20px 0;

  font: {
    size: 14px;
    weight: 700;
  }
}

.tag-cloud {
  a {
    display: inline-block;
    margin: 0px 3px 3px 0;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #000000;
    font-size: 0.7rem;
    border-radius: 3px;
    &:hover {
      color: #6e87ff;
    }
  }
}

.btn-submit-form {
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #afafaf;
  background-color: #ff6a00;
  width: 360px;
  height: 48px;
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  &:hover {
    color: #ffffff;
  }
  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.chapter-branch {
  h1 {
    @include media-breakpoint-down(xs) {
      font-size: 20px !important;
      font-weight: bold !important;
    }
  }
}
.ilgiliUrunler .product-group {
  grid-template-columns: repeat(4, 1fr);
}

.ilgiliUrunler .product-group .product-card figure img {
  width: auto;
  max-width: 256px;
}

@media screen and (max-width: 1200px) {
  .ilgiliUrunler .product-group {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 991px) {
  .ilgiliUrunler .product-group {
    grid-template-columns: repeat(2, 1fr);
  }

  .ilgiliUrunler .product-group .product-card figure img {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .ilgiliUrunler .product-group .product-card figure img {
    width: 100%;
    height: 100%;
  }
}
