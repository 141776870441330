.tree-chart-img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  user-select: none;
  @include media-breakpoint-down(xs) {
    width: 130px;
    height: 130px;
  }
}

.tree-chart-img-narrow {
  width: 75px !important;
  height: 75px !important;
}

.tree-chart-badge {
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  background: white;
  color: #484848;
  box-shadow: 0 0 12px 0 #dedede;
  text-align: center;
  line-height: 40px;
  border-radius: 50px;

  top: 0;
  left: 0;

  > .text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.solarized {
    color: #919191;
  }

  transform: translate(-50%);
}

.tree-chart-badge-narrow {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  font-size: 11px !important;
}

/** 
  30
**/

.s-30--0 {
  top: -10px;
  left: 50%;
}

/**
  60
**/

.s-60--0 {
  top: -10px;
  left: 50%;
}

.s-60--30 {
  top: auto;
  bottom: -10px;
  left: 50%;
}

/** 
  90
**/

.s-90--0 {
  top: -10px;
  left: 50%;
}

.s-90--30 {
  top: 59%;
  right: 0;
  left: auto;
}

.s-90--60 {
  top: 59%;
  left: 38px;
}

/**
  120
**/

.s-120--0 {
  top: -10px;
  left: 50%;
}

.s-120--30 {
  top: 44%;
  right: -7px;
  left: auto;
}

.s-120--60 {
  left: 50%;
  bottom: -10px;
  top: auto;
}

.s-120--90 {
  left: 30px;
  top: 44%;
}

/**
  150
**/

.s-150--0 {
  top: -10px;
  left: 50%;
}

.s-150--30 {
  top: 30%;
  left: auto;
  right: 0;
}

.s-150--60 {
  bottom: 15%;
  right: 15px;
  top: auto;
  left: auto;
}

.s-150--90 {
  bottom: 15%;
  left: 55px;
  top: auto;
}

.s-150--120 {
  top: 30%;
  left: 30px;
}

/**
  180
**/

.s-180--0 {
  top: -10px;
  left: 50%;
}

.s-180--30 {
  top: 27%;
  left: auto;
  right: 0;
}

.s-180--60 {
  top: 62%;
  right: 6px;
  left: auto;
}

.s-180--90 {
  top: auto;
  bottom: -10px;
  left: 50%;
}

.s-180--120 {
  top: 63%;
  left: 42px;
}

.s-180--150 {
  top: 27%;
  left: 35px;
}
