.brand-list {
  .breadcrumb {
    padding-left: 0;
  }
}

.brand-crumbs {
  height: 192px;
  border-radius: 16px;
  box-shadow: 0 0 12px 0 rgba(191, 191, 191, 0.5);
  background-image: url(../../../images/markalar_banner.png);
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin: 20px 0;
  background-size: cover;
  background-position: center;

  .card-body {
    margin-left: 25px;
  }

  .card-text {
    width: 100%;
    font-size: 14px;
  }
}

.card-brand a {
  color: black;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #b34f00;
  }

  .card-body {
    .card-text {
      font-size: 14px;
    }
  }
}

.card-brand-small a {
  color: #484848;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #b34f00;
  }

  img.logo {
    width: 120px;
    height: 120px;
    position: relative;
  }
}

.brand-show-checkbox {
  .btn-link {
    padding-left: 0;
    text-transform: capitalize;
    font-size: 14px;
    color: #919191;
  }

  .badge {
    font-size: 10px;
  }
}

.brand-show-logo-3 {
  position: absolute;
  bottom: 0;
  width: 210px;
  left: 12%;
}

.brand-show-mark-logo {
  position: relative;
  top: -50px;
}

.brand-show-filter-select {
  width: 25%;

  .css-bg1rzq-control {
    background: #f4f4f4;
    border-color: #f4f4f4;
  }
}

.brand-show-filter-item-btn {
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 40px;
  background: white;
  border: solid 1px #e5e5e5;
  color: #202124;
  padding: 12px;
  text-align: center;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: 0.2px;
  margin: 4px;
}

.close-filter {
  padding: 0;
  background: #f4f4f4;
  margin-left: 10px;
  border: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  figure {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.radius-20 {
  border-radius: 20px;
}

.brand-checkbox {
  &.form-group.form-check label:before {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: solid 2px #757575;
  }

  &.form-group.form-check input[type="checkbox"]:checked + label:after {
    width: 1em;
    height: 0.5em;
  }

  .checkbox-label {
    font-size: 14px;
    font-weight: 500;
    color: #202124;

    .rating {
      display: flex;
      margin-right: 8px;
    }
  }
}

.brand-show-layer-3 {
  border-radius: 20px;
  width: 100%;
  object-fit: cover;
  object-position: bottom;
}

.filter-modal {
  z-index: 999999 !important;
  left: auto;

  .modal-dialog {
    max-width: 100%;
    height: 100%;
    @include media-breakpoint-down(sm) {
      width: 50%;
      position: absolute;
      right: 0;
    }
    @include media-breakpoint-down(xs) {
      width: 70%;
      position: absolute;
      right: 0;
    }
    .modal-content {
      height: 100% !important;
      border-bottom-right-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .modal-body {
    overflow: auto;
    padding: 0;
  }
}

.brand-show-mobile-filter {
  display: none;
  border-radius: 16px;
  background-color: #f4f4f4;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #202124;
    .filter-icon-box {
      position: relative;
      .red-circle {
        position: absolute;
        display: none;
        right: 6px;
        top: -4px;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background: #f34854;
        border: 2px solid #f4f4f4;
      }
      .red-circle.active {
        display: block;
      }
    }
  }
  .btn-border-right::after {
    content: "";
    border: solid 1px #d5d5d5;
    height: 24px;
    position: absolute;
    right: 0px;
  }
}

.banner1 {
  padding: 1rem 3rem;

  .card-title {
    margin-top: 2rem;
  }

  .card-text {
    margin: 2rem 0;
    max-width: 600px;
  }
}

.banner2 {
  padding: 3rem;

  .card-text {
    margin: 2rem 0;
    max-width: 600px;
  }
}

.banner3 {
  padding: 3rem;
  height: 320px;

  .card-text {
    margin: 2rem 0;
    max-width: 600px;
  }
}

.banner4 {
  padding: 2rem 3rem;
  height: 320px;
}

.advertorial-img {
  width: 100%;
}

.brand-banner {
  overflow: hidden;

  img.card-bg {
    object-fit: cover;
    filter: grayscale(0.3);
  }
}

/* Media */
@media (max-width: 767px) {
  .banner3,
  .banner2,
  .banner1 {
    padding: 1rem;
  }

  .banner4,
  .banner3,
  .banner2,
  .banner1 {
    height: auto;

    .card-title {
      font-size: 24px;
      margin-top: 1rem;
    }
  }

  .banner4 {
    padding: 1rem;
  }
}

.sortable-item {
  z-index: 1100;
}

@media (max-width: 768px) {
  .brand-show-filter {
    display: none;
  }

  .brand-show-mobile-filter {
    display: flex;
  }

  .brand-show-list {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .brand-show-filter-select {
    display: none;
  }

  .brand-show-logo-3 {
    width: 130px;
  }

  .brand-show-mark-logo {
    width: 90px;
  }

  .brand-show-top-box {
    padding-left: 30px;
  }

  .xs-mt-30 {
    margin-top: 30px;
  }

  .banner4 {
    padding: 1rem;
  }
}

@media (max-width: 540px) {
  .card-brand-small {
    .btn-link {
      top: 0 !important;
      right: 0 !important;
    }
  }

  .card-brand-small a {
    .card-body {
      padding: 1.8rem 0.5rem;

      h5 {
        font-size: 12px;
      }
    }
  }

  .card-brand a {
    img.logo {
      width: 75px;
      height: 75px;
    }

    .card-body {
      padding-left: 5px;
      padding-right: 0;

      h5 {
        font-size: 12px;
      }

      .card-text {
        font-size: 8.7px;
      }
    }

    .btn-link {
      top: 0 !important;
      right: 0 !important;
    }
  }

  .brand-show-logo-3 {
    width: 110px;
  }

  .brand-show-filter-select {
    width: 50%;
  }
}

@media (max-width: 375px) {
  .brand-crumbs {
    .card-body {
      padding-left: 5px;
      padding-right: 0;
    }

    .btn-link {
      top: 0 !important;
      right: 0 !important;
    }
  }
}

@media (max-width: 480px) {
  .brand-crumbs {
    .card-body {
      margin-left: 0;
    }

    img.logo {
      width: 80px;
    }

    .card-text {
      font-size: 12px;
    }
  }

  .card-brand a {
    img.logo {
      width: 80px;
    }

    .card-body {
      .card-text {
        font-size: 12px;
      }
    }
  }

  .brand-show-mark-logo {
    top: -35px;
  }

  .brand-show-top-box {
    padding-left: 0;
  }

  .brand-banner {
    img.card-bg {
      width: calc(100vw - 30px);
    }
  }
}

.sidebar--item {
  border-bottom: 1px solid rgba(202, 202, 202, 0.5);
  padding-bottom: 24px;
  max-width: 254px;
  .filter-question {
    label {
      @include media-breakpoint-down(sm) {
        width: 75%;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}

@media (max-width: 320px) {
  .brand-crumbs {
    img.logo {
      width: 60px;
    }
  }

  .card-brand {
    img.logo {
      width: 60px;
    }
  }
}

.sidebar--item,
.markalar-filter {
  h6 {
    color: #202124;
    font-size: 18px;
    margin: 16px 0;
  }
}
.sidebar--item-detail {
  max-height: 277px;
  overflow-y: auto;
  .form-group.form-check {
    margin: 16px 0 !important;
    .checkbox-label {
      margin: 0;
      line-height: 1;
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #d8d8d8;
  }
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 8px;
  }
}
.sidebar--item-detail.classes {
  .form-group {
    label::after,
    label::before {
      top: 2px;
    }
  }
}

.filter-lesson-container {
  margin-bottom: 24px;
  .filter-lesson-input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background: #f4f4f4;
    font-size: 14px;
    font-weight: 600;
    color: #545454;
  }
}

.sidebar-line {
  width: 100%;
  height: 1px;
  border: solid 1px #cacaca;
  opacity: 0.5;
  margin: 24px 0;
}

.star-width {
  width: 16px;
  height: 16px;
}
.star-full {
  background: url(../../../images/sideBar/icon-star-yellow.svg) no-repeat center;
  height: 100%;
  width: 100%;
  transform: scale(1.5);
}

.star-empty {
  background: url(../../../images/sideBar/icon-star.svg) no-repeat center;
  height: 100%;
  width: 100%;
  transform: scale(1.5);
}

.orderlist {
  display: flex;
  width: 100%;
  max-width: 756px;
  height: 40px;
  border-radius: 15px;
  background: #f4f4f4;
  padding: 4px;
  justify-content: space-between;
  margin: 0;
  .orderlist-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #202124;
  }
  .active {
    background: #ffffff;
    color: #6e87ff;
    border-radius: 12px;
  }
}

.view-list {
  display: none;
  border-radius: 12px;
  width: 137px;
  height: 40px;
  background: #f4f4f4;
  justify-content: center;
  align-items: center;
  padding: 12px 4px;
  margin-left: 19px !important;
  .view-button {
    display: flex;
    padding: 6px 10px;

    div {
      width: 10px;
      height: 16px;
      border-radius: 3px;
      border: solid 3px #202124;
      margin: 1px;
    }
  }
  .view-button.active {
    background: #ffffff;
    border-radius: 9px;

    div {
      border: solid 3px #6e87ff;
    }
  }
}

.filter-clear-all {
  padding: 0;
  background: transparent;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  color: #202124;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

#sticky {
  position: relative;
}
#sticky.fixed {
  position: fixed;
}
#sticky.stuck {
  position: absolute;
}

@media (max-width: 768px) {
  .orderlist {
    display: none;
  }
}

.filter-accordion-item {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  .filtered-item {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #f34854;
  }
  .filter-lesson-container {
    margin-bottom: 0;
  }

  .filter-border-bottom {
    border-bottom: solid 1px#f4f4f4;
    padding: 1rem 0;
  }
  .filter-title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    line-height: normal;
    color: #202124;
  }
  .filter-accordion-icon {
    width: 17px;
    height: 17px;
    transition: 200ms ease-in-out all;
  }
  .brand-checkbox-container {
    height: 0;
    opacity: 0;
    .brand-checkbox {
      display: none;
    }
    .form-check {
      padding-left: 0;
    }
  }
}
.filter-accordion-item.show {
  background-color: #fbf9f9;
  .filter-accordion-icon {
    transform: rotate(90deg);
  }
  .brand-checkbox-container {
    height: 100%;
    opacity: 1;
    transition: 500ms ease-in-out all;
    .brand-checkbox {
      display: flex;
    }
  }
}

// .modal-content {
//   height: 100vh;
// }

.modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #202124;
}

.sort-modal {
  height: auto;
  width: 100% !important;
  bottom: 0 !important;
  top: auto !important;

  .modal-content {
    height: auto !important;
    min-height: auto !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    .modal-header {
      padding: 24px 0 16px;
      margin: 0 16px;
    }
    .modal-body {
      .list-group {
        .list-group-item {
          display: flex;
          align-items: center;
          border-radius: 12px;
          padding: 8px !important;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          color: #202124;
          height: 48px;
          padding: 0;
        }
        .list-group-item.active {
          background-color: #f4f4f4;
        }
      }
    }
  }
}

.modal-header {
  border-bottom: 1px solid #f4f4f4 !important;
}

.badge.product-detail-badge {
  background: transparent;
  display: flex;
  @include media-breakpoint-down(xs) {
    justify-content: center;
  }
  div {
    color: #202124;
    font-size: 12px;
    p {
      padding-right: 0;
      font-size: 12px;
    }
  }
}
.filter-modal .filter-accordion-item .filter-accordion-icon {
  transform: unset;
}

.filter-modal .filter-accordion-item.show .filter-accordion-icon {
  transform: rotate(90deg);
}
