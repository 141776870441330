.basket-nav {
  z-index: 9999;
  height: 100%;
  width: 120px;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0 0 5px 0 rgba(170, 170, 170, 0.5);
  border: solid 1px #d8d8d8;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: 5px;

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f7f7f7;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c0c0c0;
  }

  .basket-nav-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid rgba(228, 228, 228, 0.5);
    background-color: #fff;
    position: fixed;
    top: 0;
    height: 120px;
    z-index: 999;
    width: inherit;

    .basket-nav-title {
      font-size: 12px;
      letter-spacing: normal;
      color: #000;
    }
    .basket-nav-price {
      font-size: 14px;
      font-weight: bold;
      color: #f34854;
    }
    .btn-basket-link {
      border: 1px solid transparent;
      border-radius: 8px;
      font-size: 12px;
      letter-spacing: normal;
      background-color: #ff7100;
      color: white;
      margin-top: 0.5rem;
    }
  }

  .basket-nav-inner {
    .basket-list-container {
      list-style: none;
      padding: 0;
      margin-top: 120px;
    }

    .basket-item {
      padding: 12px;
      border-bottom: 1px solid rgba(228, 228, 228, 0.5);
    }

    .basket-item-price {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      text-align: center;
    }

    .basket-item-icons {
      width: 15px;
      height: 15px;
    }

    .img-wrapper {
      img {
        width: 100%;
      }
    }
  }
}
@media (max-width: 1400px) {
  .basket-nav {
    display: none;
  }
}
