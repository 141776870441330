.desk-stats {
  .avatar {
    .avatar-img {
      width: 64px;
      border-radius: 50px;
    }
    .avatar-name {
      width: 150px;
      height: 14px;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #484848;
    }

    .avatar-class {
      width: 50px;
      height: 20px;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #9b9b9b;
      margin-bottom: 0;
    }
  }

  .progress-thick.width-95 {
    width: 95%;
  }

  .new-book-btn {
    width: 159px;
    height: 48px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
  }
  .d-flex.align-items-center.justify-content-between {
    margin-bottom: 32px;
    .stats-text {
      min-width: 220px;
      height: 14px;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #484848;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include media-breakpoint-down(md) {
        min-width: 160px;
      }
      @include media-breakpoint-down(xs) {
        min-width: auto;
        width: 50%;
      }
    }
    .badge-container {
      b {
        @include media-breakpoint-down(xs) {
          font-weight: 500 !important;
        }
      }
      span {
        font-family: "Montserrat", sans-serif;
        padding: 8px 12px;
        font-size: 14px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
      @include media-breakpoint-down(md) {
        margin-left: 8px;
      }
    }
  }

  .lesson-error-box-count {
    margin: 0;
    margin-left: 10px;
    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: #484848;
    @include media-breakpoint-down(sm) {
      font-size: 24px !important;
    }
  }

  .attension-img {
    position: absolute;
    right: -20px;
    bottom: 20px;
  }

  .lesson-img {
    width: 50px;
  }
}

.badge-container {
  width: 65px;
  text-align: left;
  display: flex;
  align-items: center;
}
.desk-stats .badge-container {
  width: 125px;
}

.badge-success {
  background: #cdffd1;
  color: #3cd347;
  border-radius: 50px;
  padding: 10px;
}

.badge-red {
  background: #ffcbcb;
  color: #ff4a4a;
  border-radius: 50px;
  padding: 10px;
}

.badge-red-new {
  background: #ffcbcb;
  color: #b20202;
  border-radius: 50px;
  padding: 10px;
}
.badge-gray {
  background: #dedede;
  border-radius: 50px;
  padding: 10px;
}

.text-danger-new {
  color: #b20202;
}

.stats-lesson-emoji1 {
  width: 80px;
}

.lesson-list-name {
  width: 220px;
}

.error-box-lessons {
  font-size: 16px;
  border-radius: 20px !important;
  margin-right: 10px;
  text-transform: capitalize;
  font-weight: 600;
  padding: 8px 16px !important;
  color: #484848;
  @include media-breakpoint-down(xs) {
    font-weight: 500;
  }
}
.error-box-lessons.btn-blue {
  color: #fff;
}
.stats-error-container {
  button {
    @include media-breakpoint-down(xs) {
      width: 100%;
      margin: 0 !important;
      font-weight: bold !important;
    }
  }
  .stats-error-box {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 120px;
    overflow-y: auto;
    @include media-breakpoint-down(xs) {
      width: 100%;
      height: auto;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #d8d8d8;
    }
    &::-webkit-scrollbar {
      width: 6px;
      border-radius: 8px;
    }
    .form-group.form-check label:before {
      border-color: #757575;
    }
  }
}

.filters-btn-groups {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;
  background: #f4f4f4;
  border-radius: 50px;
  padding: 5px;

  .active {
    color: #fff;
    background-color: #6e87ff;
    border-color: #6e87ff;
  }

  .disable {
    background: #f4f4f4;
    border-radius: 8px;
    padding: 8px 12px;
    border: 0;
  }

  .btn {
    border-radius: 20px !important;
    transition: 0.2s;

    &:last-child {
      margin-right: 0;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
}

.stats-lesson-tab-bar {
  width: 80% !important;

  .btn {
    font-weight: bold;
    color: #484848 !important;
  }

  .btn.active {
    color: white !important;
  }
}

@media (max-width: 768px) {
  .stats-lesson-tab-bar {
    width: 100% !important;
  }
}

@media (max-width: 568px) {
  .stats-lesson-top-summary {
    height: 80px;

    .card-body {
      z-index: 2;
      p {
        margin: 0;
        font-size: 16px !important;
        font-weight: bold !important;
      }
      span {
        font-weight: 600;
        font-size: 13px !important;
      }
    }
  }

  .mobile-fix-content {
    font-size: 14px;
  }

  .stats-lesson-emoji1 {
    position: absolute;
    bottom: -11px;
    right: -25px;
    width: 110px;
    opacity: 0.45;
    z-index: 1;
  }

  .lesson-logo-stats {
    width: 72px;
  }
}

@media (max-width: 830px) {
  .lesson-list-name {
    width: auto;
  }
}

@media (max-width: 530px) {
  .lesson-list-name {
    width: auto !important;
  }
}

@media (max-width: 1200px) {
  .attension-img {
    width: 90px;
  }
}

@media (max-width: 768px) {
  .filters-btn-groups {
    .btn {
      padding: 5px 10px !important;
      text-align: left;
      font-size: 14px;
      margin: 0;
    }
  }

  .lesson-list-name {
    width: 220px;
  }
  .progress-thick.width-95 {
    width: 100% !important;
    margin-top: 10px;
  }
  .new-book-btn {
    width: 100% !important;
  }
}

@media (max-width: 460px) {
  .lesson-list-name {
    width: 100px !important;
    font-size: 13px;
  }

  .lesson-list-rate {
    font-size: 13px;
  }

  .card-body {
    padding: 0.5rem;
  }

  .card-header {
    padding: 0.75rem 1rem;
  }

  .attension-img {
    width: 70px;
  }

  .lesson-img {
    width: 40px !important;
  }

  .lesson-title {
    font-size: 1rem;
  }
}

@media (max-width: 370px) {
  .mobile-fix-content {
    .lesson-list-name {
      margin-right: 15px;
    }
  }
}

@media (max-width: 355px) {
  .filters-btn-groups {
    .btn {
      font-size: 12px;
    }
  }

  .stats-lesson-mobile-btn {
    margin: 1rem 0 !important;
  }
}

@media (max-width: 320px) {
  .lesson-error-box-count {
    font-size: 30px !important;
  }

  .attension-img {
    width: 60px;
  }

  .lesson-img {
    width: 35px !important;
  }
}

.error-box-modal {
  @include media-breakpoint-down(xs) {
    margin: 44px 32px !important;
  }
  .modal-content {
    border-radius: 10px !important;
    height: auto !important;
    @include media-breakpoint-down(xs) {
      height: 509px;
      min-height: auto !important;
    }
    .modal-header {
      border-bottom: 1px solid #dedede !important;
      .modal-title {
        font-size: 20px;
        font-weight: 600;
        color: #484848;
        @include media-breakpoint-down(xs) {
          font-size: 16px;
        }
      }
    }
    .modal-body {
      @include media-breakpoint-down(xs) {
        overflow-y: auto;
      }
      .numeric-input {
        @include media-breakpoint-down(xs) {
          width: 100% !important;
        }
      }
      p {
        @include media-breakpoint-down(xs) {
          font-weight: 600 !important;
        }
      }
    }
  }
}

.unit-list {
  h5 {
    @include media-breakpoint-down(xs) {
      font-size: 16px !important;
      font-weight: bold !important;
    }
  }
  .tab-bar {
    .tab-header {
      button {
        font-size: 16px;
        font-weight: 600;
        padding: 7px 32px;
        @include media-breakpoint-down(xs) {
          font-size: 10px !important;
          font-weight: bold !important;
          display: flex;
          align-items: center;
          color: #6e87ff;
          padding: 7px 16px;
        }
      }
    }
  }
}

.unit-card-item {
  .unit-card-header {
    .d-flex {
      span {
        font-weight: 600;
      }
    }
    .d-flex.align-items-center {
      span {
        font-weight: normal;
        @include media-breakpoint-down(xs) {
          font-weight: 500;
          font-size: 11px !important;
        }
      }
    }
  }
  .unit-card-body {
    span {
      @include media-breakpoint-down(xs) {
        font-weight: 600;
        font-size: 10px;
      }
    }
  }
  .unit-card-footer {
    display: flex;
    justify-content: center;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(xs) {
        width: 100% !important;
        padding: 0 !important;
      }
    }
  }
}
.branch-container {
  @include media-breakpoint-down(xs) {
    padding: 0 2px !important;
  }

  .branch-card {
    .card-header {
      .lesson-title {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
        }
      }
    }
    .card-body {
      @include media-breakpoint-down(xs) {
        padding: 0 16px !important;
      }

      h4 {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
        }
      }
      .progress {
        @include media-breakpoint-down(xs) {
          height: 19px;
        }
      }
      .total-question {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
          text-align: left;
          font-weight: 500 !important;
        }
      }
    }
    .card-footer {
      background: transparent;
      border: 0;
      @include media-breakpoint-down(xs) {
        padding: 0 16px 16px;
      }
      .stats-container {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
        }
        span.badge {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .card-footer {
    background: transparent;
    border: 0;
    padding: 0 6px 16px !important;
  }
}
.lesson-error-box-container {
  @include media-breakpoint-down(xs) {
    padding: 0 5px !important;
  }
  .lesson-error-box {
    .card-header {
      h4 {
        font-weight: 600;
        @include media-breakpoint-down(xs) {
          font-size: 14px !important;
        }
      }
      p {
        @include media-breakpoint-down(xs) {
          font-size: 12px !important;
        }
      }
    }
    .card-body {
    }
  }
}
