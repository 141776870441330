.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover {
  background-color: #663a8e !important;
}

.plyr--full-ui input[type="range"] {
  color: #ff7100 !important;
}
.bubbleContainer {
  width: 100%;
  text-align: center;
}
.bubble {
  position: relative;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  width: 400px;
  background: #edeeff;
  border-radius: 20px;
  padding: 24px;
  text-align: left;
  color: #000;
  top: 100px;
  right: 0;
  left: 0;
  margin: auto;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #edeeff;
  border-right: 12px solid transparent;
  border-top: 12px solid #edeeff;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
  color: #edeeff;
}
