.list-group.account-menu {
  border: 0;
  width: 210px;
  @include media-breakpoint-down(lg) {
    width: auto;
  }
  .list-group-item {
    border: 0;
    font-size: 16px;
    font-weight: 500;
    color: #202124;
    opacity: 1;
    line-height: 18px;
    text-align: left;
    padding: 19px 0;
    border-radius: 8px;
    border: solid 1px #e5e5e5;
    margin: 4px 0;
    @include media-breakpoint-up(lg) {
      padding: 8px 0;
      border: 0;
    }
    &.active {
      background: transparent;
      @include media-breakpoint-up(lg) {
        background: #c0cbfc;
        border-radius: 20px;
      }
    }
  }
}

.change-password-container {
  .card {
    border-radius: 16px;
    border: solid 1px #e5e5e5;
    box-shadow: none;
    @include media-breakpoint-down(xs) {
      border: 0;
    }
    .card-body {
      width: 50%;
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}

.address-box {
  border-radius: 16px;
  border: solid 1px #e5e5e5;
  padding: 28px 16px;
  @include media-breakpoint-down(xs) {
    padding: 0;
    border: 0;
  }
  .col-12.col-md-6 {
    @include media-breakpoint-down(xs) {
      padding: 0;
      padding-bottom: 16px !important;
      margin-top: 0 !important;
    }
  }
  .card {
    border-radius: 12px;
    background: #f4f4f4;
    box-shadow: none;
    padding: 24px 24px 16px;
    .card-header {
      background: transparent;
      padding: 0;
    }
    .card-body {
      padding: 0;
    }
  }
}

.account-settings-container {
  border-radius: 16px !important;
  border: solid 1px #e5e5e5 !important;
  box-shadow: none !important;
  @include media-breakpoint-down(xs) {
    border: 0 !important;
  }
  .card-body {
    @include media-breakpoint-down(xs) {
      padding: 0 !important;
    }
    .account-settings-item {
      width: 360px;
      margin: 0;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      .form-group.col-12,
      .form-group.col {
        padding: 0;
      }
      .input-group {
        align-items: center;

        input {
          height: 48px;
          border-radius: 8px !important;
        }
        .input-group-append {
          position: absolute;
          align-items: center;
          left: 100%;
          @include media-breakpoint-down(sm) {
            flex-direction: column-reverse;
            left: auto;
            right: 0;
            bottom: 0;
          }
          button {
            height: 48px;
            border-radius: 8px !important;
            border: 1px solid #484848;
            color: #484848;
            background: #fff;
            font-size: 16px;
            font-weight: 600;
            margin: 0 16px;
            @include media-breakpoint-down(sm) {
              height: 32px;
              width: 83px;
              padding: 0;
              margin: 8px 0;
            }
          }
        }
      }
      .radio-container {
        padding-left: 34px;
      }
    }
  }
}
.input-group-append {
  .btn.btn-outline-dark {
    z-index: 0;
  }
}

.wallet-score-box {
  font-size: 14px;
  color: #484848;
  display: flex;
  align-items: center;
  @include media-breakpoint-down(xs) {
    font-size: 12px;
  }
}

.preferences-card.card {
  border: solid 1px #e5e5e5;
  box-shadow: none;
  @include media-breakpoint-down(xs) {
    border: 0;
  }
}
