.settings-custom-radio {
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #dedede;
  height: 53px;
  line-height: 53px;
  margin: 25px 0;

  &:focus-within {
    border-color: #757575;
  }

  .radio-checkmark {
    // top: 50%;
    transform: translateY(-50%);
    left: 25%;
    // width: 20px;
    // height: 20px;

    &:after {
      top: 0;
      left: 0;
    }
  }
}
