/* The container */

$radio-size: 14px;

.radio-container {
  display: block;
  position: relative;
  padding-left: 22px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  line-height: 24px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ .radio-checkmark {
    background-color: #e1e1e1;
  }

  input:checked ~ .radio-checkmark {
    background-color: white;
    border-color: #757575;
  }

  input:checked ~ .radio-checkmark:after {
    display: block;
  }

  .radio-checkmark:after {
    top: 1px;
    left: 1px;
    width: $radio-size;
    height: $radio-size;
    border-radius: 46%;
    background: var(--blue);
  }

  &.img-radio {
    margin-bottom: 40px !important;

    .radio-checkmark {
      top: auto;
      bottom: -30px !important;
      left: 50%;
      transform: translateX(5px);
    }
  }
}

.radio-container.selected {
  div {
    border-radius: 50%;
    border: 2px solid #6e87ff;
  }
}

.radio-checkmark {
  position: absolute;
  left: 0;
  top: $radio-size / 10;
  height: $radio-size + 4px;
  width: $radio-size + 4px;
  background-color: #eee;
  border-radius: 46%;
  border: 1px solid #979797;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.answers-custom-checkbox {
  .radio-checkmark:after {
    background: #484848 !important;
  }
}
