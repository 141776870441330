.main {
  .card-img {
    position: relative;
  }
}

.card-main {
  // margin-right: -0.5rem;
  // margin-left: -0.5rem;

  .row {
    margin-left: 0 !important;
  }
  .container {
    margin: 0 !important;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    // padding-right: 0.5rem;
    // padding-left: 0.5rem;
  }

  @include media-breakpoint-only(xs) {
    margin-right: 0;
    margin-left: 0;
  }
}

.card-banners {
  column-count: 2;
  @include media-breakpoint-only(xl) {
    column-count: 1;
  }
}
.main-page-card {
  border-radius: 16px;
  height: auto;
}

#mainSlider {
  padding-bottom: 100px;
  .slick-slider {
    padding: 0;
  }
  .carousel-img {
    border-radius: 16px;
    overflow: hidden;
    object-fit: cover;
    object-position: left;
  }

  // .slick-arrow.slick-next {
  //   display: none !important;
  // }

  .slick-dots {
    bottom: -86px;
    height: 80px;

    overflow: hidden;
    white-space: nowrap;
    background: transparent;
    column-gap: 16px;
    @include media-breakpoint-down(sm) {
      display: none !important;
    }
    &::-webkit-scrollbar {
      height: 5px;
      overflow: hidden;
      background: #f0f0f0;
    }

    &::-webkit-scrollbar-thumb {
      background: #e4e4e4;
      border-radius: 16px;
    }
    .slick-active a {
      border: 2px #f34854 solid;
    }

    li {
      width: 72px;
      height: 43px;
      display: inline-block;
      margin: 0 8px;
      a {
        border: 2px transparent solid;
        border-radius: 10px;
        width: 100%;
        height: auto;
      }
      &.slick-active::after {
        // content: "";
        background: #000;
        display: block;
        height: 5px;
        width: 80%;
        margin: 3px auto;
      }

      img {
        overflow: hidden;
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-page-card {
    height: auto;
    display: inline-block;
  }
  #imgmobile {
    object-fit: unset !important;
  }
  #mainSlider {
    margin-bottom: 0 !important;
    padding-bottom: 0;

    .slick-dots {
      bottom: 8px;
      height: auto;
      position: absolute;
      padding-top: 0;
      background: transparent;
      li {
        width: 30px;
        height: 7px;

        a {
          background: #f4f4f4;
          border: solid 1px #e8dfdf;
        }

        &.slick-active {
          a {
            background: #f34854 !important;

            &::after {
              display: none !important;
            }
          }
        }
      }
    }

    .customPagination {
      display: block;
      height: 20px;
      width: 20px;
      background: #dedede;
      border-radius: 50px;
      padding: 0 !important;

      div {
        display: none !important;
      }
    }
  }
  .more-arrow {
    margin-right: 16px !important;
  }
}

#mainTopSlider {
  padding: 0;
  .slick-slider {
    padding: 24px 0;
    @include media-breakpoint-down(xs) {
      padding: 16px 0;
    }
  }

  .main-top-slider-item {
    display: flex !important;
    flex-direction: column;
    .slider-img-box {
      width: 96px;
      height: 96px;
      border-radius: 50%;
    }
    &--text {
      padding-top: 16px;
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      font-size: 13px;
      color: #202124;
    }
  }
}

.main-book-slider-container {
  background-color: #f4f4f4;
  padding-top: 32px;
}
.main-book-slider {
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 32px;
  position: relative;
  .editable-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #dedede;
    background-color: #fff;
    figure {
      pointer-events: none;
    }
  }
  h2 {
    padding: 24px 12px 0;
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    color: #2a2a2a;
    // cursor: pointer;
    margin: 0;
  }
  .slick-track {
    display: flex;
    align-items: center;
  }
  // .img-fluid {
  //   max-width: 220px;
  // }
}
.slick-slider {
  padding: 24px 5px;
  .slick-arrow {
    width: 40px;
    height: 80px;

    background-color: rgb(255, 255, 255);
    opacity: 0.5;
    transition: 0.1s all linear;
    &:before {
      font-size: 40px;
      color: black;
      font-family: "icomoon" !important;
    }
    &:hover {
      opacity: 1;
    }
  }
  .slick-prev {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    left: 0;
    z-index: 38;
  }
  .slick-next {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    right: 0;
  }

  .slick-prev:before {
    content: "\e900" !important;
  }
  .slick-next:before {
    content: "\e901" !important;
  }
}

// .more-arrow {
//   min-width: 40px;
//   height: 40px;
//   background-color: #f4f6ff;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;

//   &::before {
//     content: "\e902" !important;
//     font-family: "icomoon" !important;
//     font-size: 21px;
//     color: #6e87ff;
//     font-weight: 600;
//   }
// }

.book-detail-container {
  display: flex;
  justify-content: center;

  .book-detail-box {
    background-color: #ffffff;
    border: solid 1px #f4f4f4;
    border-radius: 16px;
    max-width: 204px !important;
    height: 327px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    .head-image {
      border-radius: 16px;
    }
    .book-detail-body {
      padding: 0 12px;
      div {
        font-size: 12px;
        line-height: 1.37;
        color: #212529;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        color: #f34854;
      }
    }
  }
}
.book-detail-container .book-detail-box .book-detail-body div {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}

.shopping-body
  .shopping-slider
  .slick-slider
  .book-detail-container
  .book-detail-box
  .book-detail-body
  .book-detail-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0;
}
.book-detail-container .book-detail-box:hover,
.main-card .classes-list .card-item:hover {
  box-shadow: 0 2px 11px 0 hsla(0, 0%, 63.1%, 0.5) !important;
  transition: 0.1s;
  border-radius: 16px;
}

.main-card {
  position: relative;
  padding: 24px;
  // max-width: 384px;
  height: 408px;
  border-radius: 16px;
  border: solid 1px #eeeeee;
  background-color: #ffffff;
  &:last-child {
    margin-right: 0px;
  }
  .editable-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #dedede;
    background-color: #fff;
    figure {
      pointer-events: none;
    }
  }
  .main-card-header {
    margin-bottom: 24px;
    &.multi-header {
      margin-bottom: 32px;
      @include media-breakpoint-down(lg) {
        margin-bottom: 24px;
      }
    }
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 20px;
    }
  }

  img.img-fluid {
    width: 336px;
    height: 312px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin: auto;
  }

  .card-item {
    width: 100%;
    border-radius: 16px;
    background-color: #fbfbfb;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    img.card-img {
      width: 78px;
      height: 78px;
      margin-top: 16px;
      @include media-breakpoint-down(lg) {
        width: 62px;
        height: 62px;
      }
    }
    span {
      margin: 8px 0px 14px;
      font-size: 16px;
      font-weight: 500;

      color: #202124;
    }
  }
  .classes-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
    margin: 0;
    gap: 24px;
    li {
      list-style: none;
      display: flex;
      justify-content: center;
    }
  }
}

.custom-img {
  width: 152px;
  max-width: 152px;
}
.main-banner {
  margin: 32px 0;
  position: relative;
  .editable-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #dedede;
    background-color: #fff;
    figure {
      pointer-events: none;
    }
  }
}

/*Recomendation*/

.main-recommendation {
  padding-bottom: 24px;
  .recommendation-head {
    padding-top: 10px;
    img {
      width: 123px;
      height: 123px;
    }
    .recommendation-head-box {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 0 16px 16px;
      div:first-child {
        font-size: 24px;
        font-weight: bold;
        color: #2a2a2a;
        @include media-breakpoint-down(xs) {
          font-size: 20px;
        }
      }
      div:last-child {
        font-size: 16px;
        font-weight: 500;
        color: #202124;
        @include media-breakpoint-down(xs) {
          font-size: 14px;
        }
      }
    }
  }

  /*BODY STEP-1, STEP-2*/
  .recommendation-body.step-1,
  .recommendation-body.step-2 {
    width: 100%;
    // height: 133px;
    background-color: #edeeff;
    border-radius: 16px;
    display: flex;
    .progress-body {
      display: flex;
      flex-direction: column;
      // width: 364px;
      // height: 100%;
      padding: 29px 0px 30px 33px;
      .progress-title {
        font-size: 28px;
        font-weight: 600;
        color: #36458a;
        padding-bottom: 17px;
        position: relative;
        span {
          position: absolute;
          top: -6px;
          @include media-breakpoint-down(xs) {
            position: relative;
            top: auto;
          }
        }
      }
      .progress {
        width: 140px;
        height: 16px;
        background-color: #ffffff;
        .progress-bar {
          font-weight: bold;
          color: #ffffff;
          border-radius: 50px;
          background-color: #f6cc30;
        }
      }
      .arrow-container {
        img {
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }

    .class-section {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      ul {
        padding: 16px 0;
        display: flex;
        list-style: none;
        margin: 0;
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 4px;
          height: 8px;
          overflow: hidden;
          background: #f0f0f0;
        }

        &::-webkit-scrollbar-thumb {
          background: #cccccc;
          border-radius: 4px;
        }
        li {
          margin: 0 8px;
          cursor: pointer;
          position: relative;
          .class-number {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            left: 0;
            font-size: 24px;
            font-weight: bold;
            line-height: 2;
            color: #ffffff;
          }
        }
      }
      .class-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 72px;
        img {
          width: 56px;
          height: 56px;
        }
        span {
          padding-top: 8px;
          font-size: 12px;
          font-weight: 500;
          color: #202124;
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  /*BODY-STEP-3*/
  .recommendation-body.step-3 {
    width: 100%;
    // height: 133px;
    background-color: #edeeff;
    border-radius: 16px;
    display: flex;
    // padding: 10px 0;
    .progress-body {
      display: flex;
      flex-direction: column;
      padding: 24px 0px 24px 24px;
      justify-content: center;
      .progress-title {
        font-size: 28px;
        font-weight: 600;
        color: #36458a;
        padding-bottom: 17px;
        position: relative;
        span {
          position: absolute;
          bottom: 20px;
          @include media-breakpoint-down(xs) {
            position: relative;
            bottom: auto;
          }
        }
      }
      .progress {
        width: 140px;
        height: 16px;
        background-color: #ffffff;
        .progress-bar {
          font-weight: bold;
          color: #ffffff;
          border-radius: 50px;
          background-color: #f6cc30;
        }
      }
      .arrow-container {
        img {
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
    .answer-section {
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        padding: 0;
        margin: auto;

        .answer-box {
          padding: 6px 12px 6px 0;

          position: relative;
          cursor: pointer;
          .btn-recommendation {
            background: linear-gradient(
              to bottom,
              #6e87ff 0%,
              #6e87ff 90%,
              #8ea2ff 91%,
              #8ea2ff 100%
            );
            border: 1px solid transparent;
            border-radius: 16px;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
            color: #ffffff;
          }
        }
      }
    }
  }

  /*BODY-STEP-4*/
  .recommendation-body.step-4 {
    background-color: #edeeff;
    border-radius: 16px;
    display: flex;
    .progress-body {
      display: flex;
      flex-direction: column;

      height: 100%;
      padding: 29px 0px 30px 33px;
      @include media-breakpoint-down(lg) {
        padding: 8px 0px 8px 8px;
      }
      .progress-title {
        font-size: 28px;
        font-weight: 600;
        color: #36458a;
      }
      .progress-text {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #36458a;
      }
    }
    .result-section {
      display: flex;
      @include media-breakpoint-down(md) {
        padding-bottom: 16px;
      }
      ul {
        height: 100%;
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: auto;
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          justify-content: start;
        }
        li {
          display: flex;
          align-items: center;
          img {
            height: 102px;
          }
          .result-box {
            margin-left: 20px;
            .result-title {
              font-size: 14px;
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              color: #202124;
            }
            .btn.btn-result {
              width: 119px;
              height: 40px;
              font-size: 14px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: normal;
              text-align: center;
              color: #ffffff;
              background-color: #6e87ff;
              border-radius: 10px;
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
}

/*main-helper-links*/

.main-helper-links-container {
  background-color: #f4f4f4;
  .main-helper-links {
    display: flex;
    margin: 0 0 25px 0;
    .helper-links-container {
      margin: 0 12px;
      @include media-breakpoint-down(sm) {
        min-width: 120px;
        margin: 0 6px;
      }
      .helper-links-box {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border-radius: 32px;
        padding: 30px;
        &:first-child {
          margin-left: 0 !important;
        }
        &:last-child {
          margin-right: 0 !important;
        }
        img {
          width: 53px;
          height: 53px;

          margin-right: 20px;
        }
        .helper-text {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #202124;
        }
      }
    }
  }
}

.main-library {
  padding-bottom: 41px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.15);
  .slick-slider {
    padding: 0;
  }
  .library-head {
    display: flex;
    justify-content: start;
    align-items: center;
    padding-top: 32px;
    .library-head-img {
      overflow-y: hidden;
      height: 115px;
      margin-right: 15px;
      .img-fluid {
        width: 80px;
        height: 136px;
      }
    }

    .library-head-box {
      width: 300px;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #2a2a2a;
    }
  }

  .library-item-container {
    position: relative;

    .secret-item {
      .library-box {
        @include media-breakpoint-down(xs) {
          opacity: 0.3;
        }
      }
      .library-img-wrapper {
        min-width: auto !important;
        width: 76px !important;
        @include media-breakpoint-down(md) {
          width: auto !important;
        }
      }
    }
    .library-item {
      display: flex;
      padding: 16px 24px;
      background: #f4f4f4;
      border-radius: 16px;
      position: relative;
      height: 143px;
      @include media-breakpoint-down(lg) {
        padding: 16px 16px;
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 17px 12px 8px;
        height: auto;
      }
      // @include media-breakpoint-down(sm) {
      //   height: 355px;
      // }

      .library-img-wrapper {
        min-width: 89px;
        width: 89px;
        height: auto;
        @include media-breakpoint-down(md) {
          width: auto;
        }
        img {
          width: 100%;
        }
      }
      .img-backdrop {
        background: #ffffff;
        padding: 8px;
        border-radius: 8px;
        position: relative;
        @include media-breakpoint-down(md) {
          background: transparent;
          padding: 0;
        }
        img {
          opacity: 0.2;
          // @include media-breakpoint-down(xs) {
          //   opacity: 1;
          // }
        }
        .library-lock-icon {
          position: absolute;
          width: 41px;
          height: 41px !important;
          left: 30%;
          bottom: 33%;
          opacity: 1 !important;

          @include media-breakpoint-down(md) {
            left: 40%;
            bottom: 50%;
          }
          @include media-breakpoint-down(xs) {
            left: 0;
            bottom: auto;
            top: 0;
          }
        }
      }
      .library-box {
        display: flex;
        flex-direction: column;
        margin-left: 19px;
        @include media-breakpoint-down(md) {
          text-align: center;
          margin: 8px 0 0;
          height: 37px;
        }
        .library-title {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: #181b54;
          height: 41px;
          overflow: hidden;
          text-overflow: ellipsis;
          @include media-breakpoint-down(md) {
            font-size: 12px;
            height: auto;
          }
        }
        .progress {
          height: 16px;
          width: 142px;
          background-color: #ffffff;
          margin-top: 5px;
          @include media-breakpoint-down(md) {
            width: 100%;
          }
          .progress-bar {
            border-radius: 10px;
            background-color: #6dd400;
          }
        }
        .btn-library {
          font-size: 14px;
          font-weight: 600;

          color: #ffffff;
          background-color: #6e87ff;
          border-radius: 8px;
          width: 106px;
          height: 32px;

          padding: 7px 15px;
        }
        .btn-library-2 {
          position: absolute;
          background-color: #eb5252;
          border-radius: 8px 8px 0 0;
          bottom: 0;
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
          padding: 7px 16px;
        }
        .btn-library,
        .btn-library-2 {
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
      }
    }
  }
}

.progress-2 {
  display: none;
}

/*media query*/
@media (max-width: 1199px) {
  .recommendation-body.step-1 {
    .class-section {
      ul {
        li {
          margin: 0 5px 5px !important;
        }
      }
    }
  }

  .step-3 {
    .progress-title {
      font-size: 25px !important;
      padding-bottom: 14px !important;
    }
  }
  .main-card {
    padding: 9px 12px 12px;
    height: 345px;
    img.img-fluid {
      width: 100%;
      height: 265px;
    }
  }
  .recommendation-body.step-4 {
    .result-section {
      .col-6 {
        margin: 0 !important;
      }
    }
  }
  .main-helper-links {
    .helper-links-box {
      flex-direction: column;
      padding: 10px !important;
      img {
        margin-right: 0 !important;
      }
    }
  }
}
@media (max-width: 991px) {
  .main-recommendation {
    .progress-body {
      padding: 6px !important;
    }
    .class-section {
      ul {
        flex-wrap: wrap;
      }
    }
    .recommendation-body.step-3,
    .recommendation-body.step-4 {
      height: 100% !important;
      .answer-section {
        padding-top: 10px;
      }
    }
  }
}
@media (max-width: 767px) {
  .recommendation-body {
    height: 100% !important;
    padding: 10px !important;
    .class-section {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .step-1 {
    .class-section {
      height: auto !important;
    }
  }
  .main-card {
    padding: 9px 15px 15px;
    height: auto;
    img.img-fluid {
      width: 100%;
      height: auto;
    }
  }
  .main-helper-links {
    overflow-x: scroll;

    .helper-links-box {
      min-width: 25%;
    }
  }
}
@media (max-width: 480px) {
  .main-library {
    .library-item-container {
      .library-item {
        padding: 16px 5px !important;
        .library-box {
          height: 50px;
          .progress {
            width: 100% !important;
          }
        }
      }
    }
  }

  .library-head-box {
    font-size: 20px !important;
  }
  .main-helper-links {
    .helper-links-box {
      border-radius: 24px !important;
      min-width: 112px !important;
      .helper-text {
        font-size: 12px !important;
      }
    }
  }
  .progress-title {
    padding-bottom: 0 !important;
    font-size: 20px !important;
  }
  .step-3 {
    .progress-title {
      font-size: 20px !important;
    }
  }
  .step-4 {
    .progress-text {
      display: none;
    }
  }
  .progress-d-none {
    display: none !important;
  }
  .progress-2 {
    display: flex;
    width: 142px;
    height: 16px !important;
    border-radius: 16px;
    background-color: #ffffff !important;
    .progress-bar {
      background: #f6cc30 !important;
      // border-radius: 10px!important;
      font-weight: bold;
      color: #ffffff;
      border-radius: 50px;
      font-size: 14px;
    }
  }

  .main-card {
    .card-item {
      img.card-img {
        width: 56px;
        height: 56px;
      }
    }
  }
  #mainTopSlider {
    .slider-img-box {
      width: 72px !important;
      height: 72px !important;
    }
    .text-center {
      padding-top: 8px;
    }
  }
  #mainSlider {
    .slick-dots li {
      width: 12px;
    }
    .customPagination {
      width: 12px;
      height: 12px;
    }
  }
  .main-book-slider-container {
    padding-top: 8px;
    .container {
      padding: 0;
      .main-book-slider {
        border-radius: 0;
        margin-bottom: 8px;
        padding-top: 24px;
        .slick-slider {
          padding: 16px 5px;
        }
        h2 {
          font-size: 20px !important;
          padding: 0 12px;
        }
      }
    }
    .main-banner {
      margin: 16px 0 24px;
    }
  }
  .book-detail-container {
    margin: auto !important;
    .book-detail-box {
      min-width: 156px !important;
      height: 257px;
      width: 90% !important;
      img {
        width: 148px;
      }
    }
    .border-0 {
      img {
        height: 100%;
      }
    }
  }
}

/*step-2*/

.main-recommendation {
  .recommendation-body.step-2 {
    .class-section {
      ul {
        flex-wrap: nowrap;
        overflow-x: auto;
      }
    }
  }
}

.main-backdrop {
  position: fixed;
  top: 0;
  z-index: 30;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.main-backdrop.none {
  display: none;
}

.store-modal.none {
  display: none;
}
.store-modal {
  position: absolute;
  z-index: 33;
  top: 90px;
  width: 284px;
  height: 90px;
  border-radius: 8px;
  background: #edffe8;
  padding: 10px 16px;
  @include media-breakpoint-down(lg) {
    display: none;
  }
  &::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 20px;
    width: 20px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #edffe8;
  }
  .store-modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    h6 {
      font-size: 12px;
      font-weight: bold;
      color: #00573f;
      margin: 0 6px 0 0;
    }

    .badge {
      border-radius: 7px;
      border: solid 2px #ffffff;
      background-color: #c6ffef;
      padding: 2px 4px;
      font-size: 9px;
      font-weight: bold;
      color: #324b44;
      text-transform: uppercase;
    }
  }
  .store-modal-body {
    font-size: 12px;
    color: #00573f;
  }
  .store-modal-close-icon {
    position: absolute;
    right: 10px;
    top: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
@media (max-width: 480px) {
  footer {
    margin-bottom: 64px;
  }
}
.slick-disabled {
  display: none !important;
}
.kategoriler-bottom {
  padding-bottom: 41px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.15);
}
.main-book-slider .slick-list {
  padding-bottom: 8px;
}

.main-book-slider .book-detail-container .book-detail-box {
  justify-content: flex-start;
}
#mainTopSlider,
#mainSlider {
  .slick-slider {
    .slick-prev {
      left: -35px;
      background-color: transparent;
    }
    .slick-next {
      right: -35px;
      background-color: transparent;
    }
  }
}
