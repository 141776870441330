.table-installment {
  border-radius: 10px;

  tr {
    cursor: pointer;
  }

  tr.active {
    background-color: var(--blue);
  }
}
