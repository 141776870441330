.breadcrumb {
  background: transparent !important;
  margin: 24px 0;
  .breadcrumb-item,
  .breadcrumb-item a {
    color: #202124;
    font-size: 12px;
    font-weight: 500;
    padding: 0;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    background: url("../images/icons/chevron-bottom.svg") no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    width: 20px;
    height: 11px;
    position: relative;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    top: 2px;
  }
}
