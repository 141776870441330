.tree-table-container {
  overflow: hidden;
  @include media-breakpoint-down(xs) {
    overflow: auto;
  }
  .tree-table {
    width: 98%;
    border: 0;
    margin: 5px;
    @include media-breakpoint-down(xs) {
      height: 245px;
    }
    tbody {
      border: 0;
      td {
        text-align: center;
        padding: 10px;
        width: 43px;
        border-top: 0;
        box-shadow: 0 0 0 1px #6cba3c;
        background: #e7ffba;
        // border-radius: 8px;

        img {
          height: 33px;
        }
      }

      tr:first-child {
        td:last-child {
          border-top-right-radius: 8px;
        }
        td:first-child {
          border-top-left-radius: 8px;
        }
      }
      tr:last-child {
        td:last-child {
          border-bottom-right-radius: 8px;
        }
        td:first-child {
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
  .tree-table.table-responsive {
    @include media-breakpoint-down(xs) {
      overflow: initial;
    }
  }
  .tree-table.zoomActive {
    transform: scale(1.2);
  }
}
