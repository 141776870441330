.bg-board {
  background: url("../../../images/images/Pattern.jpg");
  background-size: 288px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  z-index: -1;
}

.board {
  &.card-body {
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: -0.5em;
      bottom: -1.4em;
      left: 55px;
      box-sizing: border-box;
      border: 0.7em solid black;
      border-color: #fff5d4;
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      box-shadow: -3px 3px 3px 0 rgba(191, 191, 191, 0.5);
    }
  }
  &.white-item {
    &.card-body {
      &::before {
        border-color: #fff;
      }
    }
  }
}
