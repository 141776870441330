.support-container {
  padding: 0;
  margin-bottom: 32px;
  .card {
    border-radius: 8px;
    box-shadow: none;
    border: solid 1px #e5e5e5;
    .card-header {
      border: 0;
      background: white;
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      .collapse-button {
        svg {
          height: 14px;
          width: 14px;
        }
      }
      .card-header-icon {
        width: 30px;
        text-align: center;

        svg {
          fill: #5f6368 !important;

          path {
            fill: #5f6368 !important;
          }
        }
      }

      .title {
        font-size: 18px;
        font-weight: 600;
        color: #484848;
        margin: 0;
        margin-left: 15px;
        flex: 1;
      }

      .collapse-button {
        width: 32px;
        height: 32px;
        text-align: center;
        line-height: 28px;
        border: 1px solid #d8d8d8;
        border-radius: 32px;
      }
    }
    .card-body {
      padding-bottom: 0;
      .accordion {
        .card {
          border: 0;
          border-bottom: 1px solid #dedede;
          margin: 0 45px;
          padding: 16px 0;
          @include media-breakpoint-down(xs) {
            margin: 0;
          }
          .card-header {
            padding: 0;
            padding-bottom: 8px;
            .btn {
              padding: 0;
              font-weight: 600;
            }
          }
          .card-body {
            padding: 0;
            padding-left: 24px;
            font-weight: 500;
            @include media-breakpoint-down(xs) {
              padding: 0;
            }
            a {
              color: #6e87ff;
            }
          }
        }
      }
    }
  }
}

.cargo-tracking.card {
  border: solid 1px #e5e5e5;
  box-shadow: none;
  .card-body {
    padding: 24px;
    @include media-breakpoint-down(xs) {
      padding: 0.5rem;
    }
    label {
      font-size: 13px;
      font-weight: 600;
      color: #202124;
    }
    .d-flex {
      height: 48px;
      input {
        width: 360px;
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }
      button {
        width: 110px;
        @include media-breakpoint-down(xs) {
          max-width: 68px;
        }
      }
    }
  }
}

.feedback-container.card {
  padding: 32px;
  border: solid 1px #e5e5e5;
  box-shadow: none;
  margin-bottom: 42px;
  @include media-breakpoint-down(xs) {
    padding: 24px 16px;
  }
  .card-header {
    padding: 0;
    background: transparent;
    border: 0;
    margin-bottom: 32px;
    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .card-body {
    padding: 0;
    button {
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
    }
  }
}

.support-contact {
  padding: 9px;
  border-radius: 8px;
  box-shadow: 0 0 12px 0 rgba(191, 191, 191, 0.5);
  @include media-breakpoint-down(xs) {
    padding: 16px 0;
  }
  .card-body {
    @include media-breakpoint-down(xs) {
      padding: 8px 16px;
    }
  }
  svg {
    width: 60px !important;
    height: 60px;
  }
}

@media (max-width: 375px) {
  .feedback-container.card {
    .card-header {
      span {
        font-size: 15px !important;
      }
    }
  }
}
