.product-image-gallery {
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #f4f4f4;
  position: sticky;
  top: 100px;
  @include media-breakpoint-down(md) {
    border: 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 0;
    border-radius: 0;
  }

  .image-gallery-thumbnail {
    width: 48px;
    height: 58px;
    border: solid 2px transparent;

    &.active {
      border-radius: 12px;
      border: solid 2px #6e87ff;
    }

    .image-gallery-thumbnail-inner,
    .image-gallery-thumbnail-inner img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none;
  }
}

.product-detail-box {
  padding: 0;
  .product-detail-card {
    margin-bottom: 16px;
    font-family: "Montserrat", sans-serif;
    box-shadow: none !important;
    border-radius: 0 !important;

    .product-detail-card-header {
      border: solid 1px #f0f0f0;
      margin-bottom: 16px;
      border-radius: 12px;
      padding: 24px;
      .product-detail-card-title {
        font-size: 24px;
        font-weight: 600;
        color: #202124;
      }
    }
  }

  .product-detail-card-basket {
    border: solid 1px #f0f0f0;
    margin-bottom: 16px;
    padding: 24px;
    border-radius: 12px;
    @include media-breakpoint-only(xs) {
      margin-bottom: 24px;
    }
  }
  .product-detail-card-analysis {
    border: solid 1px #f0f0f0;
    margin-bottom: 16px;
    border-radius: 12px;
    padding: 20px;

    span {
      font-size: 16px;
      font-weight: bold;
      color: #202124;
    }
    .difficulty-level-container {
      display: flex;
      width: 100%;
      padding: 0;

      .difficulty-level-item {
        display: flex;
        flex-direction: column;
        width: 107px;
        align-items: center;
        //ortak özellikler
        .difficulty-level,
        .question-quantity {
          font-size: 14px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #202124;
          position: relative;
          @include media-breakpoint-down(md) {
            font-size: 13px;
          }
        }
        .difficulty-level {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 30px;
          margin-bottom: 15px;
          &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            left: 0px;
            height: 5px;
            width: 100%;
            background: #f0f0f0;
          }
        }
      }
      .difficulty-level-item:nth-child(1) {
        .difficulty-level {
          font-weight: 500 !important;
          &::after {
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;
          }
        }
        .question-quantity {
          text-align: start;
          font-weight: 500;
        }
      }
      .difficulty-level-item:nth-child(2) {
        .difficulty-level {
          color: #82fd8f;
          &::after {
            background: #82fd8f;
          }
        }
      }
      .difficulty-level-item:nth-child(3) {
        .difficulty-level {
          color: #5ba125;
          &::after {
            background: linear-gradient(
              90deg,
              rgba(0, 137, 57, 1) 25%,
              rgba(111, 166, 33, 1) 75%
            );
          }
        }
      }
      .difficulty-level-item:nth-child(4) {
        .difficulty-level {
          color: #ffcb00;
          &::after {
            background: #ffcb00;
          }
        }
      }
      .difficulty-level-item:nth-child(5) {
        .difficulty-level {
          color: #d03c5d;
          &::after {
            background: linear-gradient(
              90deg,
              rgba(255, 96, 0, 1) 25%,
              rgba(192, 47, 126, 1) 75%
            );
          }
        }
      }
      .difficulty-level-item:nth-child(6) {
        .difficulty-level {
          color: #8b07e6;
          &::after {
            background: linear-gradient(
              90deg,
              rgba(151, 16, 206, 1) 25%,
              rgba(130, 0, 248, 1) 75%
            );
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
          }
        }
      }
    }
  }

  .scrollable-tabs {
    display: flex;
    overflow-x: auto; /* Yatay kaydırma ekler */
    white-space: nowrap;
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #d8d8d8;
    }
    &::-webkit-scrollbar {
      height: 6px;
      border-radius: 8px;
    }
  }
  
  .nav-tabs {
    display: flex;
    list-style: none;
  }

  .product-detail-card-details {
    display: none;
    margin-bottom: 16px;
    border: solid 1px #f0f0f0;
    padding: 12px 16px 24px;
    border-radius: 12px;
    .media {
      border-top: 1px solid #f0f0f0;
    }
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .product-brand {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: normal;
    color: #919191;
    margin-bottom: 16px;
  }

  .product-code {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: normal;
    color: #919191;
    margin-bottom: 16px;
  }

  .product-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 48px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #545454;
    border-radius: 12px;
    border: solid 1px #f0f0f0;
    padding: 0;
    span {
      font-size: 12px;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #202124;
    }
    .badge {
      width: 30px;
      border-radius: 7px;
      border: solid 2px #ffffff;
      background-color: #c6ffef;
      padding: 2px 4px;
      font-size: 9px;
      font-weight: bold;
      color: #324b44;
      text-transform: uppercase;
      margin-left: 4px;
    }
  }
  .product-type.product-digital-book {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 185px;
  }

  .product-type-selected {
    border: solid 2px#6e87ff;
  }

  .product-detail-price {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #202124;
    margin-bottom: 16px;
  }

  .product-pale-price {
    font-size: 18px;
    font-weight: 400;
    color: #707070;
  }

  .product-detail-price-digital {
    color: #7e7e7e;
  }

  .product-detail-price-selected {
    background-color: #ffe5bc;
  }

  .product-order-detail {
    display: flex;
    margin-bottom: 16px;
    .add-basket-button-box {
      margin-right: 16px;

      @include media-breakpoint-down(xl) {
        width: 330px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 241px;
        width: 100%;
      }
      .add-basket-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        background-color: #ff6a00;
        text-transform: none;
        font-size: 16px;
        font-weight: bold;
        line-height: 1 !important;
        text-align: center;
        color: #ffffff;

        figure {
          margin-right: 12px;
        }

        .add-notify-me-button {
          width: 190px;
          padding: 10px;
          border: none;
          border-radius: 8px;
          background: #fde7d8;
          color: #ffa96b;
          font-size: 16px;
          font-weight: 500;
          text-transform: none;

          &:hover {
            background: #fdd3b8;
          }
        }
      }
    }
  }

  .product-favourite {
    display: flex;
    justify-content: center;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    color: #202124;
    border-radius: 12px;
    border: solid 1px #f0f0f0;
    align-items: center;
    text-decoration: none;
    &:focus {
      box-shadow: none;
      text-decoration: none;
    }

    img {
      margin-right: 6px;
      @include media-breakpoint-down(md) {
        margin: 0;
      }
    }
  }

  .product-tags {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;

    .product-tag-item {
      height: 57px;
      border-right: 1.5px solid #c6c6c6;
      padding: 0 15px;
      flex: 0 0 20%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      &:last-child {
        border: none;
      }

      .product-tag-icon img {
        width: 32px;
        height: 32px;
        margin-bottom: 6px;
        flex: 1;
      }

      .product-tag-name {
        flex: 1;
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 500;
        color: #484848;
        width: 75%;
        text-align: center;
      }
    }
  }

  .product-search-store {
    width: 65%;
  }
}

.product-detail-tabs {
  width: auto;
  overflow-x: scroll;
  overflow-y: hidden;
  flex-wrap: nowrap;
  margin-bottom: 32px;
  &::-webkit-scrollbar-thumb {
    display: none;
    border-radius: 3px;
    background-color: #d8d8d8;
    @include media-breakpoint-only(lg) {
      display: flex;
    }
  }
  &::-webkit-scrollbar {
    display: none;
    height: 6px;
    border-radius: 8px;
    @include media-breakpoint-only(lg) {
      display: flex;
    }
  }
  li {
    margin-bottom: 0 !important;
    button {
      white-space: nowrap;
      font-family: "Montserrat", sans-serif;
      text-transform: none !important;
      text-shadow: none !important;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #202124;
      padding: 12px 8px 15px;
      border-bottom: 6px solid transparent !important;

      &.active {
        // border-radius: 2px !important;
        border-bottom: 6px solid #6e87ff !important;
        border-radius: 3px;
        color: #6e87ff !important;
        text-decoration: none;
        // box-shadow: none !important;

        // &:focus {
        //   box-shadow: none !important;
        // }
      }

      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        transition: 0.3s;
        color: #202124;
        text-decoration: none !important;
      }
    }
  }
}

.product-detail-container {
  width: 100%;
  font-family: "Montserrat", sans-serif;
  color: #484848;

  .product-detail-title {
    font-size: 24px;
    font-weight: bold;
  }

  .product-detail-name {
    font-size: 18px;
    font-weight: 500;
  }

  .product-detail-content {
    font-size: 14px;

    .product-detail-feature {
      margin: 0 !important;
    }

    // iframe {
    //   display: none;
    // }

    .embed-responsive {
      max-width: 640px;
    }
  }
}

.comments-rate-container {
  width: 100%;
  border-radius: 8px;
  display: flex;
  padding: 0 32px;
  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding: 0 16px;
  }

  .rate-box {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(lg) {
      flex-direction: row;
      margin-bottom: 16px;
    }
    @include media-breakpoint-down(xs) {
      flex-direction: column;
      margin-bottom: 24px;
    }
    .rate-box-container {
      margin: 0 24px 24px 0;
      .comment-rate-score {
        font-size: 56px;
        font-weight: normal;
        line-height: 1;
        color: #202124;
        margin-bottom: 0;
      }
      .rate-total-comment {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #202124;
      }
    }
    .comment-button {
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
  }

  .comment-detail-container {
    @include media-breakpoint-up(xl) {
      margin-left: auto;
    }

    .rateBoard {
      .rate-board-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          font-size: 12px;
          color: #202124;
          min-width: 50px;
          text-align: center;
        }
        .rate-bar-container {
          width: 280px;
          height: 12px;
          position: relative;
          background: #d8d8d8;
          border-radius: 12px;
          margin-left: 16px;

          .rateBar {
            position: absolute;
            left: 0;
            top: 0;
            width: 50%;
            height: 100%;
            background: #ffd063;
            border-radius: 12px;
          }
        }
      }
    }
  }

  .comment-detail-title {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #484848;
  }
}

.detail-title {
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #484848;
  margin-top: 32px;
  margin-bottom: 20px;
}

.playlist-container.active {
  @include media-breakpoint-down(md) {
    padding: 0 0 32px;
  }
}
.playlist-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  @include media-breakpoint-down(md) {
    padding: 0;
  }
  .playlist-text {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #202124;
    margin-bottom: 14px;
  }
  .playlist-title {
    font-size: 14px;
    font-weight: bold;
    color: #202124;
    margin-bottom: 14px;
  }
  .btn-playlist {
    border-radius: 8px;
    border: solid 1px #f0f0f0;
    font-size: 14px;
    font-weight: 600;
    margin-top: 14px;
    width: 280px;
    height: 48px;
    color: #ffffff;
    background: #6e87ff;
    padding: 8px 31px;
  }
  .toggle-title {
    display: none !important;
  }

  .custom-file-label {
    height: 48px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #919191;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -2rem !important;

    &::after {
      height: 100%;
      display: flex;
      align-items: center;
      content: "Bul";
    }
  }

  .playlist-custom-input {
    .input-group-text {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border: solid 1px #d8d8d8;
      background-color: #f4f4f4;
      height: 100%;
    }

    input {
      height: 48px !important;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border: solid 1px #d8d8d8 !important;
      background-color: #ffffff !important;
    }
  }
}

.playlist-video-card {
  box-shadow: none !important;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 6px;
  }

  .card-body {
    padding: 0;

    .btn {
      padding: 0;
      border: none;
      border-radius: 0;
      height: 192px;
    }
  }

  .card-footer {
    background: white;
    border: 0;
    padding: 0;
    padding-top: 1.5rem;

    h6 {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: bold;
      color: #484848;
      text-transform: capitalize;
    }

    .user-name {
      border-radius: 12px;
      background-color: rgba(0, 198, 140, 0.17);
      padding: 4px 10px;
      font-size: 14px;
      font-weight: 500;
      color: #484848;
      text-transform: capitalize;
      display: inline-block;
      margin-bottom: 4px;
    }

    p {
      font-family: "Montserrat", sans-serif;
      font-size: 13px;
      font-weight: 500;
      color: #919191;
    }
  }
}

.pagination {
  .page-link {
    &:hover {
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
    }

    // border: 0;
    // padding: 0;
    // font-size: 12px;
    // color: #d8d8d8;
    // margin-left: 2px;
    // margin-right: 2px;
  }

  .active .page-link {
    // color: #6e87ff;
  }
}

.thumbnails-container {
  .card {
    box-shadow: none;
    border-radius: 0;

    .btn {
      padding: 0;
    }
  }
}

.product-show-caption {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #484848;
  text-transform: capitalize;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .product-detail-card {
    .product-search-store {
      width: 75%;
    }
  }

  .playlist-video-card {
    box-shadow: none !important;
    padding: 0 12px;

    .card-body {
      padding: 0;

      .btn {
        padding: 0;
        border: none;
        border-radius: 0;
        height: 162px;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  // .product-image-gallery {
  //   .image-gallery-image {
  //     margin-bottom: 16px;
  //     min-height: 350px;
  //     max-height: 350px;

  //     img {
  //       min-height: 350px;
  //       max-height: 350px;
  //     }
  //   }
  // }

  .product-tag-item {
    flex: 1 !important;
  }

  .product-detail-card .product-search-store {
    width: 100%;
  }

  .image-gallery-thumbnails {
    display: flex;
    justify-content: center;
    .image-gallery-thumbnails-container {
      height: 24px;
      width: 30px;
      .image-gallery-thumbnail {
        width: auto !important;
        // padding: 0.5rem;
        height: auto;
        &.active {
          border: none;
        }
        &:first-of-type {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border: 6px #d8d8d8 solid;
            border-radius: 50%;
          }
          &.active::after {
            border-color: #f34854;
          }
        }
        &:last-of-type {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            border-style: solid;
            border-width: 6px 0px 6px 9px;
            border-color: transparent transparent transparent #e0e0e0;
            border-radius: 2px;
          }
          &.active::after {
            border-color: transparent transparent transparent #f34854;
          }
        }

        img {
          display: none;
        }

        .image-gallery-thumbnail-inner {
          display: none;
          border: none;
        }
      }
    }
  }

  .comment-detail-title {
    text-align: center;
  }

  .comment-toggle-detail {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.comment-button {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
  background-color: #6e87ff;
  height: 40px;
  width: 196px;
  &:hover {
    color: #ffffff;
  }
}

.thumbnails-container .gallery-container {
  // width: auto;
  // overflow-x: auto;
  // overflow-y: hidden;
  // flex-wrap: nowrap;

  .gallery-item {
    .product-show-caption {
      font-size: 14px;
      font-weight: bold;
      color: #484848;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.product-show-media {
  figure {
    width: 120px;
  }
}

.cargo-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  @include media-breakpoint-down(md) {
    align-items: start;
  }
  figure {
    margin-right: 4px;
  }
  .cargo-text {
    font-size: 14px;
    color: #202124;
    span {
      font-weight: 600;
    }
  }
}

.product-info-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: #f4f4f4 solid 1px;
  padding-top: 24px;
  .product-info-item {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 8px;
    figure {
      margin-right: 4px;
    }
    .product-info-body {
      display: flex;
      flex-direction: column;

      text {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #202124;
        margin: 0;
      }
      span {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #202124;
      }
    }
  }
}

.thumbnails-container {
  .gallery-item {
    @include media-breakpoint-down(md) {
      width: 50%;
      padding: 0 8px;
    }
  }
}

.product-detail--container {
  justify-content: space-between;
  .product-image-container {
    padding: 0;
    @include media-breakpoint-up(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 37.888%;
    }
  }
  .product-detail-box {
    @include media-breakpoint-up(md) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 0 60%;
      max-width: 60%;
    }
  }
}

.empty-sample-pages,
.empty-comment-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .first-title {
    font-size: 14px;
    line-height: 1.43;
    color: #202124;
    margin-bottom: 24px;
  }
  .second-title {
    font-size: 14px;
    line-height: 1.4;
    font-weight: bold;
    color: #202124;
    margin-bottom: 24px;
  }

  .btn-empty-sample-pages,
  .btn-empty-comment-page {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    background: #6e87ff;
    border: 1px solid transparent;

    margin-top: 24px;
    transition: 0.3s;
    &:hover {
      background: #5c76f8;
      transition: 0.3s;
    }
  }
  .btn-empty-sample-pages {
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }
  }
}
.playlist-video-title {
  font-size: 14px;
  margin-top: 18px;
  color: #202124;
  b {
    font-weight: bold;
  }
}

.main-comment {
  padding: 24px 0;
  margin: 0 !important;
  &:last-child {
    padding-bottom: 0;
  }
  ul {
    padding: 0;
  }
  .comment-item {
    padding-left: 40px !important;
    @include media-breakpoint-down(xs) {
      padding-left: 14px !important;
    }
  }
}

.empty-comment-page {
  padding: 0 29px;
  text-align: center;
}

.slider-section {
  padding: 52px 0 32px;
  background: #f4f4f4;
  .book-detail-container {
    margin: 0 12px;
    @include media-breakpoint-up(md) {
      margin: 0;
      padding: 0 6px 6px;
    }
    .book-detail-box {
      height: 331px;
      justify-content: start;
      padding: 5px;
      .book-detail-body {
        padding: 0 12px;
        div {
          text-align: center;
          height: 30px;
          overflow-y: hidden;
        }
      }
      // .img-box {
      //   height: 247px;
      //   .img-fluid {
      //     height: 100%;
      //   }
      // }
      .book-sub-detail {
        margin-top: 5px;
        justify-content: space-between;
        button {
          height: 28px;
          width: 95px;
          border-radius: 8px;
          background-color: #f34854;
          font-size: 12px;
          font-weight: bold;
          color: #ffffff;
          padding: 5px;
        }
      }
    }
  }

  .symbol-box {
    position: absolute;
    top: 50%;
    right: -18px;
    span {
      border-radius: 3px;
      height: 5px;
      width: 40px;
      background: #202124;
      position: absolute;
      &:first-child {
        top: -20px;
      }
    }
  }
}

.symbol-item {
  margin-right: 70px !important;
  width: 240px !important;
  position: relative;
}

.product-book-recommendation-container {
  border-radius: 15px;
  background: #ffffff;
  width: 100%;
  padding: 24px 16px 40px;
  margin-bottom: 32px;
  h2 {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: bold;
    color: #202124;
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }
  .recommendation-box {
    display: flex;
    margin-bottom: 24px;
    gap: 40px;
    @include media-breakpoint-up(lg) {
      margin-right: 48px;
      margin-bottom: 0;
    }

    .recommendation-item {
      display: flex;
      align-items: center;
      position: relative;
      svg {
        position: absolute;
        left: 105%;
        width: 24px;
        height: 24px;
        @include media-breakpoint-down(md) {
          margin: 0;
        }
      }
      &:last-child {
        svg {
          display: none;
        }
      }
      .img-box {
        height: 222px;
        width: 176px;
        @include media-breakpoint-down(sm) {
          height: auto;
          width: auto;
        }
        .img-fluid {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .price-container {
    @include media-breakpoint-only(md) {
      width: 410px;
    }
    .price-box {
      display: flex;
      margin-bottom: 24px;
      .total-price {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        font-weight: 600;
        color: #202124;
        margin-right: 16px;
        span {
          font-size: 20px;
        }
      }
    }
    .btn-book-recommendation {
      border-radius: 12px;
      background-color: #ff6a00;
      width: 252px;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
      @include media-breakpoint-down(xs) {
        height: 40px;
      }
    }
    .recommendation-box-details {
      .recommendation-item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin: 8px 0;
        padding-right: 15px;
        color: #202124;
        input {
          min-height: 20px;
          min-width: 20px;
          margin-right: 16px;
        }
        // div{
        //   margin-right: 16px;
        // }

        div {
          max-width: 400px;
        }
        span {
          margin-left: auto;
          font-weight: 600;
          width: 40px;
        }
      }
    }
  }
}

.product-detail-slider {
  .book-detail-body {
    text-align: start !important;
  }
}

.product-compare {
  border-radius: 16px;
  background-color: #ffffff;
  padding: 24px 16px;
  margin-bottom: 32px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #2a2a2a;
  }
  .product-compare-table {
    overflow-x: auto;
    .product-detail-item {
      .book-detail-container {
        margin: 0;
        .book-detail-box {
          height: auto;
          .img-box {
            @include media-breakpoint-down(xs) {
              height: 188px;
            }
            img {
              @include media-breakpoint-down(xs) {
                width: 100%;
              }
            }
          }
          .book-detail-body {
            .book-sub-detail {
              @include media-breakpoint-down(xs) {
                border-radius: 7px;
                border: solid 1px #c0c0c0;
              }
              display: flex;
              align-items: center;
              justify-content: space-around;
              span {
                display: flex;
                justify-content: center;
                font-size: 14px;
                font-weight: bold;
                @include media-breakpoint-down(xs) {
                  font-size: 11px;
                  color: #545454;
                }
              }
              button {
                width: auto;
                height: auto;

                border-radius: 8px;
                background-color: #f34854;
                @include media-breakpoint-down(xs) {
                  color: #545454;
                  border-radius: 0;
                  padding: 0 8px;
                  background: transparent;
                  font-size: 11px;
                  font-size: 12px;
                  border-left: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
  thead {
    ul {
      list-style: none;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 48px;
        width: 160px;
        margin-right: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
        color: #2a2a2a;
        @include media-breakpoint-down(xs) {
          width: 90px;
          font-size: 12px;
          padding: 8px 8px 8px 16px;
        }
      }
    }
    li:nth-child(odd) {
      background-color: #f4f4f4;
    }
  }
  tbody {
    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .product-detail-item {
      list-style: none;
      padding: 0;
      .table-detail-item {
        height: 48px;
        width: 247px;
        margin-right: 4px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        color: #2a2a2a;
        @include media-breakpoint-down(xs) {
          width: 174px;
          font-size: 12px;
          font-weight: 600;
        }
      }
    }
    li:nth-child(even) {
      background-color: #f4f4f4;
    }
    ul:last-child {
      li {
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}

.product-discount-container {
  border-radius: 16px;
  padding-top: 24px;
  background: #ffffff;
  h2 {
    font-size: 24px;
    font-weight: bold;
    color: #2a2a2a;
    margin: 0 16px;
    @include media-breakpoint-down(xs) {
      font-size: 20px;
    }
  }

  .product-discount-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    img {
      margin: 0;
    }
    .detail-box {
      display: flex;
      flex-direction: column;
      .detail-textarea {
        margin-bottom: 16px;
        @include media-breakpoint-down(xs) {
          margin-bottom: 0;
        }

        h5 {
          font-size: 20px;
          font-weight: 600;
          color: #2a2a2a;
          @include media-breakpoint-down(xs) {
            font-size: 16px;
          }
        }
        span {
          font-size: 16px;
          font-weight: 600;
          color: #545454;
          @include media-breakpoint-down(xs) {
            font-size: 14px;
          }
        }
      }

      .btn {
        width: 119px;
        height: 40px;
        border-radius: 10px;
        background: #6e87ff;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.43;
        color: #ffffff;
        display: flex;
        align-items: center;
      }
    }
  }
}

.detail-box-mobile {
  display: none;
  height: 64px;
  position: fixed;
  bottom: -1px;
  z-index: 1;
  background-color: #ffffff;

  span {
    font-size: 16px;
    font-weight: bold;
  }
  .product-favourite {
    width: 54px;
    height: 48px;
    border: solid 2px #e5e5e5;
    border-radius: 12px;
    margin-right: 12px;
  }
  .add-basket-button {
    width: 188px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    figure {
      margin-right: 8px;
    }
  }
}

.sticky-product-navbar {
  width: 100%;
  z-index: 99999;
  height: 96px;
  position: fixed;
  opacity: 0;
  top: -96px;
  transition: 0.5s;
  background: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(228, 228, 228, 0.5);

  .detail-box {
    text {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.8;
      color: #202124;
      margin: 0;
    }
    span {
      font-size: 24px;
      font-weight: bold;
      color: #202124;
    }
  }
  .add-basket-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 380px;
    padding: 10px;
    border-radius: 8px;
    background-color: #ff6a00;
    text-transform: none;
    font-size: 16px;
    font-weight: bold;
    line-height: 1 !important;
    text-align: center;
    color: #ffffff;
    @include media-breakpoint-down(md) {
      width: 220px;
    }

    figure {
      margin-right: 12px;
    }
  }
}
.sticky-product-navbar.show {
  display: block;
  opacity: 1;
  transition: 0.5s;
  top: 0;
}
.product-badge {
  height: 30px;
  padding: 4px 8px;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.22px;
  border-radius: 7px;
  border: solid 2px #ffffff;
  z-index: 1;

  @include media-breakpoint-only(xs) {
    left: 9%;
    top: -15px;
  }
  @include media-breakpoint-only(sm) {
    left: -6px;
    top: -15px;
  }
  @include media-breakpoint-only(md) {
    left: 80px;
    top: 15px;
  }
}
.product-features {
  display: flex;
  position: absolute;
  top: 8px;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
}
.badge-super-price {
  color: #d0000f;
  background-color: #fde5e7;
}
.badge-book-week {
  color: #00167e;
  background-color: #edeeff;
}
.badge-speed-camp {
  color: #895700;
  background-color: #ffe9ca;
}
.badge-chance-set {
  color: #154a00;
  background-color: #e6ffdc;
}
.badge-indispensable {
  color: #7a004a;
  background-color: #ffcbf2;
}
.badge-lgs-indispensable {
  color: #005258;
  background-color: #cbfcff;
}
.badge-product-indispensable {
  color: #005258;
  background-color: #cbfcff;
}
.badge-new-released {
  color: #003929;
  background-color: #eefffa;
}

@media (max-width: 480px) {
  .product-slider {
    h2 {
      font-size: 20px;
    }
  }
  .slider-section {
    .book-detail-container {
      padding: 0 2px;
      .book-detail-box {
        height: auto !important;
        width: auto !important;
        min-width: auto !important;
      }
    }
  }

  .price-box {
    justify-content: space-between;
    .total-price {
      .total-price-text {
        font-size: 14px !important;
      }
    }
    .btn-book-recommendation {
      width: 138px !important;
    }
  }

  .product-detail-slide {
    padding: 0 !important;
  }
  .playlist--container {
    padding: 12px 12px;
    .playlist-container {
      .btn-playlist {
        padding: 0;
        width: 280px;
      }
    }
  }

  .playlist-video-card {
    padding: 0;
  }
  .empty-comment-page {
    padding: 0 16px 24px !important;
    .first-title {
      margin-bottom: 16px !important;
    }
  }
  .main-comment {
    padding: 8px 0 !important;
  }
  .comment {
    font-size: 14px !important;
  }
  .comment-user-name {
    font-size: 14px !important;
  }
  .rate-box-container {
    margin-right: 8px !important;
    .comment-rate-score {
      font-size: 48px !important;
    }
  }

  .difficulty-level-container {
    .difficulty-level-item:nth-child(1) {
      min-width: 111px !important;
      .difficulty-level {
        font-size: 12px !important;
        justify-content: start !important;
      }
    }
    // .difficulty-level-item {
    //   width: 100%!important;
    // }
  }

  .product-detail-box {
    .product-order-detail {
      display: none;
    }
  }

  .product-badge {
    left: 6%;
  }
  .detail-box-mobile {
    display: flex;
  }
  .product-detail-card-header {
    padding: 16px !important;
    .product-detail-card-title {
      font-size: 18px !important;
    }
    .product-detail-price {
      font-size: 24px !important;
    }
  }
}
@media (max-width: 430px) {
  .product-badge {
    left: 1%;
  }
  .recommendation-item {
    // .img-box {
    //   width: 74px !important;
    // }
    svg {
      margin: 0 3px !important;
      width: 16px;
      height: 16px;
    }
  }
}
@media (max-width: 380px) {
  .comments-rate-container {
    padding: 0 10px;
  }

  .rate-box-container {
    margin-right: 4px !important;
  }
  .product-badge {
    left: -8px;
  }
  .detail-box-mobile {
    .product-favourite {
      width: 50px;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .add-basket-button {
      width: 162px;
    }
  }
  .difficulty-level-container {
    .difficulty-level-item:nth-child(1) {
      min-width: 80px !important;
    }
  }
}

.product-accordion {
  .accordion-item {
    margin: 8px 0;
    border-radius: 15px;
    border: solid 1px #f4f4f4;
    padding: 0;
    .accordion-body {
      .product-detail-container {
        padding: 0 16px;
      }
    }
    .accordion-head {
      height: 56px;
      padding: 20px 16px;
      .accordion-icon {
        transform: rotate(90deg);
      }
      &.show {
        h6 {
          color: #6e87ff;
        }
        .accordion-icon {
          transform: rotate(-90deg);
        }
      }
      h6 {
        color: #202124;
      }
      svg {
        right: 16px !important;
      }
    }
    .media {
      padding: 0 16px;
    }
  }
}

.commentModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .modal-box {
    background: #ffffff;
    position: absolute;
    width: 539px;
    height: 100vh;
    right: 0;
    z-index: 1041;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    @include media-breakpoint-down(xs) {
      width: 100%;
      height: auto;
      bottom: 0;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      padding-bottom: 31px;
    }
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: start;
      border-bottom: solid 1px #dedede !important;
      @include media-breakpoint-down(xs) {
        border: 0 !important;
      }
      span {
        @include media-breakpoint-down(xs) {
          width: 55px;
          height: 4px;
          position: absolute;
          left: 44%;
          top: 8px;
          background: #d8d8d8;
          border-radius: 2px;
        }
      }
      .close-btn {
        @include media-breakpoint-down(xs) {
          position: absolute;
          right: 15px;
        }
      }
      h5 {
        margin: 0;
        margin-left: 16px;
        font-size: 24px;
        font-weight: 600;
        color: #202124;
        @include media-breakpoint-down(xs) {
          font-size: 16px;
          margin: 0;
        }
      }
      svg.icon {
        height: 24px;
        width: 24px;
        g {
          fill: #202124;
        }
      }
    }
    .modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 24px 99px;
      @include media-breakpoint-down(xs) {
        padding: 0 40px;
      }
      textarea {
        border-radius: 8px;
        border: solid 1px #727272;
        background: transparent;
        &::placeholder {
          font-size: 12px;
        }
      }
      form {
        .form-group {
          display: flex;
          flex-direction: column;
          align-items: center;
          textarea {
            border-radius: 8px;
            border: solid 1px #727272;
            background: transparent;
            &::placeholder {
              font-size: 12px;
            }
          }
          h6 {
            font-size: 16px;
            font-weight: bold;
            color: #202124;
            @include media-breakpoint-down(xs) {
              font-size: 14px;
            }
          }
          p {
            font-size: 16px;
            font-weight: bold;
            color: #202124;
            padding: 20px 0 0;
            text-align: center;
            @include media-breakpoint-down(xs) {
              font-size: 14px;
            }
          }
          .rating-component {
            display: flex;
            justify-content: center;
            margin: 0;
            svg {
              height: 34px;
              width: 34px !important;
              @include media-breakpoint-down(xs) {
                height: 27px;
                width: 27px !important;
              }
            }
          }
        }
      }
      .comment-button {
        width: 100%;
        font-size: 14px;
        @include media-breakpoint-down(xs) {
          margin-top: 16px;
          height: 48px;
        }
      }
      .img-container {
        width: 172px;
        height: 215px;
        @include media-breakpoint-down(xs) {
          height: 130px;
          width: 104px;
        }
      }
      .info-message {
        display: flex;
        margin-top: 20px;
        figure {
          margin-right: 10px;
          display: flex;
          align-items: center;
          svg {
            width: 26px;
            height: 26px;
          }
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #545454;
          margin: 0;
          @include media-breakpoint-down(xs) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.questions-container {
  .send-question {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    @include media-breakpoint-down(xs) {
      padding: 0 16px;
      text-align: center;
    }
  }
  h6 {
    margin: 0;
    @include media-breakpoint-down(xs) {
      margin-bottom: 8px;
    }
  }
  .main-question {
    padding: 16px 0;
    .default-avatar {
      display: block;
      height: 47px;
      width: 47px;
      background: transparent;
    }
    .comment-box {
      background: #f4f4f4;
      padding: 8px;
      border-radius: 8px;
      position: relative;
      &::before {
        position: absolute;
        right: 100%;
        top: 30%;
        content: "";
        width: 0;
        height: 0;
        border-width: 15px 15px 15px 0;
        border-color: transparent #f4f4f4 transparent transparent;
        border-style: solid;
        @include media-breakpoint-down(xs) {
          top: 20%;
          border-width: 12px 12px 12px 0;
        }
      }
      .comment {
        margin: 0;
      }
    }
  }
}

.commentModal.questionModal {
  .modal-box {
    @include media-breakpoint-down(xs) {
      height: 600px;
    }
  }
}

.product-detail--slider {
  h2 {
    padding: 24px 12px 12px;
  }
  .slick-slider {
    padding: 0 0 24px;

    .book-detail-box {
      border: solid 1px #f4f4f4;
    }
  }
}
@media (max-width: 575.98px) {
  .shopping-body
    .shopping-slider
    .slick-slider
    .book-detail-container
    .book-detail-box
    .book-detail-body
    .book-sub-detail {
    border-radius: 8px;
    border: 0px solid silver;
    display: block !important;
    height: auto;
  }

  .shopping-body
    .shopping-slider
    .slick-slider
    .book-detail-container
    .book-detail-box
    .book-detail-body
    .book-sub-detail
    button {
    background: #f34854 !important;
    color: #fff;
    padding: 4px 8px;
    transition: all 0.1s linear;
    border-radius: 8px;
    width: 100%;
  }

  .shopping-body
    .shopping-slider
    .slick-slider
    .book-detail-container
    .book-detail-box
    .book-detail-body
    .book-sub-detail
    span {
    text-align: left;
    justify-content: flex-start !important;
    font-size: 16px !important;
    margin-bottom: 10px;
    display: block;
  }

  .book-detail-container .book-detail-box {
    height: auto;
  }
}
.slider-section .book-detail-container .book-detail-box .book-detail-body div,
.book-detail-container .book-detail-box .book-detail-body div {
  text-align: left;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .product-book-recommendation-container .d-flex.flex-column.flex-lg-row {
    flex-wrap: wrap;
    justify-content: center;
  }

  .product-book-recommendation-container .recommendation-box {
    margin-right: 0;
  }

  .product-book-recommendation-container .price-container {
    margin-top: 20px;
  }

  .product-book-recommendation-container h2 {
    text-align: center;
  }
}
