@media (min-width: 576px) {
    .custom-modal-dialog .modal-dialog {
        max-width: 1200px;
    }
}

#taksitSecenekleriModal {
    font-family: Gilroy;
}

    #taksitSecenekleriModal .modal-title {
        font-size: 24px;
        font-weight: 600;
    }

    #taksitSecenekleriModal .taksitSecenekleriList .containerPayTitle {
        padding: 0;
    }

    #taksitSecenekleriModal table,
    #taksitSecenekleriModal tr,
    #taksitSecenekleriModal td {
        border: 1px solid #ccc !important;
        border-width: 1px;
        padding: 3px;
        text-align: center;
    }

    #taksitSecenekleriModal table {
        width: 100%;
        margin-bottom: 20px;
    }

.bank1 {
    font-size: 22px;
    font-weight: 600;
}


#taksitSecenekleriModalBody {
    display: grid;
    grid-template-columns: 1fr; /* Mobilde her satırda bir eleman */
    gap: 20px;
    padding: 10px;
}

@media (min-width: 768px) {
    #taksitSecenekleriModalBody {
        grid-template-columns: repeat(2, 1fr); /* Tabletlerde 2 sütun */
    }
}

@media (min-width: 992px) {
    #taksitSecenekleriModalBody {
        grid-template-columns: repeat(4, 1fr); /* Monitörlerde 4 sütun */
    }
}

.taksitSecenekleriList {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}





    /* Banka adı düzenlemesi */
    .bank1 {
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
        font-size: 1.1rem;
        color: #333;
    }

    /* Tablo düzenlemesi */
    table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
    }

        table td, table th {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: center;
            font-size: 0.7rem;
        }

        table tr:nth-child(even) {
            background-color: #f2f2f2;
        }

    @media (min-width: 992px) {
        .modal-lg {
            max-width: 1200px;
        }
    }

    .bank-logo {
        width: 60%;
    }


      @media only screen and (max-width: 575px) {
        .modal .modal-dialog .modal-close-button {
            top: 0px !important;
            left: 90% !important;
            transform: translateX(-10%) !important;
        }
        .custom-modal-dialog {
            z-index: 100000 !important;
        }
        
    }
