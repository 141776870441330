// Override default variables before the import
$body-bg: #fff;

.answers-custom-checkbox {
  .radio-checkmark {
    // width: 20px;
    // height: 20px;

    &:after {
      // top: -1px !important;
      // left: -1px !important;
      // width: 20px !important;
      // height: 20px !important;
    }
  }

  &.success-check {
    .radio-checkmark {
      background: #3cd347;
      font-size: 14px;
      text-align: center;

      svg {
        vertical-align: inherit;
      }
    }
  }

  &.success-error {
    .radio-checkmark {
      background: #d23f3f;
      font-size: 14px;
      text-align: center;

      svg {
        vertical-align: inherit;
      }
    }
  }

  &.summary-checked {
    .radio-checkmark {
      &:after {
        background: var(--dark);
      }
    }
  }
}

.summary-table {
  border-collapse: separate !important;
  border-spacing: 0 1px !important;
  margin-bottom: 0;
  thead {
    th {
      font-weight: 600;
    }
  }
  tbody {
    tr {
      &:first-child {
        td {
          &:first-child {
            border-top-left-radius: 8px !important;
          }
          &:last-child {
            border-top-right-radius: 8px !important;
          }
        }
      }
      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 8px !important;
          }
          &:last-child {
            border-bottom-right-radius: 8px !important;
          }
        }
      }
    }
    td {
      padding: 0.75rem !important;
      .radio-checkmark {
        border-color: #c7c7c7;
        &::after {
          border-radius: 50%;
        }
      }
    }
  }
}

@media (max-width: 568px) {
  .summary-table {
    thead {
      th {
        font-size: 14px;
        padding: 0.2rem 0.2rem !important;
      }
    }

    tbody {
      td {
        padding: 0.2rem 0.2rem !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .pomodoro-mobile-container {
    position: absolute;
    left: -5px;
  }
  .summary-table {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.digital-question-container {
  width: 100%;
  height: 48px;
  margin: 17px 0 16px;
  padding: 0 76px 0 0;
  background-color: #eff1ff;
}
.digital-question-number-container {
  height: 48px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-image: linear-gradient(135deg, #bdc8ff -29%, #6e87ff);
  text-align: center;
}
.digital-question-number {
  height: 29px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  display: block;
}

.digital-question-number-text {
  width: 57px;
  height: 11px;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: grid;
}
.digital-question-btn {
  background: #6e87ff;
  color: white !important;
  border-color: #6e87ff;
  border-radius: 16px;
  margin: 10px 0 0 -30px;
  width: 165px;
}
.digital-question-btn-text {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.digital-question-btn :hover {
  background: #6e87ff;
  color: white !important;
  border-color: #6e87ff;
}
.digital-question-btn :focus {
  background: #6e87ff;
  color: white !important;
  border-color: #6e87ff;
}
.digital-question-btn :active {
  background: #6e87ff;
  color: white !important;
  border-color: #6e87ff;
}

.answers-evaluate-btn {
  width: 273px;
  position: relative;
  border: 2px solid #484848;
  border-radius: 4px;
  @include media-breakpoint-down(md) {
    margin-right: 4px;
  }
}
.answers-online-btn {
  border-radius: 8px;
}

@media (max-width: 560px) {
  .answers-evaluate-btn {
    width: 48%;
    left: 0;
  }

  .answers-online-btn {
    width: 48%;
  }
}

.summary-border {
  border-left: 1px solid #dedede;
}

.timer-card {
  background: #f4f4f4;
  border-radius: 6px;
  border: 1px solid #cecece;
  padding: 20px;
  width: 525px;
  max-width: 525px;
  position: absolute;
  right: 0;
}

.timer-card-desk {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 525px;
  height: 300px;
  padding: 5px;
}

.absolute-row {
  min-height: 300px;
}

@media (max-width: 1200px) {
  .activities {
    .last-activity {
      margin-top: 1.5rem !important;
      margin-bottom: 8px !important;
    }
  }
}

@media (max-width: 768px) {
  .summary-border {
    border-left: 0;
    border-top: 1px solid #dedede;
  }

  .timer-card {
    max-width: 100% !important;
    width: 100% !important;
  }

  .absolute-row {
    min-height: 450px !important;
  }
}

.activities {
  .card-metric {
    width: 100% !important;
    min-width: 100% !important;
  }

  .all-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .list-group-item {
    padding-bottom: 6px;
  }

  .img-badge {
    display: inline-block !important;

    span {
      font-size: 12px;
    }
  }
}
.lesson-list-group.card {
  @include media-breakpoint-up(sm) {
    box-shadow: none;
  }
}
.lesson-list-group {
  @include media-breakpoint-down(xs) {
    box-shadow: none !important;
    row-gap: 16px;
  }
  .list-group-item {
    border: 0;
    border-bottom: 1px solid #e1e1e1;
    padding: 16px 0;
    @include media-breakpoint-down(xs) {
      box-shadow: none !important;
      border: solid 1px #dedede;
      border-radius: 16px !important;
    }
    // .btn{
    //   @include media-breakpoint-down(xs) {
    //     width: 100%;
    //   }
    // }
    .list-group {
      .list-group-item {
        border: 0 !important;
        border-bottom: 1px solid #e1e1e1 !important;
        border-radius: 0 !important;
        padding: 0;
      }
    }
    .lesson-img-wrapper {
      min-height: 56px;
      height: 56px;
      min-width: 56px;
      width: 56px;
      margin-right: 16px;
    }
    .lesson-inner {
      @include media-breakpoint-down(xs) {
        width: 100%;
      }
      .more-lesson {
        display: flex;
        align-items: center;
        color: #ff6a00;
        span {
          font-size: 14px;
          font-weight: 600;
          margin-right: 4px;
        }
      }
    }
    &:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
    .list-group {
      .list-group-item {
        &:last-child {
          border-bottom: 0 !important;
        }
      }
    }
  }
}
.quiz-list-item {
  border: none !important;
  padding: 16px 0 !important;
  margin-left: 80px;
  border-top: solid 1px rgba(225, 225, 225, 0.37) !important;
  @include media-breakpoint-down(md) {
    border-bottom: 1px solid #e1e1e1 !important;
    // margin-bottom: 16px;
    margin-left: 0;
    flex-direction: column;
  }
  .btn-watch {
    background: #ffeaea !important;
    color: #aa040f !important;
    height: 36px;
    border-radius: 8px;
    max-width: 135px;
    padding: 0 8px;
  }
  .badge {
    @include media-breakpoint-down(xs) {
      padding: 8px !important;
    }
  }
  .btn-quiz {
    background: #f4f4f4;
    border-radius: 50%;
    padding: 0;
    height: 40px;
    min-width: 40px;
  }
  .quiz-inner {
    display: flex;
    align-items: center;
    .quiz-count {
      position: absolute;
      right: 10px;
      bottom: 16px;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
    }
    .quiz-img-wrapper {
      border-radius: 50%;
      border: solid 6px;
      padding: 6px;
      position: relative;
      margin-right: 16px;

      img {
        height: 48px;
        width: 48px;
      }
      .success-emoji-wrapper {
        position: absolute;
        right: -24px;
        bottom: -18px;
        padding: 5px;
        background: #fff;
        border-radius: 50%;
        img {
          height: 32px;
          width: 32px;
        }
      }
    }
    .quiz-content {
      display: flex;
      flex-direction: column;
      span:nth-child(1) {
        font-size: 16px;
        font-weight: 500;
        color: #202124;
      }
      span:nth-child(2) {
        font-size: 12px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  a.btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.wh-36 {
  width: 36px !important;
  height: 36px !important;
}

.wh-40 {
  width: 40px !important;
  height: 40px !important;
}
.wh-60 {
  width: 60px !important;
  height: 48px !important;
}
.borrad-30 {
  width: 76px !important;
  height: 40px !important;
  padding: 2px 0 0px !important;
  border-radius: 20px !important;
}
.lesson-form {
  height: 64px !important;
  width: 392px;
  padding: 8px 8px 8px 16px;
  position: relative;
  justify-content: space-between;
  border: 0 !important;
  label {
    font-size: 14px;
    font-weight: 600;
    color: #202124;
    margin-bottom: 0;
  }
  #test-code {
    height: 24px;
    padding: 0;
  }
  input::placeholder {
    font-size: 12px;
    font-weight: 500;
    color: #545454;
    @include media-breakpoint-down(xs) {
      font-size: 10px;
    }
  }
  .btn-lesson-submit {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
    width: 69px !important;
    img {
      width: 10px;
      height: 11px;
    }
  }
}
.lesson-book-wrapper {
  background: #f4f4f4;
  border-radius: 8px;
  .card {
    background: #f4f4f4 !important;
    border-bottom: solid 1px #e5e5e5 !important;
  }

  .radial-inner {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #202124;
    display: flex;
    flex-direction: column;

    span:nth-child(1) {
      font-size: 16px;
      font-weight: normal;
    }
    span:nth-child(2) {
      font-size: 20px;
      font-weight: bold;

      text-align: center;
    }
  }
  .book-stats-wrapper {
    .book-stats-top {
      display: flex;
      justify-content: center;
      gap: 32px;
      padding-bottom: 24px;
      .total-true-wrapper,
      .total-wrong-wrapper,
      .total-empty-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          margin: 8px 0 0;
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          color: #727272;
        }
        span:last-child {
          font-size: 24px;
          text-align: center;
          color: #202124;
        }
      }
      .total-true-wrapper {
        div {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #46ff54;
        }
      }
      .total-wrong-wrapper {
        div {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #f34f4f;
        }
      }
      .total-empty-wrapper {
        div {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background-color: #c2c2c2;
        }
      }
    }
    .book-stats-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 0;
      margin: 0 16px;
      border-top: solid 1px #e5e5e5;
      @include media-breakpoint-down(sm) {
        margin: 0;
        padding: 24px;
      }
      div {
        font-size: 24px;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 2.4px;
        color: #202124;
      }
      span {
        font-size: 16px;
        font-weight: 600;
        color: #202124;
      }
    }
  }
}
@media (max-width: 400px) {
  .quiz-list-item {
    a.btn {
      width: 100%;
      height: 32px;
      padding: 3px;
      font-size: 12px !important;
      font-weight: 500;
    }
  }
}

@media (max-width: 560px) {
  .activity-max-height {
    max-height: 275px !important;
  }

  .activities {
    .mobile-fix {
      width: 100% !important;
    }
  }
}

@media (max-width: 350px) {
  .activities {
    .mobile-fix {
      width: auto !important;
    }
  }
  .answers-stats-container {
    .badge {
      width: 100% !important;
    }
  }
}

.quiz-page,
.play-page {
  .card {
    box-shadow: 0 0 12px 0 #dfdfdf;
    border-radius: 8px;
    @include media-breakpoint-down(xs) {
      box-shadow: none;
    }
    .quiz-time-border {
      @include media-breakpoint-down(xs) {
        border-top: 1px solid #e1e1e1 !important;
        border-bottom: 1px solid #e1e1e1 !important;
        margin-bottom: 16px;
      }
    }
    .quiz-time-container {
      padding: 16px 8px;
      display: flex;
      flex-direction: column;
      @include media-breakpoint-down(xs) {
        padding: 16px 0;
        flex-direction: row;
        justify-content: space-between;
      }
      .rating-container {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #484848;
        figure {
          svg {
            height: 16px;
          }
        }
        span {
          width: 2px;
          height: 14px;
          border-radius: 1px;
          background: #d4d4d4;
          margin: 0 16px;
        }
      }
      .questions-list {
        @include media-breakpoint-down(md) {
          overflow-x: auto;
          flex-wrap: nowrap;
          display: flex;
        }
      }
      .time-box {
        width: 115px;
        min-width: 89px;
        height: 40px;
        border-radius: 6px;
        background: #f4f4f4;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(xs) {
          width: auto;
        }
      }
    }
  }
  h1 {
    @include media-breakpoint-down(xs) {
      font-size: 16px !important;
      font-weight: bold;
    }
    .quiz-clock {
      span {
        font-size: 26px;
        @include media-breakpoint-down(xs) {
          font-size: 16 !important;
        }
      }
    }
  }
  .answers-button,
  .question-count {
    min-height: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    width: 40px !important;
    @include media-breakpoint-down(xs) {
      font-size: 16px !important;
    }
  }
  .question-count.btn-acik-uclu {
    width: 75px !important;
    min-width: 75px !important;
    border-radius: 24px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: 1.1;
    @include media-breakpoint-down(xs) {
      line-height: 1.3;
    }
    span {
      font-size: 10px;
    }
  }
  .answers-button {
    border: solid 1px #c7c7c7 !important;
    box-shadow: none !important;
  }
  .btn-success {
    color: white;
  }

  .selected-btn {
    background: #484848;
    color: white;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 72, 72, 0.3);
    }
  }

  .btn-default {
    box-shadow: 0 0 11px 0 rgba(153, 145, 145, 0.5);
    color: #919191;
  }
  .arrow-btn {
    background: #ffffff !important;
    figure {
      svg {
        @include media-breakpoint-down(xs) {
          width: 16px;
          height: 16px;
        }
        g {
          @include media-breakpoint-down(xs) {
            fill: #6e87ff;
          }
        }
      }
    }
  }

  .btn-danger {
    background: #b20202;
    border-color: #b20202;
    color: white;
  }

  .btn-active-blue {
    background: #6e87ff;
    color: white;
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(110, 134, 255, 0.3);
    }
  }

  .btn-not-active-blue {
    background: #e9edff;
    color: #6e87ff;
  }
}

@media (max-width: 768px) {
  .profile-chart {
    max-width: 180px;
  }
}

.activity-max-height {
  max-height: 185px;
  overflow: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    overflow: hidden;
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
  }
}

.activity-max2-height {
  // max-height: 370px;
  // overflow: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    overflow: hidden;
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
  }
}

.btn.rounded-circle {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;

  &.small {
    width: 32px;
    height: 32px;
    padding: 0;

    svg {
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}

.wh-36 {
  width: 36px !important;
  height: 36px !important;
}

.wh-40 {
  width: 40px !important;
  height: 40px !important;
}

.btn-hover-white {
  &:hover {
    color: $white;
  }
}

.question-img {
  padding-right: 10%;
}

.demo-banner {
  color: #fff;
  flex-direction: row !important;
  background: rgb(94, 76, 255);
  background: linear-gradient(
    90deg,
    rgba(94, 76, 255, 1) 40%,
    rgba(166, 104, 255, 1) 70%
  );

  // background-image: url('../images/images/demo_banner.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
}

.demo-banner h1 {
  font-weight: 600;
  line-height: 1.5rem;
}

.demo-banner-p {
  font-size: 13px;
  font-weight: 400;

  // line-height: 1.5em;
  // height: 3em;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // width: 80%;
}

.demo-banner-pattern {
  position: absolute;
  margin-left: 42%;
  margin-top: -120px;
  display: flex;
}

.demo-banner-btn {
  font-weight: 500;
  font-size: 18px;
  width: 250px;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .demo-banner {
    background: linear-gradient(
      155deg,
      rgba(94, 76, 255, 1) 40%,
      rgba(166, 104, 255, 1) 70%
    );
  }

  .demo-banner-pattern {
    position: absolute;
    margin-left: 100px;
    margin-top: 50px;
    max-width: 100%;
  }

  .demo-banner h1 {
    font-weight: 600;
    font-size: 1rem;
  }
}

@media (max-width: 568px) {
  .demo-banner {
    background: linear-gradient(
      155deg,
      rgba(94, 76, 255, 1) 40%,
      rgba(166, 104, 255, 1) 70%
    );
  }

  .demo-banner-pattern {
    position: absolute;
    margin-left: 0px;
    margin-top: 50px;
    max-width: 100%;
  }

  .demo-banner h1 {
    font-weight: 600;
    font-size: 1rem;
  }

  .demo-banner-p {
    font-size: 12px;
  }
}
.v-wrapper {
  padding: 56.25% 0 0 0;
  position: relative;
  .v-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// .videojs-wrapper{
//   width: 100%;
//   > div {
//     width: 100%;
//   }
//   .vjs-big-play-button {
//     top: 50%;
//     left: 50%;
//     margin-top: -.81666em;
//     margin-left: -1.5em;
//   }
// }

.answers-table {
  thead {
    th {
      font-weight: 600;
      color: #484848;
      @include media-breakpoint-down(xs) {
        padding-bottom: 0 !important;
      }
    }
  }
  th,
  td {
    padding: 1rem 0.5rem;
  }
  tr:nth-child(even) {
    background: rgba(244, 244, 244, 0.47);
  }
  td:first-child {
    @include media-breakpoint-down(xs) {
      padding-right: 20px !important;
    }
  }
  td:last-child {
    padding-left: 50px;
    @include media-breakpoint-down(xs) {
      padding-left: 1rem;
    }
  }
  .radio-checkmark {
    border-color: #c7c7c7;
  }
}

.answers-container {
  width: 75%;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  h5 {
    font-size: 20px;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  button {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

.pomodoro-mobile {
  span.text-danger {
    font-size: 46px;
    @include media-breakpoint-down(xs) {
      font-size: 29px;
    }
  }
  span.left-time {
    @include media-breakpoint-down(xs) {
      font-size: 12px !important;
    }
  }
  p {
    @include media-breakpoint-down(xs) {
      font-size: 9px !important;
    }
  }
  button {
    @include media-breakpoint-down(xs) {
      padding: 0;
      font-size: 12px !important;
      border-radius: 5px !important;
    }
  }
}

.answers-stats-container {
  h5 {
    @include media-breakpoint-down(xs) {
      font-size: 14px !important;
    }
  }
  .stats-item {
    font-size: 12px !important;
  }
}
.summary-branch-stats {
  @include media-breakpoint-down(xs) {
    padding-top: 16px !important;
    font-weight: 500 !important;
  }
  h5 {
    @include media-breakpoint-down(xs) {
      font-size: 16px !important;
    }
  }
}

.answer-keys {
  h5 {
    font-size: 20px;
    font-weight: 600;
    @include media-breakpoint-down(xs) {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .radio-container {
    .radio-checkmark {
      width: 20px;
      height: 20px;
      &::after {
        top: -1px;
        left: -1px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.activities-card {
  .card-header {
    background: transparent;
    border: 0;
    h5 {
      @include media-breakpoint-down(xs) {
        font-size: 16px !important;
        font-weight: bold !important;
        margin: 0;
      }
    }
  }
  .card-body {
    .activities-date {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
        font-weight: 500 !important;
      }
    }
    .activities-text {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
        font-weight: 600 !important;
        color: #484848 !important;
      }
    }
    .list-group {
      .list-group-item {
        @include media-breakpoint-down(xs) {
          border-bottom: 1px solid #dedede !important;
        }
      }
    }
  }
}

.proggress-card {
  .card-header {
    .card-title {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
        font-weight: bold !important;
      }
    }
    .card-icon {
      img {
        @include media-breakpoint-down(xs) {
          height: 32px !important;
          width: 32px !important;
        }
      }
    }
  }
  .card-body {
    .badge {
      @include media-breakpoint-down(xs) {
        width: 130px !important;
      }
    }
  }
}

.chapter-lesson-item {
  .card-body {
    @include media-breakpoint-down(xs) {
      padding: 16px !important;
    }
    h5.card-title {
      @include media-breakpoint-down(xs) {
        font-size: 14px !important;
        font-weight: bold !important;
      }
    }
    .card-text {
      @include media-breakpoint-down(xs) {
        font-size: 16px !important;
        font-weight: bold !important;
      }
    }
  }
}

.dislikeModal {
  .modal-dialog {
    @include media-breakpoint-down(xs) {
      height: 100vh;
    }
    .modal-content {
      height: auto;
      border-radius: 12px !important;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      span.line {
        display: none;
        width: 55px;
        height: 4px;
        position: absolute;
        left: 44%;
        top: 8px;
        background: #d8d8d8;
        border-radius: 2px;
        @include media-breakpoint-down(xs) {
          display: flex;
        }
      }
      .modal-close-button {
        top: -20px !important;
        right: -50px !important;
        @include media-breakpoint-down(xs) {
          right: 0 !important;
          top: 0 !important;
          left: auto;
        }
        svg {
          width: 24px;
          height: 24px;
          path {
            @include media-breakpoint-down(xs) {
              fill: #202124;
            }
          }
        }
      }
      .modal-body {
        padding: 32px 32px 45px;
        @include media-breakpoint-down(xs) {
          padding: 64px 16px 32px;
        }
        .btn-cancel {
          box-shadow: 0 0 2px 0 #afafaf;
          padding: 0 42px;
        }
        .btn {
          height: 40px;
          @include media-breakpoint-down(xs) {
            width: 100%;
            height: 48px;
          }
        }
        .dislike-comment {
          margin: 50px 0 32px;
          padding: 0;
          @include media-breakpoint-down(xs) {
            margin: 32px 0;
          }
          li {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            font-size: 14px;
            font-weight: 500;
            color: #484848;
            &:last-child {
              margin-bottom: 0;
            }
            input {
              width: 18px;
              height: 18px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .answers-table {
    th,
    td {
      padding: 0.5rem;
    }
  }
}

.resetQuizModal {
  .modal-dialog {
    max-width: 422px !important;
    @include media-breakpoint-down(xs) {
      height: 100vh;
    }
    .modal-content {
      height: auto;
      border-radius: 12px !important;
      @include media-breakpoint-down(xs) {
        min-height: auto;
        position: absolute;
        bottom: 0;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
      span.line {
        display: none;
        width: 55px;
        height: 4px;
        position: absolute;
        left: 44%;
        top: 8px;
        background: #d8d8d8;
        border-radius: 2px;
        @include media-breakpoint-down(xs) {
          display: flex;
        }
      }
      .modal-close-button {
        @include media-breakpoint-down(xs) {
          right: 0 !important;
          top: 0 !important;
          left: auto;
          width: auto;
        }
        svg {
          width: 24px;
          height: 24px;
          path {
            @include media-breakpoint-down(xs) {
              fill: #202124;
            }
          }
        }
      }
      .modal-body {
        padding: 40px 40px 60px;
        @include media-breakpoint-down(xs) {
          padding: 60px 16px 21px;
        }
        .quiz-inner {
          display: flex;
          align-items: center;
          flex-direction: column;
          .quiz-img-wrapper {
            display: flex;
            position: relative;
            img {
              height: 48px;
              width: 48px;
            }
            .success-emoji-wrapper {
              position: absolute;
              right: -22px;
              bottom: -15px;
              padding: 5px;
              border-radius: 50%;
              img {
                height: 32px;
                width: 32px;
              }
            }
          }
          .quiz-title {
            font-size: 16px;
            font-weight: 500;
            color: #202124;
            margin-top: 24px;
            text-align: center;
          }
        }
        .quiz-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 16px;
          p {
            font-size: 16px;
            font-weight: normal;
            margin: 24px 0 0;
            text-align: center;
            color: #202124;
          }
          .btn-submit {
            border-radius: 10px;
            background-color: #6e87ff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 16px;
            font-weight: bold;
            line-height: 1.25;
            color: #fff;
            padding: 13px 0;
          }
          .btn-back {
            border-radius: 12px;
            box-shadow: 0 0 2px 0 #afafaf;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            color: #202124;
            padding: 11px 0;
          }
        }
      }
    }
  }
}
