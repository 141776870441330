/*
  <div className="toast toast-right toast-top toast-success">
    <h6 className="toast-header>Toast Mesajı</h6>
  </div>
*/

.toast {
  position: relative;
  // width: 343px;
  max-width: 343px;
  min-height: 70px;
  padding: 16px;
  z-index: 99999;
  border: 0;
  font-size: 16px;
  font-weight: 500;
  opacity: 1;
  color: #202124;
  border-radius: 16px;
  background-color: #e6ffe8;
  .custom-toast,
  .custom-toast-danger {
    display: flex;
    align-items: center;
    span {
      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }
    svg {
      height: 32px;
      width: 32px;
    }
    a {
      color: #6e87ff;
      font-weight: bold;
    }
  }
  .custom-toast-danger {
    background: #ffeced;
    position: absolute;
    left: 0;
    top: 0;
    padding: 16px;
    width: 100%;
    height: 100%;
    line-height: 1.43;
  }

  .toast-header {
    font-size: 16px;
  }

  &.active {
    opacity: 1;
  }

  /* TOAST TYPES */
  &-success {
    .toast-header {
      color: var(--success);
    }
  }

  &-danger {
    .toast-header {
      color: var(--danger);
    }
  }

  &-warning {
    .toast-header {
      color: var(--warning);
    }
  }

  &-info {
    .toast-header {
      color: var(--blue);
    }
  }

  /* TOAST POSITION */
  &-left {
    left: 10px;
  }

  &-right {
    right: 10px;
  }

  &-top {
    top: 10px;
  }

  &-bottom {
    bottom: 10px;
  }
}

@media (max-width: 568px) {
  .toast {
    max-width: 100%;
    width: 95%;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right,
  .Toastify__toast-container--bottom-left {
    height: 100%;
    .toast {
      position: absolute;
      // top: -webkit-calc(50% - 35px);
      // top: -moz-calc(50% - 35px);
      // top: calc(50% - 35px);
      // left: -webkit-calc(50% - 150px);
      // left: -moz-calc(50% - 150px);
      // left: calc(50% - 150px);
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
      top: -webkit-calc(85% - 35px);
      top: -moz-calc(85% - 35px);
      top: calc(85% - 35px);
      left: calc(5% - 10px);
    }
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  animation-name: zoomOut;
}

/* Not needed with the cssTransition helper */

.animate {
  animation-duration: 800ms;
}
